import React, { useEffect } from 'react';
import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  EventControlComponent,
  EventControlPipelineTemplateElement,
  UserEventType,
} from '@constants/event';
import { CREATED_BY_USER_LABEL, USE_TEMPLATE_BUTTON_LABEL } from '@constants/pipeline-templates';
import { IPipelineTemplate } from '@redux/types/types';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import PipelineTemplateMetadata from './PipelineTemplateMetadata';
import PipelineTemplateMoreLikeThis from './PipelineTemplateMoreLikeThis';
import styles from './pipelineTemplateInfo.module.scss';

interface IPipelineTemplateProps {
  template: IPipelineTemplate;
  setShowNamePipelineModal: (showModal: boolean) => void;
  setSelectedNewTemplate: (template: IPipelineTemplate) => void;
}

const avatarSize = 'small';

const PipelineTemplateInfo = (props: IPipelineTemplateProps) => {
  const { template, setShowNamePipelineModal, setSelectedNewTemplate } = props;
  const { trackUserEvent, setEventProperties } = useUserEvent();

  useEffect(() => {
    setEventProperties({ pipeline_template_session_id: uuidv4() });
  }, []);

  const sendMetrics = (pipelineName: string) => {
    trackUserEvent({
      type: UserEventType.CLICK,
      control: `${EventControlComponent.PIPELINE_TEMPLATE}/${EventControlPipelineTemplateElement.SELECT_PIPELINE_TEMPLATE}`,
      properties: {
        template: pipelineName,
      },
    });
  };

  const setNewTemplate = (newTemplate: IPipelineTemplate) => {
    setShowNamePipelineModal(true);
    setSelectedNewTemplate(newTemplate);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_title}>
          <div className={styles.header_title_name}>{template?.name}</div>
          <Button
            type="primary"
            onClick={() => {
              setNewTemplate(template);
              sendMetrics(template?.pipeline_name);
            }}
          >
            {USE_TEMPLATE_BUTTON_LABEL}
          </Button>
        </div>
        <div className={styles.header_createdBy}>
          <DcLogoAvatar size={avatarSize} />
          <span>
            {CREATED_BY_USER_LABEL} {template?.author}
          </span>
        </div>
        <div className={styles.header_description}>
          <MarkdownViewer>{template?.description}</MarkdownViewer>
        </div>
      </div>
      <PipelineTemplateMetadata template={template} />
      <PipelineTemplateMoreLikeThis
        template={template}
        setNewTemplate={(newTemplate) => {
          setNewTemplate(newTemplate);
        }}
        sendMetrics={(pipelineName: string) => {
          sendMetrics(pipelineName);
        }}
      />
    </div>
  );
};

export default PipelineTemplateInfo;
