import axios from 'axios';
import { get } from 'lodash';
import { auth } from '@utils/auth';
import { API_URL } from '@constants/constant';
import { HTTPStatusCode } from '@constants/enum/common';
import { SilentError } from '@constants/error';
import { CustomHTTPHeaders } from '@constants/http';

const SILENT_500_ERRORS = import.meta.env.VITE_SILENT_500_ERRORS === 'true';
const SILENT_404_ERRORS = import.meta.env.VITE_SILENT_404_ERRORS === 'true';

const httpExt = axios.create({
  baseURL: API_URL,
  headers: {
    [CustomHTTPHeaders.X_CLIENT_SOURCE]: 'deepset-cloud-ui',
  },
});

httpExt.interceptors.request.use(
  async (config) => {
    const localConfig = config;
    if (localConfig.headers && !localConfig.headers.Authorization) {
      const { user_token: token } = JSON.parse(sessionStorage.getItem('dcExtcurrent') || '{}');
      if (token) localConfig.headers.Authorization = `Bearer ${token}`;
      else {
        try {
          const auht0token = await auth.getAccessTokenSilently({});
          localConfig.headers.Authorization = `Bearer ${auht0token}`;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    }
    if (localConfig.url) localConfig.url = encodeURI(localConfig.url);
    return localConfig;
  },
  (error) => {
    // Handle silent errors when configured
    const statusCode = get(error, 'response.status');
    const isSilent500tError =
      SILENT_500_ERRORS && statusCode === HTTPStatusCode.INTERNAL_SERVER_ERROR;
    const isSilent404Error = SILENT_404_ERRORS && statusCode === HTTPStatusCode.NOT_FOUND;
    if (isSilent500tError || isSilent404Error) {
      const silentError = new SilentError(error.message);
      silentError.response = error.response;
      return Promise.reject(silentError);
    }

    return Promise.reject(error);
  },
);

export { httpExt };
