import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const searchStoreSelector = (state: RootState) => state.searchStore;

export const searchStatusSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.status,
);

export const queryStatusPromptExplorerSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.statusPromptExplorer,
);

export const searchPipelineSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.searchPipeline,
);

export const searchMessageSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.message,
);

export const searchResultSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.searchResults,
);

export const queryResultsPromptExplorerSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.queryResultsPromptExplorer,
);

export const searchSessionsSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.searchSessions,
);

export const searchSessionsStatusSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.searchSessionsStatus,
);

export const referenceDrawerVisibleSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.referenceDrawerVisible,
);

export const promptModalVisibleSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.promptModalVisible,
);

export const activePromptsSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.activePrompts,
);

export const activeReferenceSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.activeReference,
);

export const chatQueryStatusSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.chatQueryStatus,
);

export const chatResultsSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.chatResults,
);

export const debugModeSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.debugMode,
);

export const queryPipelineParamsSelector = createSelector(
  searchStoreSelector,
  (searchStore) => searchStore.queryPipelineParams,
);
