import { isArray, isBoolean, isPlainObject } from 'lodash';
import {
  IDateMetadataFilterValue,
  INumericRangeMetadataFilterValue,
  MetadataFiltersValue,
} from '@redux/types/types';

export const updateURLParamsSilently = (params: URLSearchParams) => {
  window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
};

// Metadata filters
interface ParsedMetadataParam {
  value: MetadataFiltersValue | null;
  type: 'keyword' | 'boolean' | 'float' | 'long' | 'date';
}

type RangeValue = { min: number | string | null; max: number | string | null };

const parsers = {
  boolean: (value: string) => value.toLowerCase() === 'true',

  keyword: (value: string) => value.split(',').filter(Boolean),

  range: (value: string, type: 'float' | 'long' | 'date') => {
    const [min, max] = value.split(',');
    if (!min && !max) return null;

    const parseValue = (val: string) => (type === 'date' ? val : val ? Number(val) : null);

    return {
      min: parseValue(min),
      max: parseValue(max),
    } as INumericRangeMetadataFilterValue | IDateMetadataFilterValue;
  },
};

export const parseMetadataUrlParam = (
  value: string,
  type: string,
): ParsedMetadataParam['value'] => {
  if (!value || !type) return null;

  if (type === 'boolean') return parsers.boolean(value);
  if (type === 'keyword') return parsers.keyword(value);
  if (['float', 'long', 'date'].includes(type))
    return parsers.range(value, type as 'float' | 'long' | 'date');

  return null;
};

export const getMetadataParamsFromUrl = (
  searchParams: URLSearchParams,
  metadataFields: Record<string, { type: string }>,
): Record<string, MetadataFiltersValue> => {
  const params: Record<string, MetadataFiltersValue> = {};

  for (const [key, value] of searchParams.entries()) {
    if (metadataFields[key]) {
      const parsedValue = parseMetadataUrlParam(value, metadataFields[key].type);
      if (parsedValue) params[key] = parsedValue;
    }
  }

  return params;
};

const valueFormatters = {
  boolean: (value: boolean) => String(value),

  array: (value: unknown[]) => {
    const filtered = value.filter(Boolean);
    return filtered.length ? filtered.join(',') : null;
  },

  range: (value: RangeValue) => {
    const { min, max } = value;
    return min || max ? `${min ?? ''},${max ?? ''}` : null;
  },
};

const formatParamValue = (value: unknown): string | null => {
  if (isBoolean(value)) return valueFormatters.boolean(value);
  if (isArray(value)) return valueFormatters.array(value);
  if (isPlainObject(value)) return valueFormatters.range(value as RangeValue);
  return null;
};

export const getMetadataParamsToUrl = (
  params: Record<string, unknown>,
  metadataFields: Record<string, { type: string }>,
): URLSearchParams => {
  const searchParams = new URLSearchParams();

  Object.entries(params)
    .filter(([key, value]) => value && metadataFields[key])
    .forEach(([key, value]) => {
      const formattedValue = formatParamValue(value);
      if (formattedValue) searchParams.set(key, formattedValue);
    });

  return searchParams;
};
