import { useSelector } from 'react-redux';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';

/**
 * Custom navigation hook that automatically prepends the current workspace to the navigation path.
 * This hook wraps React Router's useNavigate to provide workspace-aware navigation.
 */
export const useWorkspaceNavigate = () => {
  const navigate = useNavigate();
  const { currentWorkspace } = useSelector(workspaceSelector);

  return (to: string | number, options?: NavigateOptions) => {
    // Handle back navigation or external URLs
    if (typeof to === 'number' || to.startsWith('http')) {
      navigate(to as To, options);
      return;
    }

    const cleanPath = to.startsWith('/') ? to.substring(1) : to;
    const newPath = `/${currentWorkspace}/${cleanPath}`;

    navigate(newPath, options);
  };
};
