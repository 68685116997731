import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { interpolateString } from '@utils/string';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { CANCEL_BUTTON_LABEL, UPDATE_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  PROMPT_EDITOR_PIPELINE_DETAILS_PAGE_LINK,
  PROMPT_EDITOR_UPDATE_PIPELINE_PROMPT_TOOLTIP,
  UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_CONTENT,
  UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_TITLE,
} from '@constants/prompt-explorer';
import { updatePipelinePromptStatusSelector } from '@redux/selectors/promptExplorerSelectors';
import { IPipeline, IPromptNode, PipelineServiceLevel } from '@redux/types/types';
import WorkspaceLink from '@components/common/WorkspaceLink/WorkspaceLink';

const usePromptEditor = ({
  pipeline,
  pipelineName,
  prompts,
  selectedPromptNode,
  onUpdatePipelinePrompt,
}: {
  pipeline: IPipeline | null;
  pipelineName?: string;
  prompts: Record<string, IPromptNode>;
  selectedPromptNode: string;
  onUpdatePipelinePrompt: () => void;
}) => {
  const [modal, modalContextHolder] = Modal.useModal();
  const initialPromptsRef = useRef<null | Record<string, IPromptNode>>(null);
  const updatePipelinePromptStatus = useSelector(updatePipelinePromptStatusSelector);

  const isUpdatingPipelinePrompt = updatePipelinePromptStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    if (Object.keys(prompts).length > 0 && initialPromptsRef.current === null)
      initialPromptsRef.current = prompts;
  }, [prompts]);

  useEffectUpdateOnly(() => {
    const pipelinePromptUpdated = updatePipelinePromptStatus === StatusCodes.SUCCESS;
    if (pipelinePromptUpdated || pipelineName) initialPromptsRef.current = prompts;
  }, [updatePipelinePromptStatus, pipelineName]);

  const hasPromptTextChanged = () => {
    if (!initialPromptsRef.current || !Object.keys(prompts).length) return false;
    const originalPromptValue = initialPromptsRef.current[selectedPromptNode]?.text;
    const newPromptValue = prompts[selectedPromptNode]?.text;
    return originalPromptValue !== newPromptValue;
  };

  const isUpdatePipelineButtonDisabled = () => {
    return (
      !pipeline ||
      pipeline.service_level === PipelineServiceLevel.PRODUCTION ||
      !hasPromptTextChanged() ||
      isUpdatingPipelinePrompt ||
      !prompts[selectedPromptNode]?.text
    );
  };

  const getUpdatePipelinePromptTooltipText = () => {
    if (!pipeline || !hasPromptTextChanged())
      return PROMPT_EDITOR_UPDATE_PIPELINE_PROMPT_TOOLTIP.NO_CHANGES;
    if (pipeline.service_level === PipelineServiceLevel.PRODUCTION)
      return interpolateString(PROMPT_EDITOR_UPDATE_PIPELINE_PROMPT_TOOLTIP.PRODUCTION_PIPELINE, {
        pipelinePage: (
          <WorkspaceLink
            to={`/pipelines/${pipelineName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {PROMPT_EDITOR_PIPELINE_DETAILS_PAGE_LINK}
          </WorkspaceLink>
        ),
      });
    return interpolateString(PROMPT_EDITOR_UPDATE_PIPELINE_PROMPT_TOOLTIP.UPDATE_PIPELINE_NODE, {
      node: selectedPromptNode,
    });
  };

  const onUpdatePipelinePromptClick = () => {
    const confirm = modal.confirm({
      title: UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_TITLE,
      content: UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_CONTENT,
      okText: UPDATE_BUTTON_LABEL,
      cancelText: CANCEL_BUTTON_LABEL,
      onOk: () => {
        Modal.destroyAll();
        onUpdatePipelinePrompt();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
    return confirm;
  };

  return {
    modalContextHolder,
    getUpdatePipelinePromptTooltipText,
    isUpdatePipelineButtonDisabled,
    onUpdatePipelinePromptClick,
  };
};

export default usePromptEditor;
