import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleErrorNotification } from '@utils/error';
import { getODataFilterFrom, getODataSearchFilterFrom } from '@utils/odata';
import { modifyYaml } from '@utils/yaml';
import { getPipelineYamlApi, updatePipelineYamlApi } from '@api/pipeline';
import {
  creatCustomPromptTemplateApi,
  deleteCustomPromptTemplateApi,
  getCustomPromptTemplatesApi,
  getPublicPromptTemplatesApi,
  getRecursivelyPromptTemplatesApi,
  updateCustomPromptTemplateApi,
} from '@api/prompt-explorer';
import {
  SORTING_PARAMS_BY_KEY,
  UPDATED_PIPELINE_PROMPT_NOTIFICATION,
  UPDATING_PIPELINE_PROMPT_NOTIFICATION,
} from '@constants/prompt-explorer';
import { initialState } from '@redux/rootReducer';
import {
  CREATE_CUSTOM_PROMPT_TEMPLATE,
  CustomPromptTemplate,
  DELETE_CUSTOM_PROMPT_TEMPLATE,
  GET_CUSTOM_PROMPT_TEMPLATES,
  GET_PROMPT_TEMPLATES_FILTER_TAGS,
  GET_PROMPT_TEMPLATES_FILTER_USER,
  GET_PUBLIC_PROMPT_TEMPLATES,
  ITemplatesParams,
  NotificationMode,
  NotificationType,
  PromptTemplatesModalTabOption,
  SELECT_PROMPT_TEMPLATES_SORT_VALUE,
  UPDATE_CUSTOM_PROMPT_TEMPLATE,
  UPDATE_PIPELINE_PROMPT,
} from '@redux/types/types';
import { addSequentialNotification } from './notificationActions';

export const selectPromptTemplatesSortValue = (value: string) => ({
  type: SELECT_PROMPT_TEMPLATES_SORT_VALUE,
  payload: value,
});

export const getCustomPromptTemplates = createAsyncThunk(
  GET_CUSTOM_PROMPT_TEMPLATES,
  async (params: ITemplatesParams = {}, { rejectWithValue, getState, dispatch }) => {
    const { sortValue, filterValues, searchValue, ...restOfParams } = params;
    const { promptTemplatesSortValue: defaultSortValue } = (getState() as typeof initialState)
      .promptExplorerStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectPromptTemplatesSortValue(sortingKey));

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const parsedODataFilers = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const filter = searchFilter
      ? `${parsedODataFilers ? `${parsedODataFilers} and ` : ''}${searchFilter}`
      : parsedODataFilers;

    const queryParams = {
      ...restOfParams,
      field,
      order,
      filter,
    };

    try {
      const { data } = await getCustomPromptTemplatesApi(queryParams);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createCustomPromptTemplate = createAsyncThunk(
  CREATE_CUSTOM_PROMPT_TEMPLATE,
  async (customPrompt: CustomPromptTemplate, { rejectWithValue }) => {
    try {
      const data = await creatCustomPromptTemplateApi(customPrompt);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateCustomPromptTemplate = createAsyncThunk(
  UPDATE_CUSTOM_PROMPT_TEMPLATE,
  async (
    customPrompt: CustomPromptTemplate & { prompt_template_id: string },
    { rejectWithValue },
  ) => {
    try {
      const { prompt_template_id: promptTemplateId, ...restOfFields } = customPrompt;
      const data = await updateCustomPromptTemplateApi(promptTemplateId, restOfFields);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCustomPromptTemplate = createAsyncThunk(
  DELETE_CUSTOM_PROMPT_TEMPLATE,
  async (customPromptTemplateId: string, { rejectWithValue }) => {
    try {
      const data = await deleteCustomPromptTemplateApi(customPromptTemplateId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPublicPromptTemplates = createAsyncThunk(
  GET_PUBLIC_PROMPT_TEMPLATES,
  async (params: ITemplatesParams = {}, { rejectWithValue, getState, dispatch }) => {
    const { sortValue, filterValues, searchValue, ...restOfParams } = params;
    const { promptTemplatesSortValue: defaultSortValue } = (getState() as typeof initialState)
      .promptExplorerStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectPromptTemplatesSortValue(sortingKey));

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const parsedODataFilers = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const filter = searchFilter
      ? `${parsedODataFilers ? `${parsedODataFilers} and ` : ''}${searchFilter}`
      : parsedODataFilers;

    const queryParams = {
      ...restOfParams,
      field,
      order,
      filter,
    };

    try {
      const { data } = await getPublicPromptTemplatesApi(queryParams);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Filters

export const getPromptTemplatesFilterTags = createAsyncThunk(
  GET_PROMPT_TEMPLATES_FILTER_TAGS,
  async (
    {
      promptTemplateModalActiveTab,
    }: { promptTemplateModalActiveTab: PromptTemplatesModalTabOption },
    { rejectWithValue },
  ) => {
    const apiCall =
      promptTemplateModalActiveTab === PromptTemplatesModalTabOption.CUSTOM
        ? getCustomPromptTemplatesApi
        : getPublicPromptTemplatesApi;
    const query = {
      select: 'tags/name, tags/tag_id',
    };
    try {
      const data = await getRecursivelyPromptTemplatesApi<string[]>(query, apiCall);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPromptTemplatesFilterUser = createAsyncThunk(
  GET_PROMPT_TEMPLATES_FILTER_USER,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'user/user_id, user/given_name, user/family_name',
    };
    try {
      const data = await getRecursivelyPromptTemplatesApi<string[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePipelinePrompt = createAsyncThunk(
  UPDATE_PIPELINE_PROMPT,
  async (
    {
      pipelineName,
      promptNode,
      prompt,
    }: { pipelineName: string; promptNode: string; prompt: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(
        addSequentialNotification({
          content: UPDATING_PIPELINE_PROMPT_NOTIFICATION,
          type: NotificationType.Loading,
          mode: NotificationMode.Message,
          duration: 0,
        }),
      );
      const { data: pipelineYaml } = await getPipelineYamlApi(pipelineName);
      const { query_yaml: queryYaml, indexing_yaml: indexingYaml } = pipelineYaml;

      const promptNodePath = ['components', promptNode];
      const templatePromptParamPath = [...promptNodePath, 'init_parameters', 'template'];

      const updatedPipelineYaml = modifyYaml(queryYaml, templatePromptParamPath, prompt);
      await updatePipelineYamlApi(pipelineName, {
        query_yaml: updatedPipelineYaml,
        indexing_yaml: indexingYaml,
      });

      dispatch(
        addSequentialNotification({
          content: UPDATED_PIPELINE_PROMPT_NOTIFICATION,
          type: NotificationType.Success,
        }),
      );
      return updatedPipelineYaml;
    } catch (error) {
      handleErrorNotification(error, dispatch, {
        sequential: true,
      });
      return rejectWithValue(error);
    }
  },
);
