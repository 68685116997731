import awsSVG from '@assets/icons/aws.svg';
import cohereLogoSVG from '@assets/icons/cohere_logo.svg';
import deeplSVG from '@assets/icons/deepl.svg';
import googleAISVG from '@assets/icons/google_ai.svg';
import huggingfaceLogoSVG from '@assets/icons/huggingface_logo.svg';
import microsoftSVG from '@assets/icons/microsoft.svg';
import mongodbSVG from '@assets/icons/mongodb.svg';
import nvidiaSVG from '@assets/icons/nvidia.svg';
import openAISVG from '@assets/icons/openai_logo.svg';
import searchApiPNG from '@assets/icons/searchapi.png';
import snowflakeSVG from '@assets/icons/snowflake.svg';
import togetherSVG from '@assets/icons/together.svg';
import unstructuredSVG from '@assets/icons/unstructured.svg';
import voyageAIPNG from '@assets/icons/voyageai.png';
import wandbSVG from '@assets/icons/wandb.svg';
import { ModelProviders } from '@redux/types/types';

export const UPGRADE_POPOVER_CONTENT = 'Upgrade to be able to complete this action.';

/* Personal Details form */
export const FIRST_NAME_LABEL = 'First Name';
export const LAST_NAME_LABEL = 'Family Name';
export const FIRST_NAME_ERROR = 'Enter your first name';
export const LAST_NAME_ERROR = 'Enter your family name';

/* Organization page */
export const ORGANIZATION_LABEL = 'Your organization';
export const ORGANIZATION_DESCRIPTION = `Everyone in an organization has access to the organization's workspaces.`;
export const UPGRADE_POPOVER_INVITE_CONTENT = 'Upgrade to a paid plan to add more team members.';

/* Workspace page */
export const WORKSPACES_TITLE = 'Workspaces';
export const WORKSPACE_ROLES_SAVED_MESSAGE = 'Workspace permissions updated.';
export const WORKSPACE_ACCESS_MODAL_TITLE = 'Manage access to %workspaceName% workspace';

export const WORKSPACE_ACCESS_MODAL_EXISTING_USERS = 'Current workspace members (%count%)';
export const WORKSPACE_ACCESS_MODAL_AUTO_COMPLETE_PLACEHOLDER = 'Add members';
export const DELETE_WORKSPACE_POPCONFIRM_LABEL =
  "Are you sure you want to delete '%workspace%' workspace? Deletion is irreversible.";
export const DELETE_WORKSPACE_POPCONFIRM_SUBTITLE = "Type '%workspace%' into the field to confirm";
export const DELETE_WORKSPACE_BUTTON_LABEL = 'Delete';
export const DELETE_WORKSPACE_POPCONFIRM_CONFIRM_LABEL = 'Delete';
export const WORKSPACE_INPUT_PLACEHOLDER = 'Workspace name';
export const ADD_WORKSPACE_LABEL = 'Add workspace';
export const ADD_WORKSPACE_MODAL_BUTTON_LABEL = 'Add';
export const REACHED_MAX_WORKSPACES_BANNER =
  'You can have maximum %maxWorkspaces% workspaces. To add a new workspace, delete an existing one first.';
export const UPGRADE_POPOVER_TITLE = 'Upgrade your plan';
export const UPGRADE_POPOVER_WORKSPACE_CONTENT = 'Unlock more workspaces with a paid plan.';

export const MANAGE_ACCESS_BUTTON_LABEL = 'Manage Access';

/* Connections page */
export const API_ID_LABEL = 'Key ID';
export const API_KEY_LABEL = 'Key';
export const API_KEYS_HEADER = 'API Keys';
export const CREATED_BY_LABEL = 'Created By';
export const DELETE_BUTTON_LABEL = 'Delete';
export const API_KEY_PLACEHOLDER = '****************';
export const EXPIRES_LABEL = 'Expires at';
export const GENERATE_KEY_TITLE = 'Generate New API Key';
export const CLOSE_BUTTON_LABEL = 'Done';
export const CANCEL_CREATE_BUTTON_LABEL = 'Cancel';
export const ADD_NEW_KEY_BUTTON_LABEL = 'Add new key';
export const ADD_ANOTHER_KEY_BUTTON_LABEL = 'Add another key';
export const GENERATE_KEY_BUTTON_LABEL = 'Generate key';
export const GENERATE_KEY_INFO_LABEL = 'Use this key to connect deepset Cloud to your application.';
export const EXPIRATION_DATEPICKER_LABEL = 'Expiration Date';
export const NEW_API_KEY_HEADER = 'API Key';
export const NEW_API_KEY_INFO = 'Save your key as it will be hidden for the rest of its life.';
export const COPY_TOOLTIP = 'Copied to clipboard.';
export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this key?';
export const POP_CONFIRM_BUTTON_LABEL = 'Yes';
export const POP_CANCEL_BUTTON_LABEL = 'No';
export const INTEGRATION_TITLE = 'Integrations';
export const CONNECT_MODAL_TITLE = 'Connect to %provider%';

export const HUGGINGFACE_LABEL = 'Hugging Face';
export const OPENAI_LABEL = 'OpenAI';
export const COHERE_LABEL = 'Cohere';
export const AWS_LABEL = 'Amazon Bedrock & SageMaker';
export const SNOWFLAKE_LABEL = 'Snowflake';
export const AZURE_OPENAI_LABEL = 'Azure OpenAI';
export const AZURE_AI_LABEL = 'Azure AI Document Intelligence';
export const UNSTRUCTURED_LABEL = 'Unstructured';
export const DEEPL_LABEL = 'DeepL';
export const GOOGLE_LABEL = 'Google AI';
export const NVIDIA_LABEL = 'NVIDIA';
export const SEARCHAPI_LABEL = 'SearchApi';
export const VOYAGEAI_LABEL = 'Voyage AI';
export const WANDB_AI = 'Weights & Biases';
export const MONGODB_LABEL = 'MongoDB';
export const TOGETHERAI_LABEL = 'TogetherAI';

export const MODEL_PROVIDER_LABEL_CONNECTED = `You're connected. You can use %private% models hosted in %provider%.`;
export const MODEL_PROVIDER_LABEL_NO_CONNECTED =
  'Connect to use %private% models hosted in %provider%.';
export const MODEL_PROVIDER_LABEL_INVALID =
  'The token is no longer valid, update it to continue using %private% models hosted in %provider%.';

export const DISCONNECT_BUTTON_LABEL = 'Disconnect';
export const DISCONNECT_CONFIRMATION_TITLE = 'Disconnect %provider%';
export const DISCONNECT_CONFIRMATION_MESSAGE =
  'By disconnecting %provider% you will no longer be able to use its services. Do you want to disconnect?';
export const CONNECT_BUTTON_LABEL = 'Connect';

export const MODEL_PROVIDERS_NAMES_MAPPED = {
  [ModelProviders.huggingface]: HUGGINGFACE_LABEL,
  [ModelProviders.openai]: OPENAI_LABEL,
  [ModelProviders.cohere]: COHERE_LABEL,
  [ModelProviders.aws]: AWS_LABEL,
  [ModelProviders.snowflake]: SNOWFLAKE_LABEL,
  [ModelProviders.azureOpenai]: AZURE_OPENAI_LABEL,
  [ModelProviders.azureAI]: AZURE_AI_LABEL,
  [ModelProviders.unstructured]: UNSTRUCTURED_LABEL,
  [ModelProviders.deepl]: DEEPL_LABEL,
  [ModelProviders.google]: GOOGLE_LABEL,
  [ModelProviders.nvidia]: NVIDIA_LABEL,
  [ModelProviders.searchapi]: SEARCHAPI_LABEL,
  [ModelProviders.voyageai]: VOYAGEAI_LABEL,
  [ModelProviders.wandb]: WANDB_AI,
  [ModelProviders.mongodb]: MONGODB_LABEL,
  [ModelProviders.together]: TOGETHERAI_LABEL,
};

export const CONNECTIONS_CONFIG = [
  {
    provider: ModelProviders.huggingface,
    logo: { src: huggingfaceLogoSVG, description: 'Hugging Face logo' },
    formItems: [
      {
        label: 'User Access Token',
        name: 'token',
        rules: [
          {
            required: true,
            message: 'Enter your Hugging Face user access token to connect.',
          },
        ],
        placeholder: 'Enter your Hugging Face user access token',
      },
    ],
  },

  {
    provider: ModelProviders.openai,
    logo: { src: openAISVG, description: 'OpenAI logo' },
    formItems: [
      {
        label: 'User Access Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your OpenAI user access token to connect.' }],
        placeholder: 'Enter your OpenAI user access token',
      },
    ],
  },

  {
    provider: ModelProviders.snowflake,
    logo: { src: snowflakeSVG, description: 'Snowflake logo' },
    label: 'Connect to a Snowflake database.',
    labelConnected: "You're connected. You can use data stored in Snowflake.",
    formItems: [
      {
        label: 'Password',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Snowflake password to connect.' }],
        placeholder: 'Enter your Snowflake password',
      },
    ],
  },

  {
    provider: ModelProviders.cohere,
    logo: { src: cohereLogoSVG, description: 'Cohere logo' },
    formItems: [
      {
        label: 'User Access Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Cohere user access token to connect.' }],
        placeholder: 'Enter your Cohere user access token',
      },
    ],
  },

  {
    provider: ModelProviders.aws,
    logo: { src: awsSVG, description: 'AWS logo' },
    formItems: [
      {
        label: 'Region',
        name: 'region',
        rules: [],
        placeholder: 'Select your Amazon region',
        defaultValue: 'us-east-1',
        options: [
          { value: 'us-east-1', label: 'us-east-1' },
          { value: 'us-west-1', label: 'us-west-1' },
          { value: 'ap-southeast-1', label: 'ap-southeast-1' },
          { value: 'ap-northeast-1', label: 'ap-northeast-1' },
          { value: 'eu-central-1', label: 'eu-central-1' },
        ],
      },
      {
        label: 'Access Key ID',
        name: 'access_key_id',
        rules: [{ required: true, message: 'Enter the AWS access key ID to connect.' }],
        placeholder: 'Enter your AWS access key id',
      },
      {
        label: 'Secret Access Key',
        name: 'secret_access_key',
        rules: [{ required: true, message: 'Enter the AWS secret access key to connect.' }],
        placeholder: 'Enter your AWS secret access key',
      },
    ],
    tokenFormatter: (values: any) => `[default]
aws_access_key_id = ${values.access_key_id}
aws_secret_access_key = ${values.secret_access_key}
region_name = ${values.region}`,
  },

  {
    provider: ModelProviders.azureOpenai,
    logo: { src: microsoftSVG, description: 'Microsoft logo' },
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Azure OpenAI token to connect.' }],
        placeholder: 'Enter your Azure OpenAI token',
      },
    ],
  },

  {
    provider: ModelProviders.azureAI,
    logo: { src: microsoftSVG, description: 'Microsoft logo' },
    label: "Connect to use Azure's data processing services.",
    labelConnected: "You're connected. You can use Azure's data processing services.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [
          { required: true, message: 'Enter your Azure Document Intelligence token to connect.' },
        ],
        placeholder: 'Enter your Azure Document Intelligence token',
      },
    ],
  },

  {
    provider: ModelProviders.unstructured,
    logo: { src: unstructuredSVG, description: 'unstructured.io logo' },
    label: "Connect to use unstructured.io's data processing services.",
    labelConnected: "You're connected. You can use unstructured.io's data processing services.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your unstructured.io token to connect.' }],
        placeholder: 'Enter your unstructured.io token',
      },
    ],
  },

  {
    provider: ModelProviders.deepl,
    logo: { src: deeplSVG, description: 'DeepL logo' },
    label: "Connect to use DeepL's translation services.",
    labelConnected: "You're connected. You can use DeepL's translation services.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your DeepL token to connect.' }],
        placeholder: 'Enter your DeepL token',
      },
    ],
  },
  {
    provider: ModelProviders.google,
    logo: { src: googleAISVG, description: 'Google logo' },
    label: 'Connect to use Google AI models.',
    labelConnected: "You're connected. You can use Google AI models.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Google AI API token to connect.' }],
        placeholder: 'Enter your Google AI API token',
      },
    ],
  },
  {
    provider: ModelProviders.nvidia,
    logo: { src: nvidiaSVG, description: 'NVIDIA logo' },
    label: 'Connect to use NVIDIA models.',
    labelConnected: "You're connected. You can use NVIDIA models.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your NVIDIA API token to connect.' }],
        placeholder: 'Enter your NVIDIA API token',
      },
    ],
  },
  {
    provider: ModelProviders.voyageai,
    logo: { src: voyageAIPNG, description: 'Voyage AI logo' },
    label: 'Connect to use Voyage AI models.',
    labelConnected: "You're connected. You can use Voyage AI models.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Voyage AI API token to connect.' }],
        placeholder: 'Enter your Voyage AI API token',
      },
    ],
  },
  {
    provider: ModelProviders.searchapi,
    logo: { src: searchApiPNG, description: 'SearchApi logo' },
    label: 'Connect to use SearchApi services.',
    labelConnected: "You're connected. You can use SearchAPI services.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your SearchApi API token to connect.' }],
        placeholder: 'Enter your SearchApi API token',
      },
    ],
  },

  {
    provider: ModelProviders.mongodb,
    logo: { src: mongodbSVG, description: 'MongoDB logo' },
    label: 'Connect to use MongoDB services.',
    labelConnected: "You're connected. You can use MongoDB services.",
    formItems: [
      {
        label: 'MongoDB connection string',
        name: 'token',
        rules: [{ required: true, message: 'Enter your MongoDB connection string.' }],
        placeholder: 'Enter your MongoDB connection string',
      },
    ],
  },

  {
    provider: ModelProviders.together,
    logo: { src: togetherSVG, description: 'Together logo' },
    label: 'Connect to use Together services.',
    labelConnected: "You're connected. You can use Together services.",
    formItems: [
      {
        label: 'Token',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Together API token to connect.' }],
        placeholder: 'Enter your Together API token',
      },
    ],
  },

  {
    provider: ModelProviders.wandb,
    logo: { src: wandbSVG, description: 'Weights & Biases' },
    label: 'Connect to Weights & Biases to trace your pipelines.',
    labelConnected: "You're connected. You can trace your pipelines with Weights & Biases.",
    formItems: [
      {
        label: 'API Key',
        name: 'token',
        rules: [{ required: true, message: 'Enter your Weights & Biases API key to connect.' }],
        placeholder: 'Enter your Weights & Biases API key',
      },
    ],
  },
];
