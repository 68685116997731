import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';
import { RootState } from '@redux/store';
import { createResetAction } from '@redux/utils/createReducerWithReset';

/**
 * Gets valid paths for a specific store slice
 */
type StoreKeys<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object ? `${string & K}.${StoreKeys<T[K]>}` : string & K;
    }[keyof T]
  : never;

/**
 * Type-safe paths that must match Redux store structure
 * Example: 'pipelineStore.name' | 'studioStore.activeTab'
 */
type StatePath = {
  [K in keyof RootState]: `${string & K}.${StoreKeys<RootState[K]>}`;
}[keyof RootState];

/**
 * Configuration for state cleanup
 * @property paths - Array of Redux state paths to reset
 * @property condition - Optional boolean or function to determine if reset should occur
 * @property dependencies - Optional array of dependencies that affect the condition
 */
interface ResetConfig {
  paths: StatePath[];
  condition?: boolean | (() => boolean);
  dependencies?: any[];
}

/**
 * Function overloads for reset operation
 * Can be called with specific paths or with no arguments to reset all configured paths
 */
type ResetFunction = {
  (paths: StatePath[]): void;
  (): void;
};

/**
 * Hook for managing Redux state cleanup
 *
 * Provides automatic cleanup on component unmount and manual reset capabilities.
 * Supports conditional resets and type-safe state paths.
 *
 * @param config - Single config object or array of configs for multiple reset scenarios
 * @returns Object containing reset function
 *
 * @example
 * ```tsx
 * const { reset } = useStateCleanup({
 *   paths: ['pipelineStore.name', 'studioStore.activeTab'],
 *   condition: () => isDirty
 * })
 * ```
 */
export const useStateCleanup = (config: ResetConfig | ResetConfig[] = []) => {
  const dispatch = useDispatch();
  const configRef = useRef(config);

  // Using ref to store the config, to ensure the cleanup effect uses a stable value.
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  /**
   * Dispatches reset action for given paths
   * @param paths - Array of state paths to reset
   */
  const resetState = useCallback(
    (paths: StatePath[]) => {
      if (!isArray(paths) || paths.length === 0) {
        console.warn('Invalid paths provided to resetState');
        return;
      }
      dispatch(createResetAction(paths as string[]));
    },
    [dispatch],
  );

  /**
   * Handles reset for a single config object
   * @param resetConfig - Configuration object for reset operation
   */
  const handleConfigReset = useCallback(
    (resetConfig: ResetConfig) => {
      const { paths, condition } = resetConfig;
      const shouldReset = typeof condition === 'function' ? condition() : condition ?? true;

      if (shouldReset) resetState(paths);
    },
    [resetState],
  );

  /**
   * Handles reset for all configured paths
   */
  const handleFullReset = useCallback(() => {
    const currentConfig = configRef.current;
    if (isArray(currentConfig)) currentConfig.forEach(handleConfigReset);
    else handleConfigReset(currentConfig);
  }, [handleConfigReset]);

  /**
   * Public reset function
   * Can be called with specific paths or no arguments
   */
  const reset: ResetFunction = useCallback(
    (paths?: StatePath[]) => {
      if (paths) {
        resetState(paths);
        return;
      }
      handleFullReset();
    },
    [resetState, handleFullReset],
  );

  // IMPORTANT: Instead of returning handleFullReset as the cleanup function,
  // we defer its execution using setTimeout to avoid synchronous nested updates.
  useEffect(() => {
    return () => {
      setTimeout(() => {
        handleFullReset();
      }, 0);
    };
  }, []);

  return { reset };
};
