import React from 'react';
import { Avatar, theme } from 'antd';
import DeepsetLogoNotextSVG from '@assets/deepset-signet-darkblue.svg?react';
import styles from './dcLogoAvatar.module.scss';

const { useToken } = theme;

interface IDcLogoAvatar {
  size?: 'small' | 'default' | 'large';
}

export enum LogoSizes {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

const DcLogoAvatar = (props: IDcLogoAvatar) => {
  const { size } = props;
  const { token } = useToken();

  const getDcLogoPadding = () => {
    if (size === LogoSizes.SMALL) return styles.dcAvatar_small;
    if (size === LogoSizes.LARGE) return styles.dcAvatar_large;
    return styles.dcAvatar_default;
  };

  return (
    <Avatar
      size={size}
      className={`${styles.dcAvatar} ${getDcLogoPadding()}`}
      style={{ backgroundColor: token.colorPrimaryBorder }}
      src={<DeepsetLogoNotextSVG />}
    />
  );
};

export default DcLogoAvatar;
