import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleErrorNotification } from '@utils/error';
import { downloadBlobFile } from '@utils/file';
import {
  getODataConjunctionFilterFrom,
  getODataFilterFrom,
  getODataSearchFilterFrom,
} from '@utils/odata';
import {
  createJobAPI,
  deleteJobAPI,
  generateSharedResultLinkApi,
  getJobAPI,
  getJobResultsAPI,
  getJobResultsCSVApi,
  getJobsAPI,
  getQuerySetAPI,
  getQuerySetsAPI,
  getRecursivelyJobResultsApi,
  getRecursivelyJobsApi,
  getSharedJobApi,
  revokeJobResultPrototypeLinkApi,
  startJobAPI,
  updateSharedJobApi,
} from '@api/jobs';
import { SelectedFilters } from '@constants/data-table';
import { MIMETypes } from '@constants/enum/common';
import {
  JOB_CREATED_MESSAGE,
  JOB_DELETED_MESSAGE,
  JOB_STARTED_MESSAGE,
  JOBS_DELETED_MESSAGE,
  JOBS_SORTING_PARAMS_BY_KEY,
  JOBS_STARTED_MESSAGE,
  RESULT_SORTING_PARAMS_BY_KEY,
} from '@constants/jobs';
import { initialState } from '@redux/rootReducer';
import {
  CREATE_AND_START_JOB,
  CREATE_JOB,
  CreateJobStep,
  DELETE_JOB,
  DELETE_MULTIPLE_JOBS,
  EXPORT_JOB_RESULTS,
  GENERATE_SHARED_JOB_LINK,
  GET_JOB,
  GET_JOB_LIST_FILTER_TAGS,
  GET_JOB_LIST_FILTER_USER,
  GET_JOB_RESULTS,
  GET_JOB_RESULTS_FILTER_FILES,
  GET_JOB_TAGS,
  GET_JOBS,
  GET_QUERY_SET,
  GET_QUERY_SETS,
  GET_SHARED_JOB,
  IJobPayload,
  IPipeline,
  ITag,
  IUserFilter,
  JobType,
  NotificationType,
  QueryRunOption,
  RESET_CREATE_JOB_STATE,
  RESET_JOB_DETAILS_STATE,
  REVOKE_SHARED_JOB_LINK,
  SELECT_JOB_LIST_SORT_VALUE,
  SELECT_JOB_PIPELINE,
  SELECT_JOB_TAGS,
  SELECT_JOB_TYPE,
  SELECT_QUERY_RUN,
  SELECT_QUERY_SET_FILE,
  SELECT_RESULTS_SORT_VALUE,
  SET_CREATE_JOB_STEP,
  SET_JOB_NAME,
  SET_OPEN_CREATE_JOB_DRAWER,
  START_JOB,
  START_MULTIPLE_JOBS,
  START_POLLING_JOB,
  START_POLLING_JOBS,
  STOP_POLLING_JOB,
  STOP_POLLING_JOBS,
  UPDATE_SHARED_JOB,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

let interval: any;
let jobPollInterval: any;

export const resetCreateJobState = { type: RESET_CREATE_JOB_STATE };

export const resetJobDetailsState = { type: RESET_JOB_DETAILS_STATE };

export const selectJobResultsSortValue = (value: string) => ({
  type: SELECT_RESULTS_SORT_VALUE,
  payload: value,
});

export const selectJobsSortValue = (value: string) => ({
  type: SELECT_JOB_LIST_SORT_VALUE,
  payload: value,
});

export const getJob = createAsyncThunk(
  GET_JOB,
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await getJobAPI(id);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getJobs = createAsyncThunk(
  GET_JOBS,
  async (
    {
      limit,
      pageNumber,
      searchValue,
      sortValue,
      filterValues,
    }: {
      limit?: number;
      pageNumber?: number;
      searchValue?: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { jobListSortValue: defaultSortValue } = (getState() as typeof initialState).jobsStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof JOBS_SORTING_PARAMS_BY_KEY;

    dispatch(selectJobsSortValue(sortingKey));

    const { field, order } = JOBS_SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const searchFilter = searchValue && getODataSearchFilterFrom('name', searchValue);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      page_number: pageNumber,
      limit,
      filter: getODataConjunctionFilterFrom(searchFilter, filters),
      field,
      order,
    };
    try {
      const { data } = await getJobsAPI(params);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startPollingJob = (id: string) => (dispatch: any) => {
  clearInterval(jobPollInterval);
  jobPollInterval = setInterval(() => dispatch(getJob(id)), 5000);
  return {
    type: START_POLLING_JOB,
  };
};

export const stopPollingJob = () => {
  clearInterval(jobPollInterval);
  return {
    type: STOP_POLLING_JOB,
  };
};

export const startPollingJobs =
  (
    currentPage: number,
    pageSize: number,
    searchValue?: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
  ) =>
  (dispatch: any) => {
    clearInterval(interval);
    interval = setInterval(
      () =>
        dispatch(
          getJobs({
            pageNumber: currentPage,
            limit: pageSize,
            searchValue,
            sortValue,
            filterValues,
          }),
        ),
      5000,
    );
    return {
      type: START_POLLING_JOBS,
    };
  };

export const stopPollingJobs = () => {
  clearInterval(interval);
  return {
    type: STOP_POLLING_JOBS,
  };
};

export const getJobResults = createAsyncThunk(
  GET_JOB_RESULTS,
  async (
    {
      id,
      limit,
      pageNumber,
      searchValue,
      sortValue,
      filterValues,
    }: {
      id: string;
      limit?: number;
      pageNumber?: number;
      searchValue?: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { jobResultsSortValue: defaultSortValue } = (getState() as typeof initialState).jobsStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof RESULT_SORTING_PARAMS_BY_KEY;

    dispatch(selectJobResultsSortValue(sortingKey));

    const { field, order } = RESULT_SORTING_PARAMS_BY_KEY[sortingKey] || {};
    const searchFilter = searchValue && getODataSearchFilterFrom('query', searchValue);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      page_number: pageNumber,
      limit,
      filter: getODataConjunctionFilterFrom(searchFilter, filters),
      field,
      order,
    };
    try {
      const { data } = await getJobResultsAPI(id, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportJobResults = createAsyncThunk(
  EXPORT_JOB_RESULTS,
  async ({ id, jobName }: { id: string; jobName: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getJobResultsCSVApi(id);
      downloadBlobFile(`${jobName}.csv`, response.data, MIMETypes.CSV);
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const createJob = createAsyncThunk(
  CREATE_JOB,
  async (job: IJobPayload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await createJobAPI(job);
      dispatch(
        addNotification({
          content: JOB_CREATED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const createAndStartJob = createAsyncThunk(
  CREATE_AND_START_JOB,
  async (job: IJobPayload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await createJobAPI(job);
      await startJobAPI(data.job_id);
      dispatch(
        addNotification({
          content: JOB_STARTED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startJob = createAsyncThunk(
  START_JOB,
  async (jobId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await startJobAPI(jobId);
      dispatch(
        addNotification({
          content: JOB_STARTED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startMultipleJobs = createAsyncThunk(
  START_MULTIPLE_JOBS,
  async (jobIds: string[], { dispatch, rejectWithValue }) => {
    try {
      await Promise.all(jobIds.map((jobId) => startJobAPI(jobId)));
      dispatch(
        addNotification({
          content: JOBS_STARTED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return jobIds;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const deleteJob = createAsyncThunk(
  DELETE_JOB,
  async (jobId: string, { dispatch, rejectWithValue }) => {
    try {
      await deleteJobAPI(jobId);
      dispatch(
        addNotification({
          content: JOB_DELETED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return jobId;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const deleteMultipleJobs = createAsyncThunk(
  DELETE_MULTIPLE_JOBS,
  async (jobIds: string[], { dispatch, rejectWithValue }) => {
    try {
      await Promise.all(jobIds.map((jobId) => deleteJobAPI(jobId)));
      dispatch(
        addNotification({
          content: JOBS_DELETED_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return jobIds;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getQuerySet = createAsyncThunk(
  GET_QUERY_SET,
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await getQuerySetAPI(id);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getQuerySets = createAsyncThunk(
  GET_QUERY_SETS,
  async (
    { limit, pageNumber }: { limit?: number; pageNumber?: number },
    { dispatch, rejectWithValue },
  ) => {
    const params = {
      page_number: pageNumber,
      limit,
    };
    try {
      const { data } = await getQuerySetsAPI(params);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getJobTags = createAsyncThunk(
  GET_JOB_TAGS,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const tags: ITag[] = [
        {
          tag_id: 'tag1',
          name: 'SQ',
        },
        {
          tag_id: 'tag2',
          name: 'test',
        },
      ];
      // const { data } = await getJobTags();
      return tags;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const setOpenCreateJobDrawer = (openCreateJobDrawer: boolean) => ({
  type: SET_OPEN_CREATE_JOB_DRAWER,
  payload: openCreateJobDrawer,
});

export const setCreateJobStep = (step: CreateJobStep) => ({
  type: SET_CREATE_JOB_STEP,
  payload: step,
});

export const selectJobType = (jobType: JobType) => ({
  type: SELECT_JOB_TYPE,
  payload: jobType,
});

export const selectQueryRun = (queryRun: QueryRunOption) => ({
  type: SELECT_QUERY_RUN,
  payload: queryRun,
});

export const selectQuerySet = (querySetFileId: string) => ({
  type: SELECT_QUERY_SET_FILE,
  payload: querySetFileId,
});

export const selectPipeline = (pipeline: IPipeline | null) => ({
  type: SELECT_JOB_PIPELINE,
  payload: pipeline,
});

export const setJobName = (name: string) => ({
  type: SET_JOB_NAME,
  payload: name,
});

export const selectTags = (tags: string[]) => ({
  type: SELECT_JOB_TAGS,
  payload: tags,
});

export const generateSharedJobResultLink = createAsyncThunk(
  GENERATE_SHARED_JOB_LINK,
  async (
    {
      jobId,
      title,
      expirationDate,
      description,
      showFiles,
    }: {
      jobId: string;
      title: string;
      expirationDate: Date;
      description?: string;
      showFiles?: boolean;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await generateSharedResultLinkApi({
        jobId,
        expirationDate,
        title,
        description,
        showFiles,
      });
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getSharedJob = createAsyncThunk(
  GET_SHARED_JOB,
  async ({ jobId }: { jobId: string }, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await getSharedJobApi(jobId);

      return data[0];
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const updateSharedJob = createAsyncThunk(
  UPDATE_SHARED_JOB,
  async (
    {
      sharedJobPrototypeId,
      description,
      title,
    }: {
      sharedJobPrototypeId: string;
      title?: string;
      description?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const patchPayload = {
      title,
      description,
    };
    try {
      const { data } = await updateSharedJobApi(sharedJobPrototypeId, patchPayload);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const revokeJobResultPrototypeLink = createAsyncThunk(
  REVOKE_SHARED_JOB_LINK,
  async (sharedJobId: string) => {
    const { data } = await revokeJobResultPrototypeLinkApi(sharedJobId);
    return data;
  },
);

// Filters

export const getJobListFilterUsers = createAsyncThunk(
  GET_JOB_LIST_FILTER_USER,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'created_by',
    };
    try {
      const data = await getRecursivelyJobsApi<IUserFilter[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getJobListFilterTags = createAsyncThunk(
  GET_JOB_LIST_FILTER_TAGS,
  async (_, { rejectWithValue }) => {
    const query = {
      select: 'tags/name, tags/tag_id',
    };
    try {
      const data = await getRecursivelyJobsApi<ITag[]>(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// TODO: Add infinite scroller instead of loading all files at once
export const getJobResultsFilterFiles = createAsyncThunk(
  GET_JOB_RESULTS_FILTER_FILES,
  async (id: string, { rejectWithValue }) => {
    const query = {
      select: 'file',
      limit: 40,
    };
    try {
      const data = await getRecursivelyJobResultsApi<{ file: string }[]>(id, query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
