import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOutlined, HistoryOutlined, SendOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Badge, Button, Input, InputRef, theme } from 'antd';
import { isEmpty } from 'lodash';
import { parseSearchHistoryToSearchResult } from '@utils/search';
import ChatBlocksSVG from '@assets/ChatBlockSVG';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import usePipelineQuery from '@hooks/usePipelineQuery';
import { useScreen } from '@hooks/useScreen';
import useScrollToBottomOnChange from '@hooks/useScrollToBottomOnChange';
import { useStateCleanup } from '@hooks/useStateCleanup';
import {
  CHAT_INITIAL_STATE_DESCRIPTION,
  CHAT_INITIAL_STATE_TITLE,
  CHAT_INPUT_PLACEHOLDER,
  INVALID_PIPELINE,
} from '@constants/chat';
import { SUBMIT_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import { PipelineDesiredStatusCodes, PipelineStatusCodes } from '@constants/pipelines';
import { getPipelineMeta } from '@redux/actions/metadataFiltersActions';
import { addNotification } from '@redux/actions/notificationActions';
import { createSearchSession } from '@redux/actions/searchActions';
import {
  appliedMetaFilterValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import {
  loadingLatestQueriesStatusSelector,
  pipelineLatestQueriesSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  IAPIPaginationData,
  IPipeline,
  ISearchResult,
  NotificationType,
  PipelineLatestQueries,
} from '@redux/types/types';
import SearchCardLayout from '@components/search/layouts/searchCard/SearchCardLayout';
import useActivatePipeline from '@pages/search/hooks/useActivatePipeline';
import styles from './conversationalSearch.module.scss';
import PlaygroundHeader from '../header/PlaygroundHeader';
import ConversationalResultsContainer from '../results/ConversationalResultsContainer';
import SearchNoResultsContainer from '../results/SearchNoResultsContainer';
import SearchHistoryLog from '../searchHistoryLog/SearchHistoryLog';
import SearchMetadataFilters from '../searchMetadataFilters/SearchMetadataFilters';

const { TextArea } = Input;
const { useToken } = theme;

interface IConversationalSearchProps {
  pipelineName: string;
  pipeline?: IPipeline | null;
  isExternal?: boolean;
  displayMetaDataFilters: boolean;
  displayResultsFileOptions?: boolean;
  displayResultsMoreOptions?: boolean;
  displayQueryHistory?: boolean;
  searchFiltersVisible?: boolean;
  onSearchFiltersClose?: () => void;
  onToggleFiltersClick?: () => void;
}

// TODO: Move merging history and chat results to a hook
const ConversationalSearch = ({
  pipelineName,
  pipeline,
  isExternal,
  displayMetaDataFilters,
  displayQueryHistory = true,
  displayResultsFileOptions = true,
  displayResultsMoreOptions = true,
  searchFiltersVisible = true,
  onSearchFiltersClose,
  onToggleFiltersClick,
}: IConversationalSearchProps) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;
  const { activatePipeline, activatingPipeline } = useActivatePipeline({
    isExternal,
    selectedPipelineName: pipelineName,
    allwaysPollSelectedPipeline: true,
  });
  const { chatResults, chatQueryStatus, chat } = usePipelineQuery();
  const { getFeedbackTags, resetFeedbackTags } = usePipelineFeedback();
  const { data: pipelines }: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, PipelineDesiredStatusCodes.DEPLOYED),
  );
  const pipelineLatestQueries: PipelineLatestQueries = useSelector(pipelineLatestQueriesSelector);
  const loadingLatestQueriesStatus: StatusCodes = useSelector(loadingLatestQueriesStatusSelector);
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);
  const chatResultsWrapperRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<InputRef>(null);
  const [chatQuery, setChatQuery] = useState<string>('');
  const [historySideMenuVisible, setHistorySideMenuVisible] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [sessionQueriesResults, setSessionQueriesResults] = useState<ISearchResult[] | null>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [displayTypingEffect, setDisplayTypingEffect] = useState<boolean>(true);

  const { reset } = useStateCleanup();

  useEffect(() => {
    reset(['pipelineStore.pipelineLatestQueries', 'pipelineStore.loadingLatestQueriesStatus']);
  }, []);

  const getChathResults = () => {
    if (sessionQueriesResults?.length) return sessionQueriesResults;
    return chatResults;
  };

  const isChatting = () => {
    return (
      chatQueryStatus === StatusCodes.IN_PROGRESS ||
      loadingLatestQueriesStatus === StatusCodes.IN_PROGRESS
    );
  };

  useScrollToBottomOnChange(chatResultsWrapperRef, [getChathResults(), isChatting()]);

  useEffect(() => {
    if (pipelineName) {
      dispatch(getPipelineMeta({ pipelineName, isExternal }));
      setSessionId(null);
    }
    resetFeedbackTags();
  }, [pipelineName]);

  useEffect(() => {
    if (!pipeline?.pipeline_id) return;

    getFeedbackTags({ pipelineId: pipeline.pipeline_id, isExternal });
  }, [pipeline?.pipeline_id]);

  useEffectUpdateOnly(() => {
    if (chatQueryStatus === StatusCodes.SUCCESS) {
      setChatQuery('');
      chatInputRef.current?.focus();
    }
  }, [chatQueryStatus]);

  useEffect(() => {
    if (sessionId) {
      const parsedResults = pipelineLatestQueries.data.map(parseSearchHistoryToSearchResult);
      const reversedParsedResults = parsedResults.reverse();

      setDisplayTypingEffect(false);
      setSessionQueriesResults(reversedParsedResults);
    }
  }, [pipelineLatestQueries]);

  useEffect(() => {
    setDisplayTypingEffect(true);

    if (sessionQueriesResults?.length) {
      const lastChatSearchResults = chatResults[chatResults.length - 1];
      const lastSessionSearchResults = sessionQueriesResults[sessionQueriesResults.length - 1];

      if (lastChatSearchResults?.query_id === lastSessionSearchResults?.query_id) {
        const updatedSessionQueriesResults = sessionQueriesResults
          .slice(0, -1)
          .concat(lastChatSearchResults);
        setSessionQueriesResults(updatedSessionQueriesResults);
        return;
      }

      setSessionQueriesResults([...sessionQueriesResults, lastChatSearchResults]);
    }
  }, [chatResults]);

  const getSelectedPipeline = (): IPipeline | null =>
    pipeline || pipelines.find(({ name }) => name === pipelineName) || null;

  const createSession = async (pipelineId: string) => {
    const resultAction = await dispatch(createSearchSession({ pipelineId, isExternal }));
    if (resultAction) {
      const content = unwrapResult(resultAction as any);
      return content?.search_session_id;
    }
    return '';
  };

  const onClickChat = async (query: string) => {
    if (!query) return;
    let currentSession = sessionId;
    const { pipeline_id: pipelineId, status } = getSelectedPipeline() || {};

    if (status === PipelineStatusCodes.IDLE) {
      activatePipeline(pipelineName);
      chatInputRef.current?.blur();
      return;
    }

    if (!pipelineId) {
      dispatch(
        addNotification({
          content: INVALID_PIPELINE,
          type: NotificationType.Error,
        }),
      );
      return;
    }

    if (!currentSession) {
      currentSession = await createSession(pipelineId);
      setSessionId(currentSession);
    }

    chat({
      pipelineName,
      query,
      sessionId: currentSession!,
      filters: selectedMetaFilterValues,
      isExternal,
    });
  };

  const openSearchHistory = () => {
    setHistorySideMenuVisible(true);
  };

  const closeSearchHistory = () => {
    setHistorySideMenuVisible(false);
  };

  // Renders

  const renderChatInput = () => {
    return (
      <div className={styles.chatInputWrapper}>
        {isSmallerScreen && (
          <Badge dot={!isEmpty(selectedMetaFilterValues) || !isEmpty(appliedMetaFilterValues)}>
            <Button icon={<FilterOutlined />} onClick={onToggleFiltersClick} />
          </Badge>
        )}
        <div
          className={`${styles.chatInputContainer} ${
            isInputFocused ? styles.chatInputContainer_focused : ''
          }`}
          style={
            isInputFocused
              ? {
                  borderColor: token.colorPrimary,
                  boxShadow: `0 0 4px 0 ${token.colorPrimaryBorderHover}`,
                }
              : {}
          }
        >
          {displayQueryHistory && (
            <Button
              type="link"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => (historySideMenuVisible ? closeSearchHistory() : openSearchHistory())}
            />
          )}
          <TextArea
            ref={chatInputRef}
            className={styles.chatInputContainer_input}
            placeholder={CHAT_INPUT_PLACEHOLDER}
            disabled={isChatting() || activatingPipeline}
            value={chatQuery}
            onChange={(event) => setChatQuery(event.target.value)}
            autoFocus
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            autoSize={{ maxRows: 4 }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
                event.preventDefault();
                if (chatQuery && !activatingPipeline && !isChatting()) {
                  onClickChat(chatQuery);
                }
              }
            }}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            disabled={!chatQuery || activatingPipeline}
            loading={isChatting()}
            onClick={() => onClickChat(chatQuery)}
          >
            {chatQuery && !isSmallerScreen ? SUBMIT_BUTTON_LABEL : ''}
          </Button>
        </div>
      </div>
    );
  };

  const renderEmptyState = () => {
    const currentSelectedPipeline = getSelectedPipeline();

    // TODO: Break states into sub-components
    if (
      currentSelectedPipeline?.status === PipelineStatusCodes.IDLE ||
      currentSelectedPipeline?.status === PipelineStatusCodes.ACTIVATING
    )
      return (
        <SearchNoResultsContainer
          selectedPipeline={currentSelectedPipeline}
          status={chatQueryStatus}
          query={chatQuery}
          isExternal={isExternal}
        />
      );

    return (
      <div className={styles.initialState}>
        <ChatBlocksSVG />
        <div className={styles.initialState_content}>
          <h4>{CHAT_INITIAL_STATE_TITLE}</h4>
          <div className={styles.initialState_content_description}>
            {CHAT_INITIAL_STATE_DESCRIPTION}
          </div>
        </div>
      </div>
    );
  };

  const renderChatResults = () => {
    return (
      <div className={styles.chatResults}>
        {!getChathResults().length && !isChatting() ? (
          renderEmptyState()
        ) : (
          <div className={styles.chatResults_resultsWrapper} ref={chatResultsWrapperRef}>
            <div className={styles.zeroHeightContainer}>
              <ConversationalResultsContainer
                isExternal={isExternal}
                pipelineId={getSelectedPipeline()?.pipeline_id || ''}
                pipelineName={pipelineName}
                results={getChathResults()}
                displayFileOptions={displayResultsFileOptions}
                displayMoreOptions={displayResultsMoreOptions}
                status={chatQueryStatus}
                query={chatQuery}
                displayTypingEffect={displayTypingEffect}
              />
            </div>
          </div>
        )}
        {renderChatInput()}
      </div>
    );
  };

  const { pipeline_id: pipelineId } = getSelectedPipeline() || {};

  return (
    <>
      <SearchCardLayout
        header={
          <PlaygroundHeader selectedPipeline={getSelectedPipeline()} isLoading={isChatting()} />
        }
        sideMenu={
          searchFiltersVisible && (
            <SearchMetadataFilters
              pipelineName={pipelineName}
              displayMetaDataFilters={displayMetaDataFilters}
              isExternal={isExternal}
              searchStatus={chatQueryStatus}
              onSearchFiltersClose={onSearchFiltersClose}
            />
          )
        }
        body={renderChatResults()}
        righSideMenu={
          historySideMenuVisible && (
            <SearchHistoryLog
              currentSessionId={sessionId}
              pipelineId={pipelineId}
              pipelineName={pipelineName}
              onSessionChange={setSessionId}
              onClose={closeSearchHistory}
            />
          )
        }
      />
    </>
  );
};

export default ConversationalSearch;
