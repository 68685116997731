import { AxiosError } from 'axios';

export class SilentError<T = unknown> extends Error {
  response?: AxiosError<T>['response'];

  constructor(message: string) {
    super(message);
    this.name = 'SilentError';
  }
}
