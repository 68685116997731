export const CONTACT_SUPPORT_MODAL_TITLE = 'Contact support';
export const CONTACT_SUPPORT_MODAL_SUBMIT_BUTTON_LABEL = 'Send to support';
export const CONTACT_SUPPORT_MODAL_SUBTITLE = 'Need assistance?';
export const CONTACT_SUPPORT_MODAL_DESCRIPTION = `We're here to help. Share the details of your problem below. The more you tell us, the
better we can assist. Our support team will get back to you promptly.`;
export const CONTACT_SUPPORT_REQUIRED_FIELD = '* ';
export const CONTACT_SUPPORT_REQUEST_TYPE = 'Request type';
export const CONTACT_SUPPORT_ARE_YOU_BLOCKED = 'Is this issue blocking you?';
export const CONTACT_SUPPORT_DESCRIPTION = 'Description';
export const CONTACT_SUPPORT_SCREENSHOTS = 'Screenshots';
export const CONTACT_SUPPORT_DESCRIPTION_PLACEHOLDER = `Provide the details of the problem, including the names of pipelines and workspaces, any logs or error messages you received, and any other information that may help us diagnose the issue.`;
export const CONTACT_SUPPORT_UPLOAD_DESCRIPTION = 'Click or drag files to this area to upload';
export const CONTACT_SUPPORT_UPLOAD_HINT = 'Accepted file types: PNG, JPG';
export const CONATCT_SUPPORT_FILE_TYPE_ERROR_ALERT = `Only %fileFormats% file formats are supported.`;
export const CONTACT_SUPPORT_FILES_LIMIT_LABEL = ' Maximum 12.';

export const CONTACT_SUPPORT_REQUEST_TYPE_OPTIONS = {
  bug: {
    label: 'Bug',
    key: 'bug',
  },
  featureRequest: {
    label: 'Feature request',
    key: 'featureRequest',
  },
  other: {
    label: 'Other',
    key: 'other',
  },
};

export const CONTACT_SUPPORT_ARE_YOU_BLOCKED_OPTIONS = {
  option1: {
    label: 'No, I found a workaround',
    key: 'option1',
  },
  option2: {
    label: `Yes, I can't work on my current tasks.`,
    key: 'option2',
  },
  option3: {
    label: `Yes, but I can work on something else in the meantime.`,
    key: 'option3',
  },
  option4: {
    label: `Yes, my users can't search as the functionality is broken.`,
    key: 'option4',
  },
};

export enum FileTypesImages {
  png = 'png',
  jpg = 'jpg',
}

export const uploadImageTypes = {
  png: 'image/png',
  jpg: 'image/jpg',
};
