import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getErrorSeverityType,
  getStatusCode,
  handleErrorNotification,
  normalizeErrorMessage,
} from '@utils/error';
import { downloadBlobFile } from '@utils/file';
import { Navigator } from '@utils/navigator';
import { getODataFilterFrom } from '@utils/odata';
import { interpolateString } from '@utils/string';
import { activatePipelineExternalApi, getPipelineExternalApi } from '@api/external/pipeline';
import {
  activatePipelineApi,
  createPipelineApi,
  deletePipelineApi,
  deletePrototypeLinkApi,
  deployPipelineYamlApi,
  generatePrototypeLinkApi,
  getPipelineApi,
  getPipelineDocumentsApi,
  getPipelineIndexing,
  getPipelineLogsApi,
  getPipelineLogsCSVApi,
  getPipelinePromptsApi,
  getPipelinePrototypeApi,
  getPipelineQueryHistoryApi,
  getPipelinesApi,
  getPipelineStatisticsApi,
  getPipelineYamlApi,
  getRecursivelyPipelineQueryHistoryApi,
  getRuntimeIssueDetectionApi,
  undeployPipelineYamlApi,
  updatePipelineApi,
  updatePipelinePrototypeApi,
  updatePipelineYamlApi,
  validatePipelineYamlApi,
} from '@api/pipeline';
import { FilterType } from '@constants/data-table';
import { HTTPStatusCode, MIMETypes, StatusCodes } from '@constants/enum/common';
import {
  EXPORT_PIPELINE_DOCUMENTS_ACTIVATING,
  EXPORT_PIPELINE_DOCUMENTS_NOT_READY,
  EXPORT_PIPELINE_DOCUMENTS_STARTED,
  EXPORT_PIPELINE_DOCUMENTS_SUCCEEDED,
  PIPELINE_RENAMED_SUCCESSFULLY_MESSAGE,
  PIPELINE_UPDATED_SUCCESSFULLY_MESSAGE,
  PipelineDesiredStatusCodes,
  PipelineMessages,
  PIPELINES_LIST_SORTING_PARAMS_BY_KEY,
  PipelineStatusCodes,
  VALIDATION_ERRORS,
} from '@constants/pipelines';
import { initialState } from '@redux/rootReducer';
import { store } from '@redux/store';
import {
  ACTIVATE_PIPELINE,
  CHECK_PIPELINE_LOGS_HEALTH,
  CREATE_PIPELINE,
  DELETE_MULTIPLE_PIPELINES,
  DELETE_PIPELINE,
  DELETE_PROTOTYPE_LINK,
  DEPLOY_PIPELINE,
  DUPLICATE_PIPELINE,
  EXPORT_PIPELINE_DOCUMENTS_CSV,
  EXPORT_PIPELINE_LOGS_CSV,
  FETCH_PIPELINE,
  FETCH_PIPELINE_INDEXING,
  FETCH_PIPELINE_YAML,
  FETCH_PIPELINES,
  GENERATE_PROTOTYPE_LINK,
  GET_PIPELINE_LOGS,
  GET_PIPELINE_PROMPTS,
  GET_PIPELINE_PROTOTYPE,
  IPipeline,
  IPipelineLogsParams,
  IValidatePipelineParams,
  NotificationDuration,
  NotificationMode,
  NotificationType,
  PipelineServiceLevel,
  RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
  RESET_FETCH_PIPELINE_YAML_STATUS,
  RESET_NEW_PIPELINE_NAME,
  RESET_PIPELINE,
  RESET_PIPELINE_DETAILS_STATE,
  RESET_PIPELINE_ERRORS,
  RESET_PIPELINE_MESSAGE,
  RESET_PIPELINE_YAML,
  RESET_PROTOTYPE_LINK,
  RESET_RENAME_PIPELINE_NAME_STATUS,
  RESET_VALIDATE_NEW_PIPELINE_NAME,
  RUNTIME_ISSUE_DETECTION,
  SELECT_PIPELINES_LIST_SORT_VALUE,
  SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
  SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE,
  SET_INDEXING_PIPELINE_EDITED_STATE,
  SET_NEW_PIPELINE_NAME,
  SET_PIPELINE_EDITED_STATE,
  SET_PIPELINE_INDEXING_YAML,
  SET_PIPELINE_LATEST_QUERIES,
  SET_PIPELINE_QUERY_YAML,
  SET_PIPELINE_STATISTICS,
  SET_PIPELINE_YAML,
  SET_QUERY_PIPELINE_EDITED_STATE,
  START_POLLING_DEPLOYED_PIPELINES,
  START_POLLING_DRAFT_PIPELINES,
  START_POLLING_PIPELINE_INDEXING,
  START_POLLING_PIPELINE_LOGS,
  START_POLLING_PIPELINE_STATUS,
  START_POLLING_PIPELINES_LIST,
  STOP_POLLING_DEPLOYED_PIPELINES,
  STOP_POLLING_DRAFT_PIPELINES,
  STOP_POLLING_PIPELINE_INDEXING,
  STOP_POLLING_PIPELINE_LOGS,
  STOP_POLLING_PIPELINE_STATUS,
  STOP_POLLING_PIPELINES_LIST,
  UNDEPLOY_PIPELINE,
  UPDATE_PIPELINE,
  UPDATE_PIPELINE_PROTOTYPE,
  UPDATE_PIPELINE_YAML,
  VALIDATE_PIPELINE_YAML,
} from '@redux/types/types';
import { addNotification, addSequentialNotification } from './notificationActions';

let intervalPipelinesList: any;
let intervalPipelinesListCounter: number = 0;
let intervalDeployed: any;
let intervalDeployedCounter: number = 0;
let intervalDraft: any;
let intervalDraftCounter: number = 0;
let intervalStatus: any;
let intervalIndexing: any;
let intervalLogs: any;

export const setActiveTabPipelineDetailsPage = (value: string) => ({
  type: SET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
  payload: value,
});

export const setActiveTabPipelinesList = (value: string) => ({
  type: SET_ACTIVE_TAB_PIPELINES_LANDING_PAGE,
  payload: value,
});

export const selectSortValuePipelinesList = (value: string) => ({
  type: SELECT_PIPELINES_LIST_SORT_VALUE,
  payload: value,
});

export const resetActiveTabPipelineDetailsPage = {
  type: RESET_ACTIVE_TAB_PIPELINE_DETAILS_PAGE,
};

export const resetMessage = {
  type: RESET_PIPELINE_MESSAGE,
};

export const resetValidateNewPipelineName = {
  type: RESET_VALIDATE_NEW_PIPELINE_NAME,
};

export const resetPipelineErrors = {
  type: RESET_PIPELINE_ERRORS,
};

export const resetRenamePipelineNameStatus = {
  type: RESET_RENAME_PIPELINE_NAME_STATUS,
};

export const resetPrototypeLink = {
  type: RESET_PROTOTYPE_LINK,
};

export const resetPipelineDetailsState = {
  type: RESET_PIPELINE_DETAILS_STATE,
};

export const resetPipeline = {
  type: RESET_PIPELINE,
};

export const resetPipelineYaml = {
  type: RESET_PIPELINE_YAML,
};

export const resetFetchPipelineYamlStatus = {
  type: RESET_FETCH_PIPELINE_YAML_STATUS,
};

export const resetNewPipelineName = {
  type: RESET_NEW_PIPELINE_NAME,
};

export function setPipelineYaml(yaml: { indexingYaml: string; queryYaml: string }) {
  return {
    type: SET_PIPELINE_YAML,
    payload: yaml,
  };
}

export function setPipelineIndexingYaml(code: string) {
  return {
    type: SET_PIPELINE_INDEXING_YAML,
    payload: code,
  };
}

export function setPipelineQueryYaml(code: string) {
  return {
    type: SET_PIPELINE_QUERY_YAML,
    payload: code,
  };
}

export const setPipelineName = (newPipelineName: string) => ({
  type: SET_NEW_PIPELINE_NAME,
  payload: newPipelineName,
});

export const setPipelineEditedStateTo = (isEdited: boolean) => ({
  type: SET_PIPELINE_EDITED_STATE,
  payload: isEdited,
});

export const setIndexingPipelineEditedStateTo = (isEdited: boolean) => ({
  type: SET_INDEXING_PIPELINE_EDITED_STATE,
  payload: isEdited,
});

export const setQueryPipelineEditedStateTo = (isEdited: boolean) => ({
  type: SET_QUERY_PIPELINE_EDITED_STATE,
  payload: isEdited,
});

const getNotificationContent = (error: any) => {
  const statusCode = getStatusCode(error);
  if (error.content) return error.content;
  if (statusCode === HTTPStatusCode.CONFLICT) {
    return VALIDATION_ERRORS.PIPELINE_NAME_ALREADY_USED;
  }
  return normalizeErrorMessage(error);
};

export const fetchPipelineYaml = createAsyncThunk(FETCH_PIPELINE_YAML, async (name: string) => {
  const response = await getPipelineYamlApi(name);
  return response.data;
});

export const fetchPipeline = createAsyncThunk(
  FETCH_PIPELINE,
  async (
    { pipelineName, isExternal }: { pipelineName: string; isExternal?: boolean },
    { getState },
  ) => {
    const apiCall = isExternal ? getPipelineExternalApi : getPipelineApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    const { data } = await apiCall(pipelineName, workspace);
    return data;
  },
);

export const fetchPipelineIndexing = createAsyncThunk(
  FETCH_PIPELINE_INDEXING,
  async (name: string) => {
    const response = await getPipelineIndexing(name);
    return response.data;
  },
);

export const fetchPipelines = createAsyncThunk(
  FETCH_PIPELINES,
  async (
    {
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      desiredStatus,
      status,
      supportsPrompt,
      serviceLevel,
    }: {
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      desiredStatus?: PipelineDesiredStatusCodes;
      status?: PipelineStatusCodes;
      supportsPrompt?: boolean;
      serviceLevel?: PipelineServiceLevel;
      isPollRequest?: boolean;
    },
    { rejectWithValue, dispatch },
  ) => {
    const state = store.getState() as any;
    const { sortValuePipelinesList } = state.pipelineStore;
    const sortingKey = (sortValue ||
      sortValuePipelinesList) as keyof typeof PIPELINES_LIST_SORTING_PARAMS_BY_KEY;
    dispatch(selectSortValuePipelinesList(sortingKey));
    const { field, order } = PIPELINES_LIST_SORTING_PARAMS_BY_KEY[sortingKey] || {};

    const query = {
      page_number: currentPage,
      limit: pageSize,
      name: searchValue,
      desired_status: desiredStatus,
      status,
      supports_prompt: supportsPrompt,
      service_level: serviceLevel,
      field,
      order,
    };
    try {
      const response = await getPipelinesApi(query);
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const stopPollingPipelinesList = () => {
  clearInterval(intervalPipelinesList);
  intervalPipelinesListCounter = 0;
  return {
    type: STOP_POLLING_PIPELINES_LIST,
  };
};

export const startPollingPipelinesList =
  (currentPage: number, pageSize: number, searchValue: string, interval: number) =>
  (dispatch: any) => {
    dispatch(stopPollingPipelinesList());
    intervalPipelinesList = setInterval(() => {
      // Temporary performance improvement, we will remove polling for better solution
      // Check if previous poll request is in progress and stop polling if no changes are made in last 10 minutes
      const state = store.getState() as any;
      const { fetchPipelinesListStatus, activeTabPipelinesLandingPage } = state.pipelineStore;
      if (fetchPipelinesListStatus === StatusCodes.IN_PROGRESS) return;
      intervalPipelinesListCounter++;
      if (intervalPipelinesListCounter > 60) {
        dispatch(stopPollingPipelinesList());
      }

      dispatch(
        fetchPipelines({
          currentPage,
          pageSize,
          searchValue,
          serviceLevel: activeTabPipelinesLandingPage,
          isPollRequest: true,
        }),
      );
    }, interval);
    return {
      type: START_POLLING_PIPELINES_LIST,
    };
  };

export const stopPollingDeployedPipelines = () => {
  clearInterval(intervalDeployed);
  intervalDeployedCounter = 0;
  return {
    type: STOP_POLLING_DEPLOYED_PIPELINES,
  };
};

export const startPollingDeployedPipelines =
  (currentPage: number, pageSize: number, searchValue: string, interval: number) =>
  (dispatch: any) => {
    dispatch(stopPollingDeployedPipelines());
    intervalDeployed = setInterval(() => {
      // Temporary performance improvement, we will remove polling for better solution
      // Check if previous poll request is in progress and stop polling if no changes are made in last 10 minutes
      const state = store.getState() as any;
      const { fetchDeployedInProgress } = state.pipelineStore;
      if (fetchDeployedInProgress) return;
      intervalDeployedCounter++;
      if (intervalDeployedCounter > 60) {
        dispatch(stopPollingDeployedPipelines());
      }

      dispatch(
        fetchPipelines({
          currentPage,
          pageSize,
          searchValue,
          desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
        }),
      );
    }, interval);
    return {
      type: START_POLLING_DEPLOYED_PIPELINES,
    };
  };

export const stopPollingDraftPipelines = () => {
  clearInterval(intervalDraft);
  intervalDraftCounter = 0;
  return {
    type: STOP_POLLING_DRAFT_PIPELINES,
  };
};

export const startPollingDraftPipelines =
  (currentPage: number, pageSize: number, searchValue: string, interval: number) =>
  (dispatch: any) => {
    dispatch(stopPollingDraftPipelines());
    intervalDraft = setInterval(() => {
      // Temporary performance improvement, we will remove polling for better solution
      // Check if previous poll request is in progress and stop polling if no changes are made in last 10 minutes
      const state = store.getState() as any;
      const { fetchDraftInProgress } = state.pipelineStore;
      if (fetchDraftInProgress) return;
      intervalDraftCounter++;
      if (intervalDraftCounter > 60) {
        dispatch(stopPollingDraftPipelines());
      }
      dispatch(
        fetchPipelines({
          currentPage,
          pageSize,
          searchValue,
          desiredStatus: PipelineDesiredStatusCodes.UNDEPLOYED,
        }),
      );
    }, interval);
    return {
      type: START_POLLING_DRAFT_PIPELINES,
    };
  };

export const deletePipeline = createAsyncThunk(
  DELETE_PIPELINE,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deletePipelineApi(name);
      dispatch(
        addNotification({
          content: PipelineMessages.DELETE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const deleteMultiplePipelines = createAsyncThunk(
  DELETE_MULTIPLE_PIPELINES,
  async (pipelineNames: string[], { rejectWithValue, dispatch }) => {
    try {
      const requests = pipelineNames.map((name) => deletePipelineApi(name));
      const responses = await Promise.all(requests);
      dispatch(
        addNotification({
          content: PipelineMessages.MULTIPLE_DELETE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return responses;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const deployPipeline = createAsyncThunk(
  DEPLOY_PIPELINE,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deployPipelineYamlApi(name);
      dispatch(
        addNotification({
          content: PipelineMessages.DEPLOY_START_SUCCESS,
        }),
      );
      return response.data;
    } catch (error) {
      const statusCode = getStatusCode(error);
      handleErrorNotification(error, dispatch, {
        type:
          statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
            ? NotificationType.Warning
            : NotificationType.Error,
        duration: NotificationDuration.long,
      });
      return rejectWithValue(error);
    }
  },
);

export const undeployPipeline = createAsyncThunk(
  UNDEPLOY_PIPELINE,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await undeployPipelineYamlApi(name);
      dispatch(
        addNotification({
          content: PipelineMessages.UNDEPLOY_START_SUCCESS,
        }),
      );
      return response.data;
    } catch (error) {
      const statusCode = getStatusCode(error);
      handleErrorNotification(error, dispatch, {
        type:
          statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
            ? NotificationType.Warning
            : NotificationType.Error,
      });
      return rejectWithValue(error);
    }
  },
);

export const activatePipeline = createAsyncThunk(
  ACTIVATE_PIPELINE,
  async (
    { pipelineName, isExternal }: { pipelineName: string; isExternal?: boolean },
    { rejectWithValue, dispatch, getState },
  ) => {
    const apiCall = isExternal ? activatePipelineExternalApi : activatePipelineApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    try {
      const { data } = await apiCall(pipelineName, workspace);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const createPipeline = createAsyncThunk(
  CREATE_PIPELINE,
  async (
    {
      code,
      pipelineName,
      indexingYaml,
      queryYaml,
    }: {
      code?: string;
      pipelineName?: string;
      indexingYaml?: string;
      queryYaml?: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const payload = {
        name: pipelineName!,
        config: code,
        indexing_yaml: indexingYaml,
        query_yaml: queryYaml,
      };

      const response = await createPipelineApi(payload);
      dispatch(
        addNotification({
          content: PipelineMessages.SAVE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      const statusCode = getStatusCode(error);
      handleErrorNotification(getNotificationContent(error), dispatch, {
        duration: NotificationDuration.long,
        type:
          statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
            ? NotificationType.Warning
            : NotificationType.Error,
      });
      return rejectWithValue(error);
    }
  },
);

export const updatePipelineYaml = createAsyncThunk(
  UPDATE_PIPELINE_YAML,
  async (
    {
      name,
      code,
      indexing_yaml,
      query_yaml,
    }: { name: string; code?: string; indexing_yaml?: string; query_yaml?: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await updatePipelineYamlApi(
        name,
        code || { indexing_yaml: indexing_yaml || '', query_yaml: query_yaml || '' },
      );
      dispatch(
        addNotification({
          content: PipelineMessages.SAVE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      const statusCode = getStatusCode(error);
      handleErrorNotification(getNotificationContent(error), dispatch, {
        duration: NotificationDuration.long,
        type:
          statusCode === HTTPStatusCode.UNDEPLOYED_PIPELINE_ERROR_STATUS
            ? NotificationType.Warning
            : NotificationType.Error,
      });
      return rejectWithValue(error);
    }
  },
);

export const updatePipeline = createAsyncThunk(
  UPDATE_PIPELINE,
  async (
    {
      pipelineName,
      newPipelineName,
      serviceLevel,
    }: { pipelineName: string; newPipelineName?: string; serviceLevel?: string },
    { dispatch, rejectWithValue },
  ) => {
    if (!newPipelineName && !serviceLevel)
      return rejectWithValue({ content: PipelineMessages.MISSIGN_PROPERTIES_ERROR });
    const payload = {
      name: newPipelineName,
      service_level: serviceLevel,
    };

    try {
      const response = await updatePipelineApi(pipelineName, payload);
      dispatch(
        addNotification({
          content: newPipelineName
            ? PIPELINE_RENAMED_SUCCESSFULLY_MESSAGE
            : PIPELINE_UPDATED_SUCCESSFULLY_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startPollingPipelineStatus =
  ({ pipelineName, isExternal }: { pipelineName: string; isExternal?: boolean }) =>
  (dispatch: any) => {
    clearInterval(intervalStatus);
    intervalStatus = setInterval(() => dispatch(fetchPipeline({ pipelineName, isExternal })), 8000);
    return {
      type: START_POLLING_PIPELINE_STATUS,
    };
  };

export const stopPollingPipelineStatus = () => {
  clearInterval(intervalStatus);
  return {
    type: STOP_POLLING_PIPELINE_STATUS,
  };
};

export const startPollingPipelineIndexing = (name: string) => (dispatch: any) => {
  clearInterval(intervalIndexing);
  intervalIndexing = setInterval(() => dispatch(fetchPipelineIndexing(name)), 8000);
  return {
    type: START_POLLING_PIPELINE_INDEXING,
  };
};

export const stopPollingPipelineIndexing = () => {
  clearInterval(intervalIndexing);
  return {
    type: STOP_POLLING_PIPELINE_INDEXING,
  };
};

export const duplicatePipeline = createAsyncThunk(
  DUPLICATE_PIPELINE,
  async (name: string, { rejectWithValue }) => {
    try {
      const yamlResponse = await getPipelineYamlApi(name);
      const { indexing_yaml: indexingYaml, query_yaml: queryYaml } = yamlResponse.data;
      setTimeout(() => Navigator.workspaceNavigate(`/pipelines/builder`));
      return { indexingYaml, queryYaml };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPipelineStatistics = createAsyncThunk(
  SET_PIPELINE_STATISTICS,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getPipelineStatisticsApi(name);
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getPipelineLatestQueries = createAsyncThunk(
  SET_PIPELINE_LATEST_QUERIES,
  async (
    { name, limit, filter }: { name: string; limit?: number; filter?: string },
    { rejectWithValue, dispatch },
  ) => {
    const query = {
      limit,
      filter,
    };
    try {
      const response = await getPipelineQueryHistoryApi(name, query);
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

// TODO: Remove once we add infinite scrolling to prompt search results
export const getAllPipelineLatestQueries = createAsyncThunk(
  SET_PIPELINE_LATEST_QUERIES,
  async (
    { name, limit, filter }: { name: string; limit?: number; filter?: string },
    { rejectWithValue, dispatch },
  ) => {
    const query = {
      limit,
      filter,
    };
    try {
      const data = await getRecursivelyPipelineQueryHistoryApi(name, query);
      return { data, has_more: false, total: data.length };
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const generatePrototypeLink = createAsyncThunk(
  GENERATE_PROTOTYPE_LINK,
  async (
    {
      pipelineName,
      expirationDate,
      description,
      showMetadataFilters,
      showFiles,
      brandColor,
      brandLogo,
    }: {
      pipelineName: string;
      expirationDate: Date;
      description?: string;
      showMetadataFilters?: boolean;
      showFiles?: boolean;
      brandColor?: string;
      brandLogo?: string | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await generatePrototypeLinkApi(pipelineName, {
        expirationDate,
        description,
        showMetadataFilters,
        showFiles,
        brandColor,
        brandLogo,
      });
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const deletePrototypeLink = createAsyncThunk(
  DELETE_PROTOTYPE_LINK,
  async (sharedPrototypeId: string) => {
    const response = await deletePrototypeLinkApi(sharedPrototypeId);
    return response.data;
  },
);

export const getPipelinePrototype = createAsyncThunk(
  GET_PIPELINE_PROTOTYPE,
  async ({ pipelineName }: { pipelineName: string }, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await getPipelinePrototypeApi(pipelineName);

      return data[0];
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const updateSharedPrototype = createAsyncThunk(
  UPDATE_PIPELINE_PROTOTYPE,
  async (
    {
      sharedPrototypeId,
      description,
      showMetadataFilters,
      showFiles,
      brandColor,
      brandLogo,
    }: {
      sharedPrototypeId: string;
      description?: string;
      showMetadataFilters: boolean;
      showFiles: boolean;
      brandColor?: string;
      brandLogo?: string | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await updatePipelinePrototypeApi(sharedPrototypeId, {
        showMetadataFilters,
        showFiles,
        description,
        brandColor,
        brandLogo,
      });
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const getPipelinePrompts = createAsyncThunk(
  GET_PIPELINE_PROMPTS,
  async (pipelineName: string, { rejectWithValue }) => {
    try {
      const { data } = await getPipelinePromptsApi(pipelineName);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPipelinelogs = createAsyncThunk(
  GET_PIPELINE_LOGS,
  async (params: IPipelineLogsParams, { rejectWithValue, dispatch }) => {
    const { pipelineName, filterValues, searchValue, ...queryParams } = params;

    const valueFilters = filterValues && getODataFilterFrom(filterValues);
    const searchFilter = searchValue && `message eq '${searchValue}'`;

    const filter = searchFilter
      ? `${valueFilters ? `${valueFilters} and ` : ''}${searchFilter}`
      : valueFilters;

    const paramsApi = {
      filter,
      ...queryParams,
    };

    try {
      const { data } = await getPipelineLogsApi(pipelineName, paramsApi);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const checkPipelinelogsHealth = createAsyncThunk(
  CHECK_PIPELINE_LOGS_HEALTH,
  async (params: IPipelineLogsParams, { rejectWithValue, dispatch }) => {
    const { pipelineName, filterValues, ...queryParams } = params;

    const filterValuesApi = {
      ...filterValues,
      level: [{ key: 'error', label: 'error', type: FilterType.MULTI_SELECT, value: 'error' }],
    };

    const valueFilters = filterValuesApi && getODataFilterFrom(filterValuesApi);
    const filter = `${valueFilters}`;
    const paramsApi = {
      filter,
      ...queryParams,
    };

    try {
      const { data } = await getPipelineLogsApi(pipelineName, paramsApi);
      return data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const exportPipelineLogsCSV = createAsyncThunk(
  EXPORT_PIPELINE_LOGS_CSV,
  async (name: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getPipelineLogsCSVApi(name);
      downloadBlobFile(`${name}.csv`, response.data, MIMETypes.CSV);
      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const startPollingPipelineLogs = (params: IPipelineLogsParams) => (dispatch: any) => {
  intervalLogs = setInterval(() => dispatch(getPipelinelogs(params)), 8000);
  return {
    type: START_POLLING_PIPELINE_LOGS,
  };
};

export const stopPollingPipelineLogs = () => {
  clearInterval(intervalLogs);
  return {
    type: STOP_POLLING_PIPELINE_LOGS,
  };
};

export const validatePipelineYaml = createAsyncThunk(
  VALIDATE_PIPELINE_YAML,
  async (params: IValidatePipelineParams, { rejectWithValue }) => {
    try {
      const response = await validatePipelineYamlApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRuntimeIssueDetection = createAsyncThunk(
  RUNTIME_ISSUE_DETECTION,
  async (pipelineName: string, { rejectWithValue }) => {
    try {
      const response = await getRuntimeIssueDetectionApi(pipelineName);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportPipelineDocumentsCSV = createAsyncThunk(
  EXPORT_PIPELINE_DOCUMENTS_CSV,
  async (params: Pick<IPipeline, 'name' | 'status'>, { rejectWithValue, dispatch }) => {
    try {
      if (params.status === PipelineStatusCodes.IDLE) {
        await dispatch(activatePipeline({ pipelineName: params.name }));
        dispatch(
          addSequentialNotification({
            content: EXPORT_PIPELINE_DOCUMENTS_ACTIVATING,
            type: NotificationType.Warning,
          }),
        );
        return true;
      }

      if (params.status !== PipelineStatusCodes.DEPLOYED) {
        dispatch(
          addSequentialNotification({
            content: EXPORT_PIPELINE_DOCUMENTS_NOT_READY,
            type: NotificationType.Warning,
          }),
        );
        return true;
      }

      dispatch(
        addSequentialNotification({
          content: EXPORT_PIPELINE_DOCUMENTS_STARTED,
          type: NotificationType.Loading,
          mode: NotificationMode.Message,
          duration: 0,
        }),
      );

      const fileName = `${params.name}-documents.csv`;

      const response = await getPipelineDocumentsApi(params.name);

      downloadBlobFile(fileName, response.data, MIMETypes.CSV);

      dispatch(
        addSequentialNotification({
          content: interpolateString(
            EXPORT_PIPELINE_DOCUMENTS_SUCCEEDED,
            { fileName },
            false,
          ) as string,
          type: NotificationType.Success,
        }),
      );

      return response.data;
    } catch (error) {
      handleErrorNotification(error, dispatch, {
        sequential: true,
        type: getErrorSeverityType(error),
      });
      return rejectWithValue(error);
    }
  },
);
