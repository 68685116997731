import produce from 'immer';
import {
  FULFILLED,
  GET_METADATA_VALUES,
  GET_PIPELINE_META,
  MetadataFiltersValue,
  PENDING,
  REJECTED,
  SELECT_METADATA_FILTER_VALUES,
  SET_APPLIED_METADATA_FILTER_VALUES,
} from '@redux/types/types';
import { StatusCodes } from '@src/constants/enum/common';

interface IInitialStateProps {
  pipelineMeta: Record<string, { type: string }>;
  pipelineMetaValues: Record<string, MetadataFiltersValue>;
  selectedMetaFilterValues: Record<string, MetadataFiltersValue>;
  appliedMetaFilterValues: Record<string, MetadataFiltersValue>;
  getPipelineMetaStatus: StatusCodes;
  getMetadataValuesStatus: StatusCodes;
}

export const initialState: IInitialStateProps = {
  pipelineMeta: {},
  pipelineMetaValues: {},
  selectedMetaFilterValues: {},
  appliedMetaFilterValues: {},
  getPipelineMetaStatus: StatusCodes.IDLE,
  getMetadataValuesStatus: StatusCodes.IDLE,
};

function metadataFiltersReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;

    switch (action.type) {
      case `${GET_METADATA_VALUES}/${PENDING}`:
        localDraft.getMetadataValuesStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_METADATA_VALUES}/${FULFILLED}`:
        localDraft.getMetadataValuesStatus = StatusCodes.SUCCESS;
        localDraft.pipelineMetaValues = { ...localDraft.pipelineMetaValues, ...action.payload };
        break;
      case `${GET_METADATA_VALUES}/${REJECTED}`:
        localDraft.getMetadataValuesStatus = StatusCodes.ERROR;
        break;

      case `${GET_PIPELINE_META}/${PENDING}`:
        localDraft.getPipelineMetaStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_PIPELINE_META}/${FULFILLED}`:
        localDraft.getPipelineMetaStatus = StatusCodes.SUCCESS;
        localDraft.pipelineMeta = action.payload;
        break;
      case `${GET_PIPELINE_META}/${REJECTED}`:
        localDraft.getPipelineMetaStatus = StatusCodes.ERROR;
        break;

      case SELECT_METADATA_FILTER_VALUES:
        localDraft.selectedMetaFilterValues = action.payload;
        break;
      case SET_APPLIED_METADATA_FILTER_VALUES:
        localDraft.appliedMetaFilterValues = action.payload;
        break;
      default:
        break;
    }

    return localDraft;
  });
}

export default metadataFiltersReducer;
