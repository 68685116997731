import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { studioTransform } from './persistTransforms';
import rootReducer, { initialState } from './rootReducer';
import { SET_CURRENT_WORKSPACE, USER_LOGIN, USER_LOGOUT } from './types/types';

const appReducer = (state: RootState, action: AnyAction) => {
  if (action.type === SET_CURRENT_WORKSPACE) {
    storage.removeItem('persist:root');
    return rootReducer(
      {
        userStore: state.userStore,
        organizationStore: state.organizationStore,
        layoutStore: state.layoutStore,
      } as RootState,
      action,
    );
  }
  if (action.type === USER_LOGIN || action.type === USER_LOGOUT) {
    storage.removeItem('persist:root');
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  transforms: [studioTransform],
  stateReconciler: autoMergeLevel2,
  blacklist: ['notificationStore', 'sharedPrototypeStore'],
};
const persistedReducer = persistReducer(persistConfig, appReducer as any);

export const setupStore = (preloadedState: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: preloadedState as any,
    devTools: import.meta.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });
};

export const store = setupStore(initialState);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
