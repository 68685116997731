import React, { ReactNode, useState } from 'react';
import { Resizable } from 're-resizable';
import styles from './searchCardLayout.module.scss';

const MIN_SIDE_MENU_WIDTH = 280;
const MAX_SIDE_MENU_WIDTH = 600;

export interface ISearchCardLayoutProps {
  sideMenu?: ReactNode;
  header?: ReactNode;
  righSideMenu?: ReactNode;
  searchBar?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
}

const SearchCardLayout = ({
  sideMenu,
  header,
  righSideMenu,
  searchBar,
  body,
  footer,
}: ISearchCardLayoutProps) => {
  const [sideMenuWidth, setSideMenuWidth] = useState(MIN_SIDE_MENU_WIDTH);

  const renderSideMenu = () => {
    return (
      <Resizable
        size={{ width: sideMenuWidth, height: '100%' }}
        minWidth={MIN_SIDE_MENU_WIDTH}
        maxWidth={MAX_SIDE_MENU_WIDTH}
        grid={[10, 10]}
        snapGap={10}
        enable={{
          right: true,
        }}
        onResizeStop={(_, __, ___, delta) => {
          setSideMenuWidth(sideMenuWidth + delta.width);
        }}
        className={styles.searchCardLayout_sideMenu}
        handleClasses={{
          right: 'handleRight',
        }}
      >
        {sideMenu}
      </Resizable>
    );
  };

  return (
    <div className={styles.searchCardLayout} data-testid="searchComponent">
      <div className={styles.searchCardLayout_container}>
        {sideMenu && renderSideMenu()}
        <div className={styles.searchCardLayout_content}>
          {header && <div className={styles.searchCardLayout_content_header}>{header}</div>}
          {searchBar && (
            <div className={styles.searchCardLayout_content_searchBar} data-testid="searchBar">
              {searchBar}
            </div>
          )}
          {body && <div className={styles.searchCardLayout_content_body}>{body}</div>}
          {footer && <div className={styles.searchCardLayout_content_footer}>{footer}</div>}
        </div>
        {righSideMenu && (
          <div className={styles.searchCardLayout_rightSideMenu}>{righSideMenu}</div>
        )}
      </div>
    </div>
  );
};

export default SearchCardLayout;
