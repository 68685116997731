import { groupBy, isArray, isBoolean, isNil, isObject } from 'lodash';
import { FeedbackSearchResultType, PipelineType } from '@constants/pipelines';
import {
  Groundedness,
  IFeedbackSearchResult,
  IJobQueryResult,
  ILabelingProjectLabel,
  IMappedReferencesMetaAnnotation,
  IPipelineQueryParams,
  IPipelineQueryPromptTemplatesParams,
  IQueryStreamMessageData,
  IQueryStreamRawMessageData,
  IReferencesMetaAnnotation,
  ISearchHistory,
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
  ISearchResultResponse,
  MetadataFiltersValue,
  QueryStreamMessageType,
} from '@redux/types/types';

/** *********************************************
 *               General methods                *
 ********************************************** */

export const generateEmptyAnswerResult = (): ISearchResultAnswer => {
  return {
    answer: '',
    context: null,
    document_ids: null,
    files: [],
    meta: {},
    offsets_in_context: [],
    offsets_in_document: [],
    prompt: '',
    result_id: '',
    score: null,
    type: PipelineType.GENERATIVE_QUESTION_ANSWERING,
  };
};

export const getSearchResultPipelineType = (searchResult: ISearchResult): PipelineType => {
  const { answers = [] } = searchResult;
  if (!answers.length) return PipelineType.DOCUMENT_RETRIEVAL;
  const [{ type }] = answers;
  if (type === PipelineType.GENERATIVE_QUESTION_ANSWERING)
    return PipelineType.GENERATIVE_QUESTION_ANSWERING;
  return PipelineType.EXTRACTIVE_QUESTION_ANSWERING;
};

export const getResultsByType = (
  searchResult: ISearchResult | null,
): ISearchResultAnswer[] | ISearchResultDocument[] => {
  if (!searchResult) return [] as ISearchResultAnswer[];

  const pipelineType = getSearchResultPipelineType(searchResult);

  const resultsByType = {
    [PipelineType.DOCUMENT_RETRIEVAL]: searchResult.documents,
    [PipelineType.EXTRACTIVE_QUESTION_ANSWERING]: searchResult.answers,
    [PipelineType.GENERATIVE_QUESTION_ANSWERING]: searchResult.answers,
  };

  return resultsByType[pipelineType];
};

export const getDocumentsGroupedByFileId = (documents: ISearchResultDocument[]) =>
  groupBy(documents, 'file.id');

export const getDocumentContentById = (
  documentId: string,
  documents: ISearchResultDocument[],
): string => {
  if (!documents) return '';
  const matchedDocument = documents.find((document) => document.id === documentId);
  return matchedDocument?.content ?? '';
};

export const getSliceDocumentContent = (
  documentContent: string,
  startIdx: number,
  endIdx: number,
): string => {
  if (!documentContent) return '';
  const slicedContent = documentContent.slice(startIdx, endIdx);
  return slicedContent;
};

export const extractSearchResultAnswers = (searchResultResponse: ISearchResultResponse) => {
  return searchResultResponse.results[0]?.answers ?? [];
};

export const extractSearchResultDocuments = (searchResultResponse: ISearchResultResponse) => {
  return searchResultResponse.results[0]?.documents ?? [];
};

export const extractSearchQueryParamsPromptTemplates = (
  params: IPipelineQueryParams,
): IPipelineQueryPromptTemplatesParams => {
  const searchParams = (
    currentParams: IPipelineQueryParams,
  ): IPipelineQueryPromptTemplatesParams | null => {
    if (!isObject(currentParams) || isNil(currentParams)) return null;
    if ('prompt_template' in currentParams)
      return { ...currentParams } as IPipelineQueryPromptTemplatesParams;

    const nestedResults = Object.entries(currentParams).reduce(
      (acc: IPipelineQueryParams, [key, value]): IPipelineQueryPromptTemplatesParams => {
        const result = searchParams(value as any);
        if (isNil(result)) return acc;
        return {
          ...acc,
          [key]: result as any,
        };
      },
      {},
    );

    return Object.keys(nestedResults).length > 0 ? nestedResults : null;
  };

  const promptTemplatesParams = searchParams(params) ?? {};
  return promptTemplatesParams;
};

/** *********************************************
 *                   Parsers                    *
 ********************************************** */

// Search metadata

export const getParsedMetadataFiltersRequest = (
  metadataFilters: Record<string, MetadataFiltersValue>,
) => {
  const parsedMetadataFilters: { field: string; operator: string; value: unknown }[] = [];

  Object.keys(metadataFilters).forEach((filter: string) => {
    if (isBoolean(metadataFilters[filter])) {
      parsedMetadataFilters.push({
        field: filter,
        operator: '==',
        value: metadataFilters[filter],
      });
      return;
    }
    if (isArray(metadataFilters[filter])) {
      parsedMetadataFilters.push({
        field: filter,
        operator: 'in',
        value: metadataFilters[filter],
      });
      return;
    }

    if ((metadataFilters[filter] as any).min === (metadataFilters[filter] as any).max) {
      parsedMetadataFilters.push({
        field: filter,
        operator: '==',
        value: (metadataFilters[filter] as any).min,
      });
      return;
    }
    if ((metadataFilters[filter] as any).min) {
      parsedMetadataFilters.push({
        field: filter,
        operator: '>=',
        value: (metadataFilters[filter] as any).min,
      });
    }
    if ((metadataFilters[filter] as any).max) {
      parsedMetadataFilters.push({
        field: filter,
        operator: '<=',
        value: (metadataFilters[filter] as any).max,
      });
    }
  });

  return {
    operator: 'AND',
    conditions: parsedMetadataFilters,
  };
};

// Search history

export const parseSearchHistoryToDocSearchResult = (
  searchHistory: ISearchHistory,
): ISearchResult => {
  const {
    request: { query },
    search_history_id: queryId,
    response,
  } = searchHistory;

  const parsedDocuments = response.map((doc) => {
    const { search_result_history_id: resultId, documents } = doc;
    return {
      ...documents[0],
      result_id: resultId,
    };
  });
  const parsedHistorySearchResults = {
    answers: [],
    documents: parsedDocuments,
    query,
    query_id: queryId,
  };

  return parsedHistorySearchResults;
};

export const parseSearchHistoryToSearchResult = (searchHistory: ISearchHistory): ISearchResult => {
  const { response, request, search_history_id: queryId } = searchHistory;
  const [firstResult] = response;
  const { params, query } = request;
  const answer = {
    ...firstResult.result!,
    result_id: firstResult.search_result_history_id,
  };
  return {
    query_id: queryId,
    answers: [answer],
    documents: firstResult.documents,
    promptTemplates: extractSearchQueryParamsPromptTemplates(params),
    query,
  };
};

// Labeling results

export const parseLabelingLabelsToDocSearchResult = (
  labelingLabels: ILabelingProjectLabel[],
): ISearchResult | null => {
  if (!labelingLabels.length) return null;

  const documents = labelingLabels.map((label) => {
    const { document, search_result_history_id: resultId } = label;
    return {
      ...(document as ISearchResultDocument),
      result_id: resultId,
    };
  });

  const { query, query_id: queryId } = labelingLabels[0];

  const parsedSearchResult: ISearchResult = {
    answers: [],
    documents,
    query,
    query_id: queryId,
  };

  return parsedSearchResult;
};

// Feedback query results

const getPipelineTypeFromFeedbackSearchResultType = (type: FeedbackSearchResultType) => {
  if (type === FeedbackSearchResultType.DOCUMENT) return PipelineType.DOCUMENT_RETRIEVAL;
  if (type === FeedbackSearchResultType.GENERATIVE_QUESTION_ANSWERING)
    return PipelineType.GENERATIVE_QUESTION_ANSWERING;
  return PipelineType.EXTRACTIVE_QUESTION_ANSWERING;
};

export const getParsedFeedbackSearchResultToSearchResult = (
  feedbackSearchResult: IFeedbackSearchResult,
): ISearchResult => {
  const {
    answer,
    context,
    documents: resultDocuments,
    files,
    offsets_in_context: offsetsInContext,
    search_history_result_id: resultId,
    type,
    prompt,
    query_id: queryId,
    search: { query },
    meta,
  } = feedbackSearchResult;

  const answers: ISearchResultAnswer[] = answer
    ? [
        {
          answer: answer || '',
          context: context || null,
          document_ids: resultDocuments ? resultDocuments.map((doc) => doc.id) : null,
          files: files || [],
          meta: meta || {},
          offsets_in_context: offsetsInContext || [],
          offsets_in_document: [],
          prompt,
          result_id: resultId,
          score: null,
          type: getPipelineTypeFromFeedbackSearchResultType(type),
        },
      ]
    : [];

  const documents: ISearchResultDocument[] = resultDocuments.map((doc) => ({
    ...doc,
    result_id: resultId, // TODO: Include result id for docs from the API
  }));

  const result: ISearchResult = {
    answers,
    documents,
    query,
    query_id: queryId,
    prompts: null,
    _debug: null,
  };

  return result;
};

// Job Query results

export const getParsedJobQueryResultToSearchDocuments = (
  result: IJobQueryResult,
): ISearchResultDocument[] => {
  const {
    response: { documents, files },
  } = result;

  if (!documents || !files) return [];

  const parsedDocuments = documents.map((doc) => {
    const { file_id: docFileId } = doc.meta;
    const { file_id: id, file_name: name } = files.find((f) => f.file_id === docFileId)!;
    return {
      ...doc,
      file: {
        id,
        name,
      },
    };
  });

  return parsedDocuments;
};

export const getParsedJobQueryResultToSearchAnswer = (
  result: IJobQueryResult,
): ISearchResultAnswer => {
  const {
    response: { answers },
    prompt,
  } = result;
  const firstAnswer = Array.isArray(answers) ? answers[0] : null;
  if (!firstAnswer) return generateEmptyAnswerResult();

  const docs = getParsedJobQueryResultToSearchDocuments(result);

  const answerFiles = docs
    .filter((doc) => firstAnswer.document_ids?.includes(doc.id))
    .map((doc) => doc.file)
    .filter(Boolean);

  return { ...firstAnswer, files: answerFiles, prompt };
};

export const getParsedJobQueryResultToSearchResult = (result: IJobQueryResult): ISearchResult => {
  const { response, prompts } = result;

  return {
    ...response,
    prompts,
    answers: [getParsedJobQueryResultToSearchAnswer(result)],
    documents: getParsedJobQueryResultToSearchDocuments(result),
  };
};

// References

export const getParsedMetaReferences = (
  references: IReferencesMetaAnnotation[],
  uniqueId: string,
  documents: ISearchResultDocument[],
):
  | {
      id: string;
      startIdx: number;
      endIdx: number;
      content: string;
    }[]
  | null => {
  if (!references?.length) return null;
  return references
    .filter((reference) => reference.label === Groundedness.GROUNDED)
    .map((reference, index) => ({
      id: `${uniqueId}_${index}`,
      startIdx: reference.answer_start_idx,
      endIdx: reference.answer_end_idx,
      content: getSliceDocumentContent(
        getDocumentContentById(reference.document_id, documents),
        reference.doc_start_idx,
        reference.doc_end_idx,
      ),
    }));
};

export const groupParsedDuplicateReferences = (
  references: IMappedReferencesMetaAnnotation[],
  label: string,
): (IMappedReferencesMetaAnnotation & { highlightLabel: string })[] => {
  const groupedDuplicates = references.reduce((acc, ref, idx) => {
    const foundIndex = acc.findIndex((r) => r.content === ref.content);

    if (foundIndex !== -1) acc[foundIndex].highlightLabel += `, ${idx + 1}`;
    else acc.push({ ...ref, highlightLabel: `${label} ${idx + 1}` });

    return acc;
  }, [] as (IMappedReferencesMetaAnnotation & { highlightLabel: string })[]);

  return groupedDuplicates;
};

// Query streaming

export const parseQueryStreamChunks = (
  chunks: IQueryStreamRawMessageData[],
): IQueryStreamMessageData => {
  const parsed = chunks.reduce(
    (acc: IQueryStreamMessageData, data: IQueryStreamRawMessageData) => {
      const { type, query_id: queryId, result, delta } = data;
      if (type === QueryStreamMessageType.DELTA) {
        return {
          ...acc,
          query_id: queryId,
          text: acc.text + delta?.text || '',
        };
      }
      if (type === QueryStreamMessageType.RESULT) {
        return {
          ...acc,
          query_id: queryId,
          result,
        };
      }
      if (type === QueryStreamMessageType.ERROR) {
        return {
          ...acc,
          query_id: queryId,
          error: data.error,
        };
      }

      return acc;
    },
    { query_id: '', text: '', result: null },
  );

  return parsed;
};
