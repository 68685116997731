import { cloneDeep, get, isEqual, setWith } from 'lodash';
import { AnyAction } from 'redux';

export const RESET_STATE = '@@reset/RESET_STATE';

export const createResetAction = (paths: string[]) => ({
  type: RESET_STATE,
  payload: paths,
});

export const createReducerWithReset = <T extends object>(
  initialState: T,
  reducer: (state: T, action: AnyAction) => T,
) => {
  return (state: T = initialState, action: AnyAction): T => {
    if (action.type === RESET_STATE) {
      const paths: string[] = action.payload;
      let hasChanges = false;

      let newState = cloneDeep(state);

      paths.forEach((path) => {
        const currentValue = get(newState, path);
        const initialValue = get(initialState, path);

        if (!isEqual(currentValue, initialValue)) {
          newState = setWith(newState, path, cloneDeep(initialValue), (objValue, key) => {
            if (objValue && Object.hasOwn(objValue, key))
              return Array.isArray(objValue) ? [...objValue] : { ...objValue };

            return objValue;
          });
          hasChanges = true;
        }
      });

      return hasChanges ? newState : state;
    }
    return reducer(state, action);
  };
};
