import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { updateWorkspaceInPath } from '@utils/workspace';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import { DEFAULT_WORKSPACE } from '@constants/workspace';
import { setWorkspace } from '@redux/actions/organizationActions';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';

const WorkspaceRouteWrapper = () => {
  const dispatch = useDispatch();
  const {
    routeParams: { workspace: workspaceFromParams },
    location: { pathname, search: searchParams },
  } = useCurrentRoute();
  const { currentWorkspace, workspaces } = useSelector(workspaceSelector);

  // Check if the workspace from params exists in available workspaces
  const workspaceExists = workspaces.some((ws) => ws.name === workspaceFromParams);

  // If workspace param doesn't exist or isn't valid, redirect to root
  if (!workspaceFromParams || !workspaceExists)
    return <Navigate to={`/${currentWorkspace ?? DEFAULT_WORKSPACE}`} replace />;

  // If workspace exists but differs from current, update it and redirect
  if (workspaceFromParams !== currentWorkspace) {
    dispatch(setWorkspace(workspaceFromParams));
    return (
      <Navigate
        to={updateWorkspaceInPath({
          path: `${pathname}${searchParams}`,
          currentWorkspace,
          newWorkspace: workspaceFromParams,
        })}
        replace
      />
    );
  }

  return <Outlet />;
};

export default WorkspaceRouteWrapper;
