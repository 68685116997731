import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from '@sentry/react';
import { message } from 'antd';
import { auth } from '@utils/auth';
import { Navigator } from '@utils/navigator';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import { useUser } from '@hooks/useUser';
import { useWhiteLabel } from '@hooks/useWhiteLabel';
import { useWorkspaceNavigate } from '@hooks/useWorkspaceNavigate';
import { UserRoles } from '@constants/enum/common';
import { getWorkspaces, setWorkspace } from '@redux/actions/organizationActions';
import { fetchUserInfo } from '@redux/actions/userActions';
import { organizationSelector, workspaceSelector } from '@redux/selectors/organizationSelectors';
import { IOrganizationData, SharedAssetType } from '@redux/types/types';
import LayoutWrapper from '@components/common/Layout/LayoutWrapper';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import PrivateRoute from '@components/common/PrivateRoute';
import ProtectedRoute from '@components/common/ProtectedRoute';
import WorkspaceRouteWrapper from '@components/common/WorkspaceRouteWrapper';
import NotificationCenter from '@components/notificationCenter/notificationCenter';
import UploadPreview from '@components/uploadPreview/UploadPreview';
import useUserflow from '@modules/Userflow/hooks/useUserflow';
import LoginComponent from '@pages/auth/Login';
import LogoutComponent from '@pages/auth/Logout';
import DashboardPage from '@pages/dashboard/DashboardPage';
import ErrorPage from '@pages/error/ErrorPage';
import NotFoundPrototypePage from '@pages/error/NotFoundPrototypePage';
import NotFoundWorkspaceAccessPage from '@pages/error/NotWorkspaceAccessPage';
import AwsMarketplacePage from '@pages/external/awsMarketplace/AwsMarketplace';
import FilesPage from '@pages/files/FilesPage';
import MeteringPage from '@pages/metering/MeteringPage';
import PipelineDetails from '@pages/pipelineDetails/PipelineDetailsPage';
import PipelinesPage from '@pages/pipelines/PipelinesLandingPage';
import PipelineTemplateDetailsPage from '@pages/pipelineTemplateDetails/PipelineTemplateDetails';
import PipelineTemplatesPage from '@pages/pipelineTemplates/PipelineTemplates';
import PromptExplorerPage from '@pages/promptExplorer/PromptExplorer';
import SearchPage from '@pages/search/SearchPage';
import SecretsManagementPage from '@pages/secretsManagement/SecretsManagementPage';
import ConnectionsPage from '@pages/settings/connections/ConnectionsPage';
import OrganizationPage from '@pages/settings/OrganizationPage';
import PersonalDetailsPage from '@pages/settings/PersonalDetailsPage';
import WorkspacesPage from '@pages/settings/WorkspacesPage';

const ObservabilityDashboard = React.lazy(
  () => import('@pages/observabilityDashboard/ObservabilityDashboard'),
);
const JobsPage = React.lazy(() => import('@pages/jobs/JobsPage'));
const JobDetailsPage = React.lazy(() => import('@pages/jobs/JobDetailsPage'));
const SharedPrototypePage = React.lazy(
  () => import('@pages/external/sharedPrototype/SharedPrototype'),
);
const SharedJobReportPage = React.lazy(() => import('@pages/external/jobReportPage/jobReportPage'));
const PipelineStudio = React.lazy(
  () => import('@modules/PipelineStudio/pages/pipelineStudio/pipelineStudioPage'),
);
const Pricing = React.lazy(() => import('@modules/Upgrade/pages/Pricing'));

message.config({
  maxCount: 1,
  duration: 5,
});

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceNavigate = useWorkspaceNavigate();
  const { path: currentRoutePath } = useCurrentRoute();
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect, isLoading } =
    useAuth0();
  const { identify, isInitialized } = useUserflow();
  const { user: userData } = useUser();
  const { currentWorkspace } = useSelector(workspaceSelector);
  const organization: IOrganizationData = useSelector(organizationSelector);
  const { updateDocumentMetadata } = useWhiteLabel({ orgId: organization.organizationId });

  useEffect(() => {
    auth.setAccessTokenSilently(getAccessTokenSilently);
    auth.setloginWithRedirect(loginWithRedirect);
    auth.setLogout(logout);
  }, [getAccessTokenSilently, loginWithRedirect, logout]);

  useEffect(() => {
    Navigator.setNavigate(navigate);
    Navigator.setWorkspaceNavigate(workspaceNavigate);
  }, [navigate, workspaceNavigate]);

  useEffect(() => {
    if (userData.userID && organization && isInitialized) {
      identify(
        userData.userID,
        {
          email: userData.email,
          name: userData.firstName,
          lastName: userData.lastName,
          orgaID: organization.organizationId,
          orgaName: organization.organizationName,
          role: userData.role,
          organizationType: userData.organizationType,
        },
        {
          signature: userData.userflowSignature,
        },
      );

      if (userData.role !== UserRoles.ADMIN) {
        const hasAccesToCurrentWorkspace = userData.workspaces.some(
          (workspace) => workspace.name === currentWorkspace,
        );

        if (!hasAccesToCurrentWorkspace) {
          const [firstWorkspace] = userData.workspaces ?? [];
          if (firstWorkspace) dispatch(setWorkspace(firstWorkspace.name));
        }
      }
    }
  }, [userData.userID, organization, isInitialized]);

  useEffect(() => {
    if (!organization?.organizationId) return;
    updateDocumentMetadata();
  }, [organization.organizationId]);

  useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated && user) {
      dispatch(fetchUserInfo({ authUser: user }));
      dispatch(getWorkspaces());
    } else navigate('/login');
  }, [isAuthenticated, user, isLoading]);

  const renderWorkspaceBasedRoutes = () => {
    return (
      <Route path="/:workspace" element={<WorkspaceRouteWrapper />}>
        <Route
          path=""
          element={
            <PrivateRoute component={DashboardPage} roles={[UserRoles.ADMIN, UserRoles.EDITOR]} />
          }
        />

        {/* prompt explorer pages */}
        <Route
          path="prompt-explorer/:pipelineName"
          element={
            <PrivateRoute
              component={PromptExplorerPage}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="prompt-explorer"
          element={
            <PrivateRoute
              component={PromptExplorerPage}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />

        {/* data pages */}
        <Route
          path="files"
          element={
            <PrivateRoute component={FilesPage} roles={[UserRoles.ADMIN, UserRoles.EDITOR]} />
          }
        />

        {/* pipelines pages */}
        <Route
          path="pipelines"
          element={
            <PrivateRoute component={PipelinesPage} roles={[UserRoles.ADMIN, UserRoles.EDITOR]} />
          }
        />
        <Route
          path="pipelines/:pipelineName"
          element={
            <PrivateRoute component={PipelineDetails} roles={[UserRoles.ADMIN, UserRoles.EDITOR]} />
          }
        />
        <Route
          path="pipelines/templates"
          element={
            <PrivateRoute
              component={PipelineTemplatesPage}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="pipelines/templates/:templateName"
          element={
            <PrivateRoute
              component={PipelineTemplateDetailsPage}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />

        {/* pipeline builder pages */}
        <Route
          path="pipelines/builder"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <PipelineStudio />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="pipelines/:pipelineName/builder"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <PipelineStudio />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />

        {/* plaground pages */}
        <Route
          path="playground"
          element={
            <PrivateRoute
              component={SearchPage}
              roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="playground/:pipelineName"
          element={
            <PrivateRoute
              component={SearchPage}
              roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER, UserRoles.EDITOR]}
            />
          }
        />

        {/* Observability */}
        <Route
          path="groundedness"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <ObservabilityDashboard />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="groundedness/:pipelineName"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <ObservabilityDashboard />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />

        {/* Jobs page */}
        <Route
          path="jobs"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <JobsPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
        <Route
          path="jobs/:jobId"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <JobDetailsPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN, UserRoles.EDITOR]}
            />
          }
        />
      </Route>
    );
  };

  if (isLoading) return <LoadingIndicator />;

  const isEnabledUploadPreview = currentRoutePath !== '/jobs';

  return (
    <ErrorBoundary fallback={ErrorPage}>
      <NotificationCenter />
      {isEnabledUploadPreview && <UploadPreview />}
      <Routes>
        {/* redirect to workspace home page */}
        <Route path="/" element={<Navigate to={`/${currentWorkspace}`} />} />

        <Route path="/login" element={<LoginComponent />} />
        <Route path="/logout" element={<LogoutComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/not-found-prototype" element={<NotFoundPrototypePage />} />
        <Route path="/not-workspaces-access" element={<NotFoundWorkspaceAccessPage />} />
        <Route path="/aws-marketplace" element={<AwsMarketplacePage />} />
        <Route
          path="/shared_prototypes"
          element={<ProtectedRoute Component={SharedPrototypePage} />}
        />
        <Route
          path="/shared_job_results"
          element={<ProtectedRoute Component={SharedJobReportPage} type={SharedAssetType.JOB} />}
        />
        {/* main app pages */}
        <Route element={<LayoutWrapper />}>
          {/* settings pages */}
          <Route
            path="/settings/personal-details"
            element={
              <PrivateRoute
                component={PersonalDetailsPage}
                roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER, UserRoles.EDITOR]}
              />
            }
          />
          <Route
            path="/settings/organization"
            element={<PrivateRoute component={OrganizationPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/connections"
            element={<PrivateRoute component={ConnectionsPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/workspaces"
            element={<PrivateRoute component={WorkspacesPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/usage"
            element={<PrivateRoute component={MeteringPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/secrets"
            element={<PrivateRoute component={SecretsManagementPage} roles={[UserRoles.ADMIN]} />}
          />
          {/* Pricing */}
          <Route
            path="/upgrade"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <Pricing />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER, UserRoles.EDITOR]}
              />
            }
          />

          {/* workspace based routes */}
          {renderWorkspaceBasedRoutes()}
        </Route>

        {/* redirect for 404 pages */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
