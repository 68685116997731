import { combineReducers } from 'redux';
import studioReducer, {
  initialState as initialStudioState,
} from '@modules/PipelineStudio/redux/studioSlice';
import dashboardReducer, {
  initialState as initialDashboardState,
} from './reducers/dashboardReducer';
import evalsetReducer, { initialState as initialEvalsetState } from './reducers/evalsetReducer';
import experimentReducer, {
  initialState as initialExperimentState,
} from './reducers/experimentReducer';
import fileReducer, { initialState as initialFileState } from './reducers/fileReducer';
import jobsReducer, { initialState as initialJobsState } from './reducers/jobsReducer';
import labelingReducer, { initialState as initialLabelingState } from './reducers/labelingReducer';
import layoutReducer, { initialState as initialLayoutState } from './reducers/layoutReducer';
import metadataFiltersReducer, {
  initialState as initialMetadataFiltersState,
} from './reducers/metadataFiltersReducer';
import notificationReducer, { initialNotificationState } from './reducers/notificationReducer';
import observabilityReducer, {
  initialState as initialObservabilityState,
} from './reducers/observabilityReducer';
import organizationReducer, {
  initialState as initialOrganizationState,
} from './reducers/organizationReducer';
import pipelineFeedbackReducer, {
  initialState as initiaPipelineFeedbackState,
} from './reducers/pipelineFeedbackReducer';
import pipelineReducer, { initialState as initialPipelineState } from './reducers/pipelineReducer';
import pipelineTemplatesReducer, {
  initialState as initialPipelineTemplatesState,
} from './reducers/pipelineTemplatesReducer';
import promptExplorerReducer, {
  initialState as initialPromptExplorerState,
} from './reducers/promptExplorerReducer';
import searchReducer, { initialState as initialSearchState } from './reducers/searchReducer';
import secretsManagementReducer, {
  initialState as initialSecretsManagementState,
} from './reducers/secretsManagementReducer';
import sharedPrototypeReducer, {
  initialState as initialSharedPrototypeState,
} from './reducers/sharedPrototypeReducer';
import uploadReducer, { initialState as initialUploadState } from './reducers/uploadReducer';
import usageReducer, { initialState as initialUsageState } from './reducers/usageReducer';
import userReducer, { initialState as initialUserState } from './reducers/userReducer';
import { createReducerWithReset } from './utils/createReducerWithReset';

export const initialState = {
  userStore: initialUserState,
  organizationStore: initialOrganizationState,
  pipelineStore: initialPipelineState,
  pipelineTemplatesStore: initialPipelineTemplatesState,
  searchStore: initialSearchState,
  dashboardStore: initialDashboardState,
  observabilityStore: initialObservabilityState,
  layoutStore: initialLayoutState,
  fileStore: initialFileState,
  evalsetStore: initialEvalsetState,
  experimentStore: initialExperimentState,
  uploadStore: initialUploadState,
  notificationStore: initialNotificationState,
  promptExplorerStore: initialPromptExplorerState,
  labelingStore: initialLabelingState,
  metadataFiltersStore: initialMetadataFiltersState,
  sharedPrototypeStore: initialSharedPrototypeState,
  usageStore: initialUsageState,
  jobsStore: initialJobsState,
  pipelineFeedbackStore: initiaPipelineFeedbackState,
  secretsManagementStore: initialSecretsManagementState,
  studioStore: initialStudioState,
};

const rootReducer = combineReducers({
  userStore: userReducer,
  organizationStore: organizationReducer,
  pipelineStore: pipelineReducer,
  pipelineTemplatesStore: pipelineTemplatesReducer,
  searchStore: searchReducer,
  dashboardStore: dashboardReducer,
  observabilityStore: observabilityReducer,
  layoutStore: layoutReducer,
  fileStore: fileReducer,
  evalsetStore: evalsetReducer,
  experimentStore: experimentReducer,
  uploadStore: uploadReducer,
  notificationStore: notificationReducer,
  promptExplorerStore: promptExplorerReducer,
  labelingStore: labelingReducer,
  metadataFiltersStore: metadataFiltersReducer,
  sharedPrototypeStore: sharedPrototypeReducer,
  usageStore: usageReducer,
  jobsStore: jobsReducer,
  pipelineFeedbackStore: pipelineFeedbackReducer,
  secretsManagementStore: secretsManagementReducer,
  studioStore: studioReducer,
});

const rootReducerWithReset = createReducerWithReset(initialState, rootReducer);

export default rootReducerWithReset;
