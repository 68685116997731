/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Progress } from 'antd';
import { interpolateString } from '@utils/string';
import { BACK_BUTTON_LABEL, NEXT_BUTTON_LABEL, STEPCARD_STEPS_TITLE } from '@constants/common';
import styles from './stepCard.module.scss';

interface IStepCardProps {
  title: string;
  subtitle: string;
  extra?: React.ReactElement | string | null;
  children: React.ReactNode;
  currentStep: number;
  totalSteps: number;
  onBackButtonPress?: () => void;
  isBackButtonDisabled?: boolean;
  nextButtonLabel?: string;
  onNextButtonPress?: () => void;
  isNextButtonDisabled?: boolean;
  isNextButtonLoading?: boolean;
}

interface IStepCardExposedMethods {
  scrollTop: () => void;
}

const StepCard = forwardRef<IStepCardExposedMethods, IStepCardProps>(
  (
    {
      title,
      subtitle,
      extra,
      children,
      currentStep,
      totalSteps,
      onBackButtonPress,
      isBackButtonDisabled,
      nextButtonLabel,
      onNextButtonPress,
      isNextButtonDisabled,
      isNextButtonLoading,
    }: IStepCardProps,
    ref,
  ) => {
    const bodyDivRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollTop() {
          if (!bodyDivRef.current) return;
          bodyDivRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        },
      }),
      [],
    );

    return (
      <div className={styles.stepCard}>
        <div className={styles.stepCard_header}>
          <Progress percent={(currentStep / totalSteps) * 100} showInfo={false} status="normal" />
          <div className={styles.stepCard_header_step}>
            {interpolateString(STEPCARD_STEPS_TITLE, {
              currentStep,
              totalSteps,
            })}
          </div>
          <h3>{title}</h3>
          <span>{subtitle}</span>
          {extra && <div className={styles.stepCard_header_extra}>{extra}</div>}
        </div>
        <div className={styles.stepCard_body} ref={bodyDivRef} id="stepCardBodyDiv">
          {children}
        </div>
        <div className={styles.stepCard_footer}>
          {onBackButtonPress && (
            <Button type="default" onClick={onBackButtonPress} disabled={isBackButtonDisabled}>
              {BACK_BUTTON_LABEL}
            </Button>
          )}
          <Button
            type="primary"
            onClick={onNextButtonPress}
            disabled={isNextButtonDisabled}
            loading={isNextButtonLoading}
          >
            {nextButtonLabel || NEXT_BUTTON_LABEL}
          </Button>
        </div>
      </div>
    );
  },
);

export default StepCard;
