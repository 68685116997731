import { IWorkspaceItem } from '@redux/reducers/organizationReducer';

export const getWorkspaceIdleTimeout = (workspaces: IWorkspaceItem[], currentWorkspace: string) => {
  const currentWorkspaceData = workspaces.find(
    (workspace: IWorkspaceItem) => workspace.name === currentWorkspace,
  );
  const idleTimeout = currentWorkspaceData?.default_idle_timeout_in_seconds;

  return idleTimeout || null;
};

export const updateWorkspaceInPath = ({
  path,
  currentWorkspace,
  newWorkspace,
}: {
  path: string;
  currentWorkspace: string;
  newWorkspace: string;
}): string => path.replace(encodeURIComponent(currentWorkspace), newWorkspace);

export const getSafeWorkspacePath = ({
  path,
  currentWorkspace,
  newWorkspace,
  routeParams = {},
}: {
  path: string;
  currentWorkspace: string;
  newWorkspace: string;
  routeParams?: Record<string, string>;
}): string => {
  const updatedPath = updateWorkspaceInPath({ path, currentWorkspace, newWorkspace });

  // Extract path without query/hash and the separate query/hash parts
  const [pathWithoutQuery, queryAndHash] = updatedPath.split(/[?#]/);
  const queryHash = updatedPath.includes('?')
    ? `?${queryAndHash}`
    : updatedPath.includes('#')
    ? `#${queryAndHash}`
    : '';

  const segments = pathWithoutQuery.split('/').filter(Boolean);
  const paramValues = Object.values(routeParams).map((v) => decodeURIComponent(v));

  const firstDynamicIndex = segments.findIndex(
    (segment, index) => index > 0 && paramValues.includes(decodeURIComponent(segment)),
  );

  if (firstDynamicIndex === -1) return updatedPath;

  const safeSegments = segments.slice(0, firstDynamicIndex);
  const basePath = safeSegments.length > 0 ? `/${safeSegments.join('/')}` : `/${newWorkspace}`;

  return `${basePath}${queryHash}`;
};
