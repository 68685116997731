import React from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import { workspaceSelector } from '@redux/selectors/organizationSelectors';

type WorkspaceLinkProps = Omit<LinkProps, 'to'> & {
  to: string;
  skipWorkspace?: boolean;
};

/**
 * A wrapper around React Router's Link component that automatically prepends
 * the current workspace to the route path.
 */
const WorkspaceLink: React.FC<WorkspaceLinkProps> = ({ to, skipWorkspace = false, ...props }) => {
  const { currentWorkspace } = useSelector(workspaceSelector);

  if (skipWorkspace || typeof to === 'number' || to.startsWith('http')) {
    return <Link to={to} {...props} />;
  }

  const cleanPath = to.startsWith('/') ? to.substring(1) : to;
  const workspacePath = `/${currentWorkspace}/${cleanPath}`;

  return <Link to={workspacePath} {...props} />;
};

export default WorkspaceLink;
