import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import DeepsetLogoSVG from '@assets/deepset-logo-darkblue.svg?react';
import { AWS_MARKETPLACE_CONTACT_BUTTON } from '@constants/aws-marketplace';
import styles from './awsMarketplaceHeader.module.scss';

const AwsMarketplaceHeader = () => {
  const CONTACT_URL = import.meta.env.VITE_CONTACT_DEEPSET_URL;

  const { Header } = Layout;

  return (
    <Header className={styles.container}>
      <div className={styles.logoWrapper}>
        <DeepsetLogoSVG className={styles.logo} />
      </div>
      <a href={CONTACT_URL} target="_blank" rel="noreferrer" className={styles.docsLink}>
        <Button type="primary" icon={<ArrowRightOutlined />}>
          {AWS_MARKETPLACE_CONTACT_BUTTON}
        </Button>
      </a>
    </Header>
  );
};

export default AwsMarketplaceHeader;
