import React, { useMemo } from 'react';
import { isArray } from 'lodash';

export enum ExtractorType {
  TABLE = 'table',
}

export interface TableData {
  headers: string[];
  rows: Record<string, string>[];
}

export type ExtractedData = TableData | null;

const extractTextContent = (node: React.ReactNode): string => {
  if (typeof node === 'string') return node;
  if (typeof node === 'number') return node.toString();
  if (!node) return '';

  if (isArray(node)) return node.map(extractTextContent).join('');
  if (React.isValidElement(node)) return extractTextContent(node.props.children);

  return '';
};

const useExtractDataFromNodes = (children: React.ReactNode, type: ExtractorType): ExtractedData => {
  const extractTableData = (c: React.ReactNode): TableData => {
    const headers: string[] = [];
    const rows: Record<string, string>[] = [];

    React.Children.forEach(c, (child: any) => {
      if (child.type === 'thead') {
        React.Children.forEach(child.props.children, (headerRow: any) => {
          React.Children.forEach(headerRow.props.children, (header: any) => {
            headers.push(extractTextContent(header.props.children));
          });
        });
      }
      if (child.type === 'tbody') {
        React.Children.forEach(child.props.children, (row: any) => {
          const rowData: Record<string, string> = {};
          React.Children.forEach(row.props.children, (cell: any, index: number) => {
            rowData[headers[index]] = extractTextContent(cell.props.children);
          });
          rows.push(rowData);
        });
      }
    });

    return { headers, rows };
  };

  const extractors = {
    table: extractTableData,
  };

  return useMemo(() => {
    const extractor = extractors[type];
    if (!extractor) return null;
    return extractor(children);
  }, [children, type]);
};

export default useExtractDataFromNodes;
