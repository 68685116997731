import { ColumnsType } from 'antd/es/table';
import { isNil } from 'lodash';

const escapeCSVValue = (value: unknown): string => {
  if (isNil(value)) return '';

  const stringValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

  if (stringValue.includes('"') || stringValue.includes(',') || stringValue.includes('\n'))
    return `"${stringValue.replace(/"/g, '""')}"`;

  return stringValue;
};

export const parseTableDataToCsvString = <T extends Record<string, unknown>>(
  columns: ColumnsType<T>,
  dataSource: T[] | null,
): string => {
  if (!dataSource?.length) return '';

  const headers = columns.map(({ dataIndex }: any) => escapeCSVValue(dataIndex));
  const csvRows = dataSource.map((row) =>
    headers.map((fieldName) => escapeCSVValue(row[fieldName])).join(','),
  );

  const csvString = [headers.join(','), ...csvRows].join('\r\n');

  return csvString;
};
