import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const pipelineTemplatesStoreSelector = (state: RootState) => state.pipelineTemplatesStore;

export const pipelineTemplatesSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplates,
);

export const pipelineTemplatesByCategorySelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplatesByCategory,
);

export const pipelineTemplatesStatusSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplatesStatus,
);

export const selectedCategorySelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.selectedCategory,
);

export const selectedTemplateSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.selectedTemplate,
);

export const moreTemplatesLikeThisSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (labelingStore) => labelingStore.moreTemplatesLikeThis,
);

export const moreTemplatesLikeThisStatusSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (labelingStore) => labelingStore.moreTemplatesLikeThisStatus,
);

export const pipelineTemplatesSearchValueSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplatesSearchValue,
);

export const pipelineTemplatesFiltersValuesSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplatesFiltersValues,
);

export const selectedPipelineTemplateFiltersSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.selectedPipelineTemplateFilters,
);

export const pipelineTemplatesSortValueSelector = createSelector(
  pipelineTemplatesStoreSelector,
  (pipelineTemplatesStore) => pipelineTemplatesStore.pipelineTemplatesSortValue,
);
