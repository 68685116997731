import React from 'react';
import { IPipeline } from '@redux/types/types';
import styles from './playgroundHeader.module.scss';
import QueryPipelineParams from '../queryPipelineParams/queryPipelineParams';
import SearchPipelineSelect from '../searchPipelineSelect/SearchPipelineSelect';

interface IPlaygroundHeaderProps {
  selectedPipeline?: IPipeline | null;
  isLoading?: boolean;
}

const PlaygroundHeader = ({ selectedPipeline, isLoading }: IPlaygroundHeaderProps) => {
  return (
    <div className={styles.container}>
      <SearchPipelineSelect selectedPipeline={selectedPipeline} loading={isLoading} />
      <QueryPipelineParams />
    </div>
  );
};

export default PlaygroundHeader;
