import { useEffect, useLayoutEffect, useState } from 'react';

interface InitializeConfig {
  actions: Array<() => void>;
  onComplete?: () => void;
  useLayoutEffect?: boolean;
}

/**
 * A hook for handling component initialization and initializing state
 * @param config Configuration object for initialization
 * @param config.actions Array of actions to dispatch on initialization
 * @param config.onComplete Optional callback to execute after initialization
 * @param config.useLayoutEffect Whether to use useLayoutEffect instead of useEffect (default: false)
 * @returns Object containing isInitialized state
 */
const useInitialize = ({
  actions,
  onComplete,
  useLayoutEffect: useLayout = false,
}: InitializeConfig) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const Effect = useLayout ? useLayoutEffect : useEffect;

  Effect(() => {
    actions.forEach((action) => {
      if (typeof action === 'function') action();
    });

    setIsInitialized(true);

    if (onComplete) onComplete();
  }, []);

  return { isInitialized };
};

export default useInitialize;
