/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Tabs,
  Tag,
  Tooltip,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import {
  CANCEL_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  NO_BUTTON_LABEL,
  YES_BUTTON_LABEL,
} from '@constants/common';
import { FiltersProp, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import {
  PROMPT_TEMPLATES_FILTERS_SEARCH_PLACEHOLDER,
  PROMPT_TEMPLATES_MODAL_CANCEL_CHANGES_POPCONFIRM_TITLE,
  PROMPT_TEMPLATES_MODAL_CREATE_CUSTOM_PROMPT_BUTTON_LABEL,
  PROMPT_TEMPLATES_MODAL_CUSTOM_TEMPLATES_TAB,
  PROMPT_TEMPLATES_MODAL_DEEPSET_TEMPLATES_TAB,
  PROMPT_TEMPLATES_MODAL_DELETE_POPCONFIRM_TITLE,
  PROMPT_TEMPLATES_MODAL_EDIT_PROMPT_BUTTON_LABEL,
  PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DESCRIPTION_PLACEHOLDER,
  PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DISCLAIMER_MESSAGE,
  PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_LABEL,
  PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_PLACEHOLDER,
  PROMPT_TEMPLATES_MODAL_NEW_PROMPT_PLACEHOLDER,
  PROMPT_TEMPLATES_MODAL_SAVE_CHANGES_BUTTON_LABEL,
  PROMPT_TEMPLATES_MODAL_SAVE_PROMPT_BUTTON_LABEL,
  PROMPT_TEMPLATES_MODAL_TAGS_LABEL,
  PROMPT_TEMPLATES_MODAL_TITLE,
  PROMPT_TEMPLATES_MODAL_USE_PROMPT_BUTTON_LABEL,
  PROMPT_TEMPLATES_SORTING_OPTIONS,
} from '@constants/prompt-explorer';
import {
  CustomPromptTemplate,
  PromptTemplate,
  PromptTemplatesModalMoreOptionsKey,
  PromptTemplatesModalTabOption,
} from '@redux/types/types';
import AppliedFiltersList from '@components/appliedFilterList/AppliedFiltersList';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import DataHeaderActions from '@components/dataHeaderActions/DataHeaderActions';
import TagInput from '@components/tagInput/TagInput';
import styles from './promptTemplatesModal.module.scss';

const { Item } = Form;
const { TextArea } = Input;

const PromptEditor = React.lazy(
  () => import(/* webpackChunkName: "PromptEditor" */ '@components/promptEditor/PromptEditor'),
);

const NUM_SKELETON_TEMPLATE_ITEMS = 5;
const CREATE_CUSTOM_TEMPLATE_ITEM_ID = 'custom-template-item';

const TABS_ITEMS = [
  {
    label: PROMPT_TEMPLATES_MODAL_CUSTOM_TEMPLATES_TAB,
    key: PromptTemplatesModalTabOption.CUSTOM,
  },
  {
    label: PROMPT_TEMPLATES_MODAL_DEEPSET_TEMPLATES_TAB,
    key: PromptTemplatesModalTabOption.DEEPSET,
  },
];

interface IPromptModalProps {
  promptTemplates: PromptTemplate[];
  activeTab: PromptTemplatesModalTabOption;
  open: boolean;
  templateListHasMore: boolean;
  selectedSortingValue: string;
  filters: FiltersProp;
  selectedFilterValues: SelectedFilters;
  onUsePromptClick: (promptTemplate: PromptTemplate) => void;
  onCreateNewCustomPrompt: (promptTemplate: CustomPromptTemplate) => void;
  onUpdateCustomPromptValues: (
    promptTemplate: CustomPromptTemplate & { prompt_template_id: string },
  ) => void;
  onDeleteCustomPrompt: (promptTemplateId: string) => void;
  onTabChange: (activeKey: PromptTemplatesModalTabOption) => void;
  onCancel: () => void;
  fetchMoreTemplates: () => void;
  onSortSelectChange: (value: string) => void;
  onFilterSelectChange: (filterKey: string, items: SelectedFilterItem[]) => void;
  onClearAllFilters: () => void;
  onSearch: (value: string) => void;
  loadingTemplates: boolean;
}

interface IPromptModalExposedMethods {
  createCustomTemplateFromPrompt: (prompt: string) => void;
}

const PromptTemplatesModal = forwardRef<IPromptModalExposedMethods, IPromptModalProps>(
  (
    {
      promptTemplates,
      activeTab,
      open,
      templateListHasMore,
      loadingTemplates,
      selectedSortingValue,
      filters,
      selectedFilterValues,
      onCancel,
      onUsePromptClick,
      onTabChange,
      onCreateNewCustomPrompt,
      onUpdateCustomPromptValues,
      onDeleteCustomPrompt,
      fetchMoreTemplates,
      onSortSelectChange,
      onFilterSelectChange,
      onClearAllFilters,
      onSearch,
    }: IPromptModalProps,
    ref,
  ) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>(
      CREATE_CUSTOM_TEMPLATE_ITEM_ID,
    );
    const [customPromptTemplateFormValues, setCustomPromptTemplateFormValues] =
      useState<CustomPromptTemplate>({ name: '', description: '', tags: [], text: '' });
    const [editing, setEditing] = useState(false);

    const isCustomTemplate = activeTab === PromptTemplatesModalTabOption.CUSTOM;

    useImperativeHandle(
      ref,
      () => ({
        createCustomTemplateFromPrompt(prompt: string) {
          setSelectedTemplateId(CREATE_CUSTOM_TEMPLATE_ITEM_ID);
          setCustomPromptTemplateFormValues({ name: '', description: '', tags: [], text: prompt });
        },
      }),
      [],
    );

    useEffect(() => {
      if (promptTemplates.length) setSelectedTemplateId(promptTemplates[0]?.prompt_template_id);
    }, [promptTemplates]);

    const getPromptTemplateById = (id: string | number) => {
      return promptTemplates.find((template) => template.prompt_template_id === id);
    };

    const onSelectTemplate = (id: string) => {
      setEditing(false);
      setSelectedTemplateId(id);
    };

    const onSelectedTabChange = (key: string) => {
      setEditing(false);
      onTabChange(key as PromptTemplatesModalTabOption);
    };

    const onUsePromptClicked = () => {
      const promptTemplate = getPromptTemplateById(selectedTemplateId);
      if (!promptTemplate) return;
      onUsePromptClick(promptTemplate);
      onCancel();
    };

    const resetCustomPromptForm = () => {
      setEditing(false);
      setCustomPromptTemplateFormValues({ name: '', description: '', tags: [], text: '' });
    };

    const onSubmitCustomPromptTemplate = () => {
      resetCustomPromptForm();
      if (editing)
        onUpdateCustomPromptValues({
          ...customPromptTemplateFormValues,
          prompt_template_id: selectedTemplateId,
        });
      else onCreateNewCustomPrompt(customPromptTemplateFormValues);
    };

    const onEditCustomPromptButtonClick = (promptTemplate: PromptTemplate) => {
      const { name, description, text, tags } = promptTemplate;
      setEditing(true);
      setCustomPromptTemplateFormValues({ name, description, tags, text });
    };

    const onCustomPromptTemplateFormValuesChange = (field: string, value: string | string[]) => {
      setCustomPromptTemplateFormValues((prevValue) => ({
        ...prevValue,
        [field]: value,
      }));
    };

    const isValidCustomTemplateForm = (): boolean => {
      const { name } = customPromptTemplateFormValues;
      return !!name;
    };

    const onCustomPromptTemplateTagRemove = (removedTag: string) => {
      const { tags } = customPromptTemplateFormValues;
      const updatedTags = tags.filter((tag) => tag !== removedTag);
      setCustomPromptTemplateFormValues({
        ...customPromptTemplateFormValues,
        tags: updatedTags,
      });
    };

    const getAvatarTextContent = (firstName: string, lastName: string) =>
      `${firstName[0]}${lastName[0] || ''}`;

    const getTemplateName = (name: string) => {
      const splittedBySlash = name.split('/');
      if (splittedBySlash.length < 2) return name;
      const [user, ...rest] = splittedBySlash;
      return (
        <>
          <span className={styles.withSupportTextColor}>{user}</span>/{rest.join()}
        </>
      );
    };

    // TODO: Remove once we add dc prompt templates for v2
    const getTabItems = () => {
      return TABS_ITEMS.filter((tab) => tab.key !== PromptTemplatesModalTabOption.DEEPSET);
    };

    // Renders

    const renderActionsHeader = () => {
      return (
        <div className={styles.actionsBar}>
          <DataHeaderActions
            searchAvailable
            searchPlaceholder={PROMPT_TEMPLATES_FILTERS_SEARCH_PLACEHOLDER}
            filters={filters}
            filterValues={selectedFilterValues}
            onSearch={onSearch}
            onFilterSelectChange={onFilterSelectChange}
            onSortSelectChange={onSortSelectChange}
            sorting={{
              selectedValue: selectedSortingValue,
              options: PROMPT_TEMPLATES_SORTING_OPTIONS,
            }}
          />
        </div>
      );
    };

    const renderSkeletonListItem = () => (
      <div className={styles.templateSkeletonItem} key={uuidv4()}>
        <Skeleton avatar title paragraph={{ rows: 3 }} active />
      </div>
    );

    const renderListItem = ({
      prompt_template_id: id,
      name,
      description,
      tags,
      user: { given_name: firstName, family_name: lastName },
    }: PromptTemplate) => {
      const isActive = id === selectedTemplateId;
      return (
        <div
          key={id}
          className={`${styles.templateListItem} ${isActive ? styles.templateListItem_active : ''}`}
          tabIndex={0}
          role="button"
          onClick={() => onSelectTemplate(id)}
          aria-hidden="true"
        >
          <div className={styles.templateListItem_avatar}>
            <Tooltip title={`${firstName} ${lastName}`}>
              <Avatar>{getAvatarTextContent(firstName, lastName)}</Avatar>
            </Tooltip>
          </div>
          <div>
            <h6 className={styles.templateListItem_title}>{getTemplateName(name)}</h6>
            <div className={styles.templateListItem_description}>{description}</div>
            <div className={styles.templateListItem_tags}>
              {tags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </div>
          </div>
        </div>
      );
    };

    const renderCreateCustomTemplateListItem = () => {
      const isActive =
        !promptTemplates.length || selectedTemplateId === CREATE_CUSTOM_TEMPLATE_ITEM_ID;
      return (
        <div
          key={CREATE_CUSTOM_TEMPLATE_ITEM_ID}
          className={`${styles.templateListItem} ${styles.withGreyBackground} ${
            isActive ? styles.templateListItem_active : ''
          }`}
        >
          <Button
            block
            icon={<PlusOutlined />}
            type={isActive ? 'dashed' : 'default'}
            className={isActive ? styles.templateListItem_button_active : ''}
            onClick={() => onSelectTemplate(CREATE_CUSTOM_TEMPLATE_ITEM_ID)}
          >
            {PROMPT_TEMPLATES_MODAL_CREATE_CUSTOM_PROMPT_BUTTON_LABEL}
          </Button>
        </div>
      );
    };

    const renderSelectedTemplate = () => {
      const promptTemplate = getPromptTemplateById(selectedTemplateId);
      if (!promptTemplate) return null;

      const templateMoreOptionsMenuItems = [
        {
          key: PromptTemplatesModalMoreOptionsKey.DELETE,
          label: (
            <Popconfirm
              title={PROMPT_TEMPLATES_MODAL_DELETE_POPCONFIRM_TITLE}
              placement="right"
              okText={YES_BUTTON_LABEL}
              cancelText={NO_BUTTON_LABEL}
              onConfirm={() => {
                onDeleteCustomPrompt(selectedTemplateId);
              }}
            >
              {DELETE_BUTTON_LABEL}
            </Popconfirm>
          ),
          danger: true,
          icon: <DeleteOutlined />,
        },
      ];

      const {
        name,
        description,
        text: promptText,
        tags,
        user: { given_name: firstName, family_name: lastName },
      } = promptTemplate;

      return (
        <div className={styles.templateItem}>
          <div className={styles.templateItem_headerWrapper}>
            <h6 className={styles.templateItem_title}>{getTemplateName(name)}</h6>
            {isCustomTemplate && (
              <Dropdown
                menu={{
                  items: templateMoreOptionsMenuItems,
                }}
                placement="bottomRight"
                trigger={['click']}
              >
                <Button type="text" icon={<MoreOutlined />} onClick={(e) => e.preventDefault()} />
              </Dropdown>
            )}
          </div>
          <div className={styles.templateItem_user}>
            <Avatar size={16}>{getAvatarTextContent(firstName, lastName)}</Avatar>
            <div className={styles.templateItem_user_name}>
              {firstName} {lastName}
            </div>
          </div>
          <div className={styles.templateItem_description}>{description}</div>
          <div className={styles.templateItem_prompt}>
            <React.Suspense fallback={<LoadingIndicator />}>
              <PromptEditor
                prompt={promptText}
                readOnly
                showLineNumbers={false}
                showHeader={false}
                showCopyPromptButton
              />
            </React.Suspense>
          </div>
          {tags.length > 0 && (
            <div className={styles.templateItem_tags}>
              {PROMPT_TEMPLATES_MODAL_TAGS_LABEL}:
              {tags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </div>
          )}
          <div className={styles.templateItem_buttonsWrapper}>
            {isCustomTemplate && (
              <Button type="default" onClick={() => onEditCustomPromptButtonClick(promptTemplate)}>
                {PROMPT_TEMPLATES_MODAL_EDIT_PROMPT_BUTTON_LABEL}
              </Button>
            )}
            <Button type="primary" onClick={onUsePromptClicked}>
              {PROMPT_TEMPLATES_MODAL_USE_PROMPT_BUTTON_LABEL}
            </Button>
          </div>
        </div>
      );
    };

    const renderCustomTemplateForm = () => {
      const { name, description, text, tags } = customPromptTemplateFormValues;
      return (
        <div className={styles.formTemplateItem}>
          <Form layout="vertical">
            <Item label={PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_LABEL} required>
              <Input
                value={name}
                onChange={(event) =>
                  onCustomPromptTemplateFormValuesChange('name', event.target.value)
                }
                placeholder={PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_PLACEHOLDER}
              />
            </Item>
            <Item>
              <TextArea
                rows={2}
                value={description}
                onChange={(event) =>
                  onCustomPromptTemplateFormValuesChange('description', event.target.value)
                }
                placeholder={PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DESCRIPTION_PLACEHOLDER}
                allowClear
              />
            </Item>
            <Item className={styles.formTemplateItem_tags}>
              <div className={styles.formTemplateItem_tags_label}>
                {PROMPT_TEMPLATES_MODAL_TAGS_LABEL}:
              </div>
              <TagInput
                onConfirm={(tagValue: string) => {
                  const updatedTags = [tagValue, ...tags];
                  onCustomPromptTemplateFormValuesChange('tags', updatedTags);
                }}
              />
              {tags.map((tag) => (
                <Tag
                  className={styles.formTemplateItem_tags_tag}
                  key={tag}
                  closable
                  onClose={(event) => {
                    event.preventDefault();
                    onCustomPromptTemplateTagRemove(tag);
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </Item>
            <Item>
              <React.Suspense fallback={<LoadingIndicator />}>
                <PromptEditor
                  placeholder={PROMPT_TEMPLATES_MODAL_NEW_PROMPT_PLACEHOLDER}
                  prompt={text}
                  onChange={(newPrompt) =>
                    onCustomPromptTemplateFormValuesChange('text', newPrompt)
                  }
                  showHeader={false}
                />
              </React.Suspense>
              <span className={styles.formTemplateItem_disclaimer}>
                {PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DISCLAIMER_MESSAGE}
              </span>
            </Item>
            <Item>
              <Popconfirm
                title={
                  <div className={styles.formTemplateItem_popconfirm_title}>
                    {PROMPT_TEMPLATES_MODAL_CANCEL_CHANGES_POPCONFIRM_TITLE}
                  </div>
                }
                onConfirm={resetCustomPromptForm}
                okText={YES_BUTTON_LABEL}
                cancelText={NO_BUTTON_LABEL}
              >
                <Button>{CANCEL_BUTTON_LABEL}</Button>
              </Popconfirm>
              <Button
                type="primary"
                onClick={onSubmitCustomPromptTemplate}
                disabled={!isValidCustomTemplateForm()}
              >
                {editing
                  ? PROMPT_TEMPLATES_MODAL_SAVE_CHANGES_BUTTON_LABEL
                  : PROMPT_TEMPLATES_MODAL_SAVE_PROMPT_BUTTON_LABEL}
              </Button>
            </Item>
          </Form>
        </div>
      );
    };

    const isCreateCustomTemplateItemSelected =
      selectedTemplateId === CREATE_CUSTOM_TEMPLATE_ITEM_ID || editing;

    return (
      <Modal
        title={PROMPT_TEMPLATES_MODAL_TITLE}
        className={styles.promptTemplatesModal}
        open={open}
        centered
        onCancel={onCancel}
        footer={null}
      >
        <div className={styles.promptTemplatesModal_header}>
          <Tabs activeKey={activeTab} items={getTabItems()} onChange={onSelectedTabChange} />
        </div>
        <div className={styles.promptTemplatesModal_actionsBar}>{renderActionsHeader()}</div>
        <div className={styles.promptTemplatesModal_appliedFiltersBar}>
          <AppliedFiltersList
            filterValues={selectedFilterValues}
            onClearAllFilters={onClearAllFilters}
            onFilterSelectChange={onFilterSelectChange}
          />
        </div>
        <div className={styles.promptTemplatesModal_body}>
          <div id="templateListDiv" className={styles.promptTemplatesModal_body_templateList}>
            <div className={styles.zeroHeightContainer}>
              {!loadingTemplates ? (
                <>
                  {isCustomTemplate &&
                    PromptTemplatesModalTabOption.CUSTOM &&
                    renderCreateCustomTemplateListItem()}
                  <InfiniteScroll
                    dataLength={promptTemplates.length}
                    next={fetchMoreTemplates}
                    loader={renderSkeletonListItem()}
                    hasMore={templateListHasMore}
                    scrollableTarget="templateListDiv"
                  >
                    {promptTemplates.map(renderListItem)}
                  </InfiniteScroll>
                </>
              ) : (
                Array.from({ length: NUM_SKELETON_TEMPLATE_ITEMS }).map(renderSkeletonListItem)
              )}
            </div>
          </div>
          <Divider type="vertical" />
          <div className={styles.promptTemplatesModal_body_selectedTemplate}>
            {isCreateCustomTemplateItemSelected
              ? renderCustomTemplateForm()
              : renderSelectedTemplate()}
          </div>
        </div>
      </Modal>
    );
  },
);

export default PromptTemplatesModal;
