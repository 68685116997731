import { useDispatch, useSelector } from 'react-redux';
import { StatusCodes } from '@constants/enum/common';
import {
  activatePipeline as activatePipelineAction,
  createPipeline as createPipelineAction,
  deletePipeline as deletePipelineAction,
  deployPipeline as deployPipelineAction,
  duplicatePipeline as duplicatePipelineAction,
  exportPipelineDocumentsCSV,
  fetchPipeline as fetchPipelineAction,
  fetchPipelineIndexing as fetchPipelineIndexingAction,
  fetchPipelineYaml as fetchPipelineYamlAction,
  getRuntimeIssueDetection as getRuntimeIssueDetectionAction,
  resetFetchPipelineYamlStatus as resetFetchPipelineYamlStatusAction,
  resetMessage as resetMessageAction,
  resetNewPipelineName as resetNewPipelineNameAction,
  resetPipeline as resetPipelineAction,
  resetPipelineErrors as resetPipelineErrorsAction,
  resetPipelineYaml as resetPipelineYamlAction,
  resetRenamePipelineNameStatus as resetRenamePipelineNameStatusAction,
  resetValidateNewPipelineName as resetValidateNewPipelineNameAction,
  setActiveTabPipelineDetailsPage as setActiveTabPipelineDetailsPageAction,
  setPipelineEditedStateTo as setPipelineEditedStateToAction,
  setPipelineName as setPipelineNameAction,
  setPipelineYaml as setPipelineYamlAction,
  startPollingPipelineStatus as startPollingPipelineStatusAction,
  stopPollingPipelineStatus as stopPollingPipelineStatusAction,
  undeployPipeline as undeployPipelineAction,
  updatePipeline as updatePipelineAction,
  updatePipelineYaml as updatePipelineYamlAction,
  validatePipelineYaml as validatePipelineYamlAction,
} from '@redux/actions/pipelineActions';
import {
  duplicatePipelineDataSelector,
  fetchPipelineYamlStatusSelector,
  newPipelineNameSelector,
  pipelineActionStatusSelector,
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineErrorsSelector,
  pipelineIndexingDetailsSelector,
  pipelineLogsUnhealthySelector,
  pipelineMessageSelector,
  pipelineSelector,
  pipelineStatusSelector,
  renamePipelineNameStatusSelector,
} from '@redux/selectors/pipelineSelectors';
import { IMessage, IPipeline, IPipelineIndexingData } from '@redux/types/types';

const usePipelineActions = () => {
  const dispatch = useDispatch();
  const pipeline: IPipeline = useSelector(pipelineSelector);
  const renamePipelineNameStatus: StatusCodes = useSelector(renamePipelineNameStatusSelector);
  const fetchPipelineYamlStatus: StatusCodes = useSelector(fetchPipelineYamlStatusSelector);
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const status: StatusCodes = useSelector(pipelineStatusSelector);
  const actionStatus: StatusCodes = useSelector(pipelineActionStatusSelector);
  const message: IMessage = useSelector(pipelineMessageSelector);
  const pipelineLogsUnhealthy: Record<string, boolean> = useSelector(pipelineLogsUnhealthySelector);
  const pipelineCreateUpdatePipelineStatus: StatusCodes = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const newPipelineName: string = useSelector(newPipelineNameSelector);
  const duplicatePipelineData = useSelector(duplicatePipelineDataSelector);
  const pipelineErrors = useSelector(pipelineErrorsSelector);

  const createPipeline = async (payload: { code?: string; pipelineName: string }) => {
    await dispatch(createPipelineAction(payload));
  };

  const deployPipeline = async (pipelineName: string) => {
    await dispatch(deployPipelineAction(pipelineName));
  };

  const undeployPipeline = async (pipelineName: string) => {
    await dispatch(undeployPipelineAction(pipelineName));
  };

  const activatePipeline = async (pipelineName: string) => {
    await dispatch(activatePipelineAction({ pipelineName }));
  };

  const deletePipeline = async (pipelineName: string) => {
    await dispatch(deletePipelineAction(pipelineName));
  };

  const updatePipeline = async (payload: { pipelineName: string; newPipelineName: string }) => {
    await dispatch(updatePipelineAction(payload));
  };

  const fetchPipelineIndexing = (pipelineName: string) => {
    dispatch(fetchPipelineIndexingAction(pipelineName));
  };

  const setActiveTabPipelineDetailsPage = (tabKey: string) => {
    dispatch(setActiveTabPipelineDetailsPageAction(tabKey));
  };

  const duplicatePipeline = (pipelineName: string) => {
    dispatch(duplicatePipelineAction(pipelineName));
  };

  const resetRenamePipelineNameStatus = () => {
    dispatch(resetRenamePipelineNameStatusAction);
  };

  const resetMessage = () => {
    dispatch(resetMessageAction);
  };

  const resetNewPipelineName = () => {
    dispatch(resetNewPipelineNameAction);
  };

  const resetPipeline = () => {
    dispatch(resetPipelineAction);
  };

  const resetPipelineErrors = () => {
    dispatch(resetPipelineErrorsAction);
  };

  const resetFetchPipelineYamlStatus = () => {
    dispatch(resetFetchPipelineYamlStatusAction);
  };

  const resetPipelineYaml = () => {
    dispatch(resetPipelineYamlAction);
  };

  const fetchPipeline = (pipelineName: string, isExternal?: boolean) => {
    dispatch(fetchPipelineAction({ pipelineName, isExternal }));
  };

  const fetchPipelineYaml = (pipelineName: string) => {
    dispatch(fetchPipelineYamlAction(pipelineName));
  };

  const resetValidateNewPipelineName = () => {
    dispatch(resetValidateNewPipelineNameAction);
  };

  const startPollingPipelineStatus = (pipelineName: string, isExternal?: boolean) => {
    dispatch(startPollingPipelineStatusAction({ pipelineName, isExternal }));
  };

  const stopPollingPipelineStatus = () => {
    dispatch(stopPollingPipelineStatusAction());
  };

  const setPipelineName = (pipelineName: string) => {
    dispatch(setPipelineNameAction(pipelineName));
  };

  const getRuntimeIssueDetection = (pipelineName: string) => {
    dispatch(getRuntimeIssueDetectionAction(pipelineName));
  };

  const validatePipelineYaml = (payload: {
    yamlCode?: string;
    indexingYaml?: string;
    queryYaml?: string;
  }) => {
    dispatch(
      validatePipelineYamlAction({
        config: payload.yamlCode,
        indexing_yaml: payload.indexingYaml,
        query_yaml: payload.queryYaml,
      }),
    );
  };

  const setPipelineYaml = (yaml: { indexingYaml: string; queryYaml: string }) => {
    dispatch(setPipelineYamlAction(yaml));
  };

  const setPipelineEditedStateTo = (edited: boolean) => {
    dispatch(setPipelineEditedStateToAction(edited));
  };

  const updatePipelineYaml = async (
    payload:
      | { code: string; name: string }
      | { indexing_yaml: string; query_yaml: string; name: string },
  ) => {
    await dispatch(updatePipelineYamlAction(payload));
  };

  const exportPipelineDocuments = (params: Pick<IPipeline, 'name' | 'status'>) => {
    dispatch(exportPipelineDocumentsCSV(params));
  };

  return {
    pipeline,
    fetchPipelineYamlStatus,
    status,
    actionStatus,
    message,
    pipelineIndexingData,
    pipelineLogsUnhealthy,
    renamePipelineNameStatus,
    pipelineCreateUpdatePipelineStatus,
    newPipelineName,
    pipelineErrors,
    duplicatePipelineData,
    createPipeline,
    deployPipeline,
    undeployPipeline,
    activatePipeline,
    deletePipeline,
    updatePipeline,
    fetchPipelineIndexing,
    setActiveTabPipelineDetailsPage,
    duplicatePipeline,
    resetRenamePipelineNameStatus,
    resetMessage,
    resetNewPipelineName,
    resetPipelineErrors,
    resetFetchPipelineYamlStatus,
    resetPipeline,
    resetPipelineYaml,
    fetchPipeline,
    fetchPipelineYaml,
    resetValidateNewPipelineName,
    startPollingPipelineStatus,
    stopPollingPipelineStatus,
    setPipelineName,
    getRuntimeIssueDetection,
    validatePipelineYaml,
    setPipelineYaml,
    setPipelineEditedStateTo,
    updatePipelineYaml,
    exportPipelineDocuments,
  };
};

export default usePipelineActions;
