import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Alert, Button, Popover } from 'antd';
import { JSX } from 'react/jsx-runtime';
import { interpolateString } from '@utils/string';
import {
  DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_DESCRIPTION,
  OPENAI_KEY_MISSING_MESSAGE_DESCRIPTION,
} from '@constants/common';
import {
  CONNECTIONS_REDIRECTION_LINK_LABEL,
  DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_TITLE,
  OPENAI_KEY_MISSING_MESSAGE_TITLE,
  POPOVER_ISSUES_LABEL,
} from '@constants/pipelines';
import { IMessage } from '@redux/types/types';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import styles from './multipleErrorsPopup.module.scss';

interface IMultipleErrorsPopupProps {
  errors: IMessage[];
  withExtraMenu?: boolean;
}

const MultipleErrorsPopup = (props: IMultipleErrorsPopupProps) => {
  const { errors, withExtraMenu } = props;
  const navigate = useNavigate();
  const [openPopoverMessage, setOpenPopoverMessage] = useState(true);

  const getPopoverErrorAlerts = () => {
    const alertErrors: JSX.Element[] = [];
    errors.forEach((error: IMessage) => {
      if (error.title === OPENAI_KEY_MISSING_MESSAGE_TITLE)
        alertErrors.push(
          <Alert
            key={error.title}
            className={styles.alertPopover}
            description={interpolateString(OPENAI_KEY_MISSING_MESSAGE_DESCRIPTION, {
              connections: (
                <Button
                  type="link"
                  className={styles.alertLink}
                  onClick={() => navigate(`/settings/connections`)}
                >
                  {CONNECTIONS_REDIRECTION_LINK_LABEL}
                </Button>
              ),
            })}
            type={error.type}
            showIcon
          />,
        );
      else if (error.title === DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_TITLE)
        alertErrors.push(
          <Alert
            key={error.title}
            className={styles.alertPopover}
            description={
              <MarkdownViewer>{DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_DESCRIPTION}</MarkdownViewer>
            }
            type={error.type}
            showIcon
          />,
        );
      else
        alertErrors.push(
          <Alert
            key={error.title}
            className={styles.alertPopover}
            description={
              typeof error.content === 'string' ? (
                <MarkdownViewer>{error.content as string}</MarkdownViewer>
              ) : (
                error.content
              )
            }
            type={error.type}
            showIcon
          />,
        );
    });

    return alertErrors;
  };

  return (
    <div className={`${styles.popoverMessage} ${withExtraMenu ? styles.withExtraMenu : ''}`}>
      <Popover
        content={getPopoverErrorAlerts()}
        title={POPOVER_ISSUES_LABEL}
        trigger="click"
        open={openPopoverMessage}
        onOpenChange={(newOpen: boolean) => setOpenPopoverMessage(newOpen)}
        placement="topRight"
        rootClassName={styles.popoverMessage_popover}
      >
        {openPopoverMessage ? (
          <Button icon={<CloseOutlined />} />
        ) : (
          <Button icon={<ExclamationCircleFilled className={styles.popoverMessage_issuesIcon} />}>
            {POPOVER_ISSUES_LABEL}
          </Button>
        )}
      </Popover>
    </div>
  );
};

export default MultipleErrorsPopup;
