import React from 'react';
import { BulbTwoTone, MessageTwoTone } from '@ant-design/icons';
import { DOCS_URL } from './constant';

export const MAX_NUM_PLAYGROUNDS = 3;
export const MIN_PROMPT_EDITOR_HEIGHT = 220;
export const PROMPT_EDITOR_TITLE = 'Prompt Editor';
export const PROMPT_TITLE = 'Prompt';
export const PROMPT_EDITOR_TEMPLATES_LINK_LABEL = 'Prompt templates';
export const PROMPT_EDITOR_PROMPT_COPIED = 'Prompt copied';
export const PROMPT_EDITOR_PIPELINE_DETAILS_PAGE_LINK = 'Pipeline Details page';
export const PROMPT_EDITOR_UPDATE_PIPELINE_PROMPT_TOOLTIP = {
  NO_CHANGES: 'Once you make changes, you’ll be able to insert the prompt in the pipeline.',
  UPDATE_PIPELINE_NODE: 'Update %node% in pipeline',
  PRODUCTION_PIPELINE:
    'To update a prompt in a production pipeline, change its service level to "Development" from the %pipelinePage%.',
};

export const UPDATING_PIPELINE_PROMPT_NOTIFICATION = 'Updating prompt';
export const UPDATED_PIPELINE_PROMPT_NOTIFICATION = 'Prompt updated in pipeline.';

export const UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_TITLE = 'Update prompt?';
export const UPDATE_PIPELINE_PROMPT_CONFIRM_MODAL_CONTENT =
  'Updating the prompt means the existing prompt will be replaced with this one.';

export const PROMPT_TEMPLATES_MODAL_TITLE = 'Prompt Templates';
export const PROMPT_TEMPLATES_MODAL_TAGS_LABEL = 'Tags';
export const PROMPT_TEMPLATES_MODAL_USE_PROMPT_BUTTON_LABEL = 'Use Prompt';
export const PROMPT_TEMPLATES_MODAL_EDIT_PROMPT_BUTTON_LABEL = 'Edit Prompt';
export const PROMPT_TEMPLATES_MODAL_CREATE_CUSTOM_PROMPT_BUTTON_LABEL = 'Create Custom Prompt';
export const PROMPT_TEMPLATES_MODAL_CUSTOM_TEMPLATES_TAB = 'Custom';
export const PROMPT_TEMPLATES_MODAL_DEEPSET_TEMPLATES_TAB = 'deepset';

export const PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_LABEL = 'Prompt name';
export const PROMPT_TEMPLATES_MODAL_NEW_PROMPT_NAME_PLACEHOLDER =
  'Give your prompt a meaningful name';
export const PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DESCRIPTION_PLACEHOLDER =
  'Add a prompt description to help you identify it later';
export const PROMPT_TEMPLATES_MODAL_NEW_PROMPT_PLACEHOLDER =
  'Type prompt text here. Use curly {brackets} to insert a placeholder';
export const PROMPT_TEMPLATES_MODAL_NEW_PROMPT_DISCLAIMER_MESSAGE =
  'Your custom prompts will be shared across your organization.';
export const PROMPT_TEMPLATES_MODAL_SAVE_PROMPT_BUTTON_LABEL = 'Save Prompt';
export const PROMPT_TEMPLATES_MODAL_SAVE_CHANGES_BUTTON_LABEL = 'Save Changes';
export const PROMPT_TEMPLATES_MODAL_CANCEL_CHANGES_POPCONFIRM_TITLE =
  'By cancelling, you will loose all your progress. Are you sure you want to cancel?';
export const PROMPT_TEMPLATES_MODAL_DELETE_POPCONFIRM_TITLE =
  'Are you sure you want to delete this?';

export const ADD_PIPELINE_BUTTON_LABEL = 'Add Pipeline';
export const SEARCH_PLACEHOLDER = 'Type your query here';

export const DESCRIPTION_TITLE = 'Welcome to Prompt Explorer';
export const DESCRIPTION_SUBTITLE =
  'Easily try out different prompts to help you optimize your pipeline. Use a template for an easy start.';
export const DESCRIPTION_OPTIONS = [
  {
    title: 'Explore prompts in a dynamic environment',
    description:
      'Experiment with your prompts and get immediate results. Compare prompts across up to three pipelines and save the best ones to your prompt template library.',
    icon: <MessageTwoTone />,
  },
  {
    title: 'A library of ready-made prompt templates',
    description:
      "We've got you covered with our curated set of ready-to-use prompts to start you off. Just choose a template and try it out.",
    icon: <BulbTwoTone />,
  },
];

export const SELECT_PLACEHOLDER = 'Select Pipeline';
export const PLAYGROUND_CLEAR_CHAT_OPTION_LABEL = 'Start new chat';

export const PROMPT_EXPLORER_NEW_SESSION_STARTED_MESSAGE = 'New session started';

// Sorting prompt templates

export const DEFAULT_FEEDBACK_SORTING_KEY = 'created_at_desc';

export const PROMPT_TEMPLATES_SORTING_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to Oldest' },
  { key: 'created_at_asc', label: 'Oldest to Newest' },
  { key: 'name_asc', label: 'Name A to Z' },
  { key: 'name_desc', label: 'Name Z to A' },
];

export const SORTING_PARAMS_BY_KEY = {
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
};

// Filtering prompt templates

export const PROMPT_TEMPLATES_FILTERS_TAGS_LABEL = 'Tags';
export const PROMPT_TEMPLATES_FILTERS_USER_LABEL = 'Created by';
export const PROMPT_TEMPLATES_FILTERS_SEARCH_PLACEHOLDER = 'Search for prompts';
export const PROMPT_TEMPLATES_FILTERS_FIELD_KEYS = {
  USER_ID: 'user/user_id',
  TAGS_ID: 'tags/tag_id',
};

export const EMPTY_PROMPT_STUDIO_PAGE_SECTION = {
  TITLE: 'Prompt Explorer',
  DESCRIPTION: [
    {
      paragraph:
        "Fine-tuning prompts is key to getting optimal results. With deepset Cloud's Prompt Explorer, you can easily test, refine, and compare prompt performance in real-time. Access a library of ready-to-use prompts, and save your custom prompts for future use—all in one streamlined workspace.",
    },
    {
      paragraph: 'For more details, see the %documentationLink%.',
    },
  ],
};
export const DOCS_PROMPT_STUDIO_LINK = `${DOCS_URL}docs/using-prompt-studio`;
