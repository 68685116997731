import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useScreen } from '@hooks/useScreen';
import { PipelineDesiredStatusCodes } from '@constants/pipelines';
import { EMPTY_PIPELINE_SELECT_LABEL, SELECT_PIPELINE_PLACEHOLDER } from '@constants/search';
import { fetchPipelineIndexing, fetchPipelines } from '@redux/actions/pipelineActions';
import { setSearchPipeline } from '@redux/actions/searchActions';
import {
  pipelineIndexingDetailsSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import { IPipeline, IPipelineIndexingData } from '@redux/types/types';
import EllipsisMiddle from '@components/common/ellipsisMiddle/EllipsisMiddle';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './searchPipelineSelect.module.scss';

interface ISearchPipelineSelectProps {
  selectedPipeline?: IPipeline | null;
  loading?: boolean;
}

const SearchPipelineSelect = ({ selectedPipeline, loading }: ISearchPipelineSelectProps) => {
  const dispatch = useDispatch();
  const { data: pipelines }: { data: IPipeline[] } = useSelector((state) =>
    pipelinesSelector(state, PipelineDesiredStatusCodes.DEPLOYED),
  );
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;

  const fetchPipelineIndexingDetails = (pipelineName: string) => {
    dispatch(fetchPipelineIndexing(pipelineName));
  };

  const onPipelineSelect = (value: string) => {
    dispatch(setSearchPipeline(value));
  };

  const pipelineOptions = useMemo(
    () =>
      pipelines.map((pipeline) => ({
        key: pipeline.name,
        value: pipeline.name,
        'data-testid': `pipelineOption_${pipeline.name}`,
        label: (
          <div className={styles.pipelineOption}>
            <div className={styles.pipelineOption_wrapper}>
              <PipelineServiceLevelBadge serviceLevel={pipeline.service_level} />
              <EllipsisMiddle>{pipeline.name}</EllipsisMiddle>
            </div>
            <PipelineStatusTag
              pipeline={pipeline}
              getIndexingDetails={fetchPipelineIndexingDetails}
              indexingData={pipelineIndexingData}
            />
          </div>
        ),
      })),
    [pipelines, pipelineIndexingData, selectedPipeline],
  );

  return (
    <Select
      size={isSmallerScreen ? 'large' : 'middle'}
      showSearch
      placeholder={SELECT_PIPELINE_PLACEHOLDER}
      className={styles.pipelineSelect}
      onChange={onPipelineSelect}
      value={selectedPipeline?.name ?? ''}
      options={pipelineOptions}
      disabled={loading}
      notFoundContent={EMPTY_PIPELINE_SELECT_LABEL}
      data-testid="pipelineSelector"
      popupMatchSelectWidth={false}
      onFocus={() =>
        dispatch(
          fetchPipelines({
            currentPage: 1,
            pageSize: 100,
            searchValue: '',
            desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
          }),
        )
      }
    />
  );
};

export default SearchPipelineSelect;
