import React, { ReactNode, useEffect, useRef } from 'react';
import {
  CodeTwoTone,
  ExpandAltOutlined,
  FilterOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { Badge, Button, Dropdown, Select, Tooltip } from 'antd';
import { Resizable } from 're-resizable';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import useScrollToBottomOnChange from '@hooks/useScrollToBottomOnChange';
import { EXPAND_LABEL, SHRINK_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  ADD_PIPELINE_BUTTON_LABEL,
  DESCRIPTION_OPTIONS,
  DESCRIPTION_SUBTITLE,
  DESCRIPTION_TITLE,
  MAX_NUM_PLAYGROUNDS,
  MIN_PROMPT_EDITOR_HEIGHT,
  PLAYGROUND_CLEAR_CHAT_OPTION_LABEL,
  PROMPT_EDITOR_TITLE,
  SELECT_PLACEHOLDER,
} from '@constants/prompt-explorer';
import { IPipeline, IPromptPlayground, ISearchResult } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import FeatureDescription from '@components/featureDescription/FeatureDescription';
import ConversationalResultsContainer from '@components/search/organisms/results/ConversationalResultsContainer';
import styles from './pipelinePlayground.module.scss';
import usePromptEditor from '../hooks/usePromptEditor';

export enum PlaygroundMoreOption {
  NEW_SESSION = 'newSession',
  CLEAR_CHAT = 'clearChat',
}

const PromptEditor = React.lazy(
  () => import(/* webpackChunkName: "PromptEditor" */ '@components/promptEditor/PromptEditor'),
);

interface IPipelinePromptPlaygroundProps {
  displayPipelineActionsBar: boolean;
  playground: IPromptPlayground;
  totalPlaygrounds: number;
  pipeline: IPipeline | null;
  pipelinesOptions: {
    id: string;
    value: string;
    label: string | ReactNode;
  }[];
  search: {
    results: ISearchResult[];
    status: StatusCodes;
    query: string;
    searching: boolean;
    displayTypingEffect?: boolean;
  };
  options: {
    displayRemovePipelineButton: boolean;
    renderDescription: boolean;
    displayHeader: boolean;
    displayExpandShrinkButton: boolean;
  };
  promptEditorContainerExpanded: boolean;
  promptEditorHeight: number;
  activeFilters: boolean;
  onPromptEditorValueChange: ({
    value,
    playgroundId,
  }: {
    value: string;
    playgroundId: string;
  }) => void;
  onPromptEditorPromptNodeChange: ({
    node,
    playgroundId,
  }: {
    node: string;
    playgroundId: string;
  }) => void;
  onSelectPipeline: ({
    pipelineName,
    playgroundId,
  }: {
    pipelineName: string;
    playgroundId: string;
  }) => void;
  onAddNewPipeline: (playgroundId: string, pipelineName: string) => void;
  onRemovePipeline: (playgroundId: string) => void;
  onDisplayFilters: (playgroundId: string) => void;
  onStartNewSession: (playgroundId: string) => void;
  onClearChat: (playgroundId: string) => void;
  onExpandShrinkPromptEditorContainer: () => void;
  onTemplatesLinkClick: (playgroundId: string) => void;
  onRepeatQueryOptionClick: (query: string) => void;
  onSaveQueryPromptTemplateOptionClick: (playgroundId: string, prompt: string) => void;
  onPromptEditorResize: (height: number) => void;
  onUpdatePipelinePrompt: (playgroundId: string) => void;
}

const PipelinePlayground = ({
  displayPipelineActionsBar,
  playground,
  totalPlaygrounds,
  pipeline,
  pipelinesOptions,
  search,
  options,
  promptEditorContainerExpanded,
  promptEditorHeight,
  activeFilters,
  onExpandShrinkPromptEditorContainer,
  onPromptEditorValueChange,
  onPromptEditorPromptNodeChange,
  onSelectPipeline,
  onAddNewPipeline,
  onRemovePipeline,
  onDisplayFilters,
  onStartNewSession,
  onClearChat,
  onTemplatesLinkClick,
  onRepeatQueryOptionClick,
  onSaveQueryPromptTemplateOptionClick,
  onPromptEditorResize,
  onUpdatePipelinePrompt,
}: IPipelinePromptPlaygroundProps) => {
  const { pipelineName, id: playgroundId, prompts, selectedPromptNode } = playground;
  const {
    results: searchResults,
    status: searchStatus,
    query: searchQuery,
    searching,
    displayTypingEffect,
  } = search;
  const {
    displayRemovePipelineButton,
    renderDescription,
    displayHeader,
    displayExpandShrinkButton,
  } = options;
  const isSearching = searchStatus === StatusCodes.IN_PROGRESS;

  const searchResultsWrapperRef = useRef<HTMLDivElement>(null);
  useScrollToBottomOnChange(searchResultsWrapperRef, [searchResults, searching]);

  useEffectUpdateOnly(() => {
    if (!searchResults?.length) return;
    const lastResult = searchResults[searchResults.length - 1];

    // TODO: Handle it inside useScrollToBottomOnChange hook
    // Currently it doesn't detect ref mutation
    if (lastResult.infoMessage) {
      searchResultsWrapperRef.current?.scrollTo({
        top: searchResultsWrapperRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [searchResults]);

  // Prompt editor
  const {
    modalContextHolder,
    isUpdatePipelineButtonDisabled,
    getUpdatePipelinePromptTooltipText,
    onUpdatePipelinePromptClick,
  } = usePromptEditor({
    pipeline,
    prompts,
    pipelineName,
    selectedPromptNode,
    onUpdatePipelinePrompt: () => onUpdatePipelinePrompt(playgroundId),
  });

  // Feedback tags
  const { getFeedbackTags, resetFeedbackData } = usePipelineFeedback();

  useEffect(() => {
    resetFeedbackData();
  }, []);

  useEffect(() => {
    if (!pipeline?.pipeline_id) return;

    getFeedbackTags({ pipelineId: pipeline.pipeline_id });
  }, [pipeline?.pipeline_id]);

  const handleMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }) => {
    if (key === PlaygroundMoreOption.NEW_SESSION) onStartNewSession(playgroundId);
    if (key === PlaygroundMoreOption.CLEAR_CHAT) onClearChat(playgroundId);
  };

  // Renders

  const renderPipelineActionsBar = () => {
    const reachedMaxPipelines = MAX_NUM_PLAYGROUNDS === totalPlaygrounds;
    const pipelineSelected = !!pipeline;
    const displayAddPipelineButton = !reachedMaxPipelines && pipelineSelected;

    const moreOptionsDropdownMenuItems = [
      {
        key: PlaygroundMoreOption.CLEAR_CHAT,
        label: PLAYGROUND_CLEAR_CHAT_OPTION_LABEL,
        icon: <ReloadOutlined />,
      },
    ];

    return (
      <div className={styles.pipelineActionsBar}>
        <Select
          defaultValue={pipelineName || null}
          disabled={isSearching}
          onChange={(value: string) => onSelectPipeline({ pipelineName: value, playgroundId })}
          options={pipelinesOptions}
          placeholder={SELECT_PLACEHOLDER}
          className={styles.pipelineActionsBar_select}
          popupMatchSelectWidth={false}
        />
        <div className={styles.pipelineActionsBar_buttonsWrapper}>
          {pipelineSelected && (
            <Badge dot={activeFilters}>
              <Button icon={<FilterOutlined />} onClick={() => onDisplayFilters(playgroundId)} />
            </Badge>
          )}
          {displayRemovePipelineButton && (
            <Button icon={<MinusCircleOutlined />} onClick={() => onRemovePipeline(playgroundId)} />
          )}
          {displayAddPipelineButton && (
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => onAddNewPipeline(playgroundId, pipelineName!)}
            >
              {totalPlaygrounds === 1 ? ADD_PIPELINE_BUTTON_LABEL : ''}
            </Button>
          )}
          {pipelineSelected && (
            <Dropdown
              menu={{
                items: moreOptionsDropdownMenuItems,
                onClick: handleMoreOptionsDropdownMenuItemClick,
              }}
              trigger={['click']}
            >
              <Button onClick={(e) => e.preventDefault()} icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </div>
      </div>
    );
  };

  const renderDescriptionMessage = () => {
    if (!renderDescription) return null;

    return (
      <div className={styles.descriptionMessage}>
        <FeatureDescription
          title={DESCRIPTION_TITLE}
          subtitle={DESCRIPTION_SUBTITLE}
          options={DESCRIPTION_OPTIONS}
        />
      </div>
    );
  };

  const renderPromptEditor = () => (
    <div className={styles.promptEditor}>
      <div className={styles.promptEditor_header}>
        {displayHeader && (
          <div className={styles.promptEditor_header_wrapper}>
            <div className={styles.promptEditor_header_icon}>
              <CodeTwoTone />
            </div>
            <div className={styles.promptEditor_header_title}>{PROMPT_EDITOR_TITLE}</div>
          </div>
        )}
        {!displayHeader && <div className={styles.promptEditor_header_empty} />}
        {displayExpandShrinkButton && (
          <Tooltip
            placement="topLeft"
            title={promptEditorContainerExpanded ? SHRINK_LABEL : EXPAND_LABEL}
          >
            <Button
              size="small"
              icon={promptEditorContainerExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
              type="text"
              onClick={onExpandShrinkPromptEditorContainer}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.promptEditor_editor}>
        <React.Suspense fallback={<LoadingIndicator />}>
          <PromptEditor
            prompt={prompts[selectedPromptNode]?.text || ''}
            promptNodes={Object.keys(prompts)}
            selectedPromptNode={selectedPromptNode}
            updatePipelinePromptButtonProps={{
              tooltipTitle: getUpdatePipelinePromptTooltipText(),
              disabled: isUpdatePipelineButtonDisabled(),
              onClick: onUpdatePipelinePromptClick,
            }}
            onPromptNodeSelect={(newNode) => {
              onPromptEditorPromptNodeChange({ node: newNode, playgroundId });
            }}
            onChange={(newValue) => {
              onPromptEditorValueChange({ value: newValue, playgroundId });
            }}
            onTemplatesLinkClick={() => onTemplatesLinkClick(playgroundId)}
          />
        </React.Suspense>
      </div>
    </div>
  );

  return (
    <div className={styles.pipelinePlayground}>
      {modalContextHolder}

      {displayPipelineActionsBar && (
        <div className={styles.pipelinePlayground_pipelineActionsBar_wrapper}>
          {renderPipelineActionsBar()}
        </div>
      )}
      <div
        className={`${styles.pipelinePlayground_searchResults_wrapper} ${
          promptEditorContainerExpanded
            ? styles.pipelinePlayground_searchResults_wrapper_expanded
            : ''
        }`}
        ref={searchResultsWrapperRef}
      >
        <div className={styles.zeroHeightContainer}>
          <div className={styles.pipelinePlayground_searchResults_paddingContainer}>
            {pipelineName && (searchResults?.length > 0 || searching) ? (
              <ConversationalResultsContainer
                pipelineId={pipeline?.pipeline_id || ''}
                pipelineName={pipeline?.name || ''}
                results={searchResults}
                displayFileOptions
                displayMoreOptions
                displayTypingEffect={displayTypingEffect}
                status={searchStatus}
                query={searchQuery}
                onRepeatQueryOptionClick={onRepeatQueryOptionClick}
                onSaveQueryPromptTemplateOptionClick={(template) =>
                  onSaveQueryPromptTemplateOptionClick(playgroundId, template)
                }
              />
            ) : (
              renderDescriptionMessage()
            )}
          </div>
        </div>
      </div>

      <Resizable
        size={{ width: 'auto', height: promptEditorHeight }}
        minWidth="auto"
        minHeight={MIN_PROMPT_EDITOR_HEIGHT}
        maxHeight={promptEditorContainerExpanded ? '100%' : 600}
        grid={[10, 10]}
        snapGap={10}
        enable={{
          top: true,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResizeStop={(e, direction, ref, d) => {
          onPromptEditorResize(d.height);
        }}
        className={`${styles.pipelinePlayground_promptEditor_wrapper} ${
          promptEditorContainerExpanded
            ? styles.pipelinePlayground_promptEditor_wrapper_expanded
            : ''
        }`}
        handleClasses={{
          top: 'handle_top',
        }}
      >
        {renderPromptEditor()}
      </Resizable>
    </div>
  );
};

export default PipelinePlayground;
