import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMetadataValuesApi as getMetadataValuesExternalApi,
  getPipelineMetadataApi as getPipelineMetadataExternalApi,
  getPipelineMinMaxAggregationMetadataApi as getPipelineMinMaxAggregationMetadataExternalApi,
} from '@api/external/prototype';
import {
  getMetadataValuesApi,
  getPipelineMetaApi,
  getPipelineMinMaxAggregationMetadataApi,
} from '@api/metadata-filters';
import { initialState } from '@redux/rootReducer';
import {
  GET_METADATA_VALUES,
  GET_PIPELINE_META,
  MetadataFiltersValue,
  SELECT_METADATA_FILTER_VALUES,
  SET_APPLIED_METADATA_FILTER_VALUES,
} from '@redux/types/types';

export const selectMetaDataFilterValues = (values: Record<string, MetadataFiltersValue>) => ({
  type: SELECT_METADATA_FILTER_VALUES,
  payload: values,
});

export const setAppliedMetaDataFilterValues = (values: Record<string, MetadataFiltersValue>) => ({
  type: SET_APPLIED_METADATA_FILTER_VALUES,
  payload: values,
});

// GET ALL THE METADATA FILTERS
export const getPipelineMeta = createAsyncThunk(
  GET_PIPELINE_META,
  async (
    {
      pipelineName,
      isExternal = false,
    }: { pipelineName: string; workspace?: string; isExternal?: boolean },
    { rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? getPipelineMetadataExternalApi : getPipelineMetaApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    try {
      const response = await apiCall(pipelineName, workspace!);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// GET VALUES FOR A SPECIFIC METADATA FILTER
export const getMetadataValues = createAsyncThunk(
  GET_METADATA_VALUES,
  async (
    {
      pipelineName,
      fieldName,
      query,
      limit,
      isExternal,
    }: {
      pipelineName: string;
      fieldName: string;
      query: string;
      limit?: number;
      isExternal?: boolean;
    },
    { rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? getMetadataValuesExternalApi : getMetadataValuesApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }

    try {
      const response = await apiCall(pipelineName, fieldName, query, limit, workspace);
      return { [fieldName]: response.data.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPipelineMinMaxAggregationMetadata = createAsyncThunk(
  GET_METADATA_VALUES,
  async (
    {
      pipelineName,
      fieldName,
      isExternal,
    }: { pipelineName: string; fieldName: string; isExternal?: boolean },
    { rejectWithValue, getState },
  ) => {
    const apiCall = isExternal
      ? getPipelineMinMaxAggregationMetadataExternalApi
      : getPipelineMinMaxAggregationMetadataApi;
    let workspace: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspace = tokenData?.workspaceName;
    }
    try {
      const response = await apiCall(pipelineName, fieldName, workspace);
      return { [fieldName]: { ...response.data } };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
