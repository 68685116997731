import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, DatePicker, Empty, Select, Skeleton } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { SliderMarks } from 'antd/es/slider';
import dayjs from 'dayjs';
import { debounce, isEmpty, isEqual, isNil } from 'lodash';
import { formatDecimalPlaces } from '@utils/math';
import { interpolateString, transformToTitleCase } from '@utils/string';
import {
  getMetadataParamsFromUrl,
  getMetadataParamsToUrl,
  updateURLParamsSilently,
} from '@utils/urlParams';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { useScreen } from '@hooks/useScreen';
import { useWorkspaceNavigate } from '@hooks/useWorkspaceNavigate';
import { StatusCodes } from '@constants/enum/common';
import {
  CLEAR_SINGLE_FILTER,
  EMPTY_KEYWORD_SELECT_LABEL,
  EMPTY_METADATA_MESSAGE,
  EMPTY_PIPELINE_METADATA_MESSAGE,
  SELECT_KEYWORD_PLACEHOLDER,
  UPLOAD_FILE_LINK,
} from '@constants/search';
import {
  getMetadataValues as getMetadataValuesAction,
  getPipelineMinMaxAggregationMetadata,
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import {
  appliedMetaFilterValuesSelector,
  getMetadataValuesStatusSelector,
  metadataFiltersSelector,
  metadataFiltersValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IDateMetadataFilterValue, INumericRangeMetadataFilterValue } from '@redux/types/types';
import RangeSlider from '@components/common/rangeSlider/RangeSlider';
import styles from './metadataFilters.module.scss';

const DEFAULT_NUM_EXPANDED_KEYS = 5;

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker: DateRangePicker } = DatePicker;

interface IMetadataFiltersProps {
  pipelineName: string;
  requestWithoutErrors?: boolean;
  showAll?: boolean;
  isExternal?: boolean;
  syncWithUrl?: boolean;
}

const MetadataFilters = ({
  pipelineName,
  requestWithoutErrors,
  showAll,
  isExternal,
  syncWithUrl = true,
}: IMetadataFiltersProps) => {
  const dispatch = useDispatch();
  const workspaceNavigate = useWorkspaceNavigate();
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;
  const [searchParams] = useSearchParams();

  const metadataFields = useSelector(metadataFiltersSelector);
  const metadataValues = useSelector(metadataFiltersValuesSelector);
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);
  const getMetadataValuesStatus = useSelector(getMetadataValuesStatusSelector);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const isLoadingMetadataValues = getMetadataValuesStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    if (pipelineName) {
      dispatch(selectMetaDataFilterValues({}));
      dispatch(setAppliedMetaDataFilterValues({}));
    }
  }, [pipelineName]);

  useEffect(() => {
    if (!syncWithUrl || !metadataFields) return;

    const urlParams = getMetadataParamsFromUrl(searchParams, metadataFields);

    if (isEmpty(urlParams) || isEqual(urlParams, appliedMetaFilterValues)) return;

    dispatch(selectMetaDataFilterValues(urlParams));
    dispatch(setAppliedMetaDataFilterValues(urlParams));
  }, [metadataFields, syncWithUrl]);

  const getMetadataValues = (payload: {
    pipelineName: string;
    fieldName: string;
    query: string;
    limit?: number;
  }) => {
    dispatch(getMetadataValuesAction({ ...payload, isExternal }));
  };

  const getMinMaxAggregationMetadataValues = (payload: {
    pipelineName: string;
    fieldName: string;
  }) => {
    dispatch(getPipelineMinMaxAggregationMetadata({ ...payload, isExternal }));
  };

  const getMetadataValuesByType = (keyword: string) => {
    const { type } = metadataFields[keyword] || {};
    if (type === 'keyword') {
      getMetadataValues({
        pipelineName,
        fieldName: keyword,
        query: '',
        limit: 10,
      });
    }

    if (type === 'float' || type === 'long' || type === 'date') {
      getMinMaxAggregationMetadataValues({
        pipelineName,
        fieldName: keyword,
      });
    }
  };

  useEffect(() => {
    if (pipelineName && metadataFields) {
      const keys = Object.keys(metadataFields).filter((keyword) => {
        const { type } = metadataFields[keyword] || {};
        return (
          type === 'keyword' ||
          type === 'float' ||
          type === 'long' ||
          type === 'date' ||
          type === 'boolean'
        );
      });

      // Only set first DEFAULT_NUM_EXPANDED_KEYS as default expanded
      if (keys && !showAll) setExpandedKeys(keys.slice(0, DEFAULT_NUM_EXPANDED_KEYS));
      else if (showAll) setExpandedKeys(keys);
      else setExpandedKeys([]);
    }
  }, [metadataFields]);

  // Set applied metadata filters as route params
  useEffectUpdateOnly(() => {
    if (!syncWithUrl || isEmpty(appliedMetaFilterValues)) return;
    const currentURLParams = getMetadataParamsFromUrl(searchParams, metadataFields);

    // If the applied metadata filters are the same as the current URL params, do nothing
    if (isEqual(currentURLParams, appliedMetaFilterValues)) return;

    const params = getMetadataParamsToUrl(appliedMetaFilterValues, metadataFields);
    updateURLParamsSilently(params);
  }, [appliedMetaFilterValues]);

  useEffectUpdateOnly(() => {
    if (expandedKeys.length)
      expandedKeys.forEach((keyword) => {
        getMetadataValuesByType(keyword);
      });
  }, [expandedKeys]);

  // Get values for a specific metadata filter
  const handleMetaSelectSearch = (keyword: string) => {
    const getMetadataValuesHandler = (value: string) => {
      getMetadataValues({
        pipelineName,
        fieldName: keyword,
        query: value,
      });
    };

    return debounce(getMetadataValuesHandler, 1000);
  };

  // Store the values selected for a specific metadata filter
  const handleMetaSelectChange = (keyword: string) => {
    return (value: string[]) => {
      if (!value || value.length === 0) {
        const { [keyword]: ignore, ...rest } = selectedMetaFilterValues;
        dispatch(selectMetaDataFilterValues(rest));
      } else {
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: value,
          }),
        );
      }
    };
  };

  const clearSingleFilterHandler = (event: any, keyword: string) => {
    event.stopPropagation();
    const { [keyword]: ignore, ...rest } = selectedMetaFilterValues;
    dispatch(selectMetaDataFilterValues(rest));
  };

  // Renders

  const renderSelectInputSkeleton = () => {
    return <Skeleton.Input size="small" block active />;
  };

  const MetadataFiltersComponent = () => {
    const clearSingleMetaFilterButton = (keyword: string) => {
      if (
        (selectedMetaFilterValues[keyword] || selectedMetaFilterValues[keyword] === false) &&
        expandedKeys.includes(keyword)
      ) {
        return (
          <Button
            type="link"
            onClick={(event) => clearSingleFilterHandler(event, keyword)}
            className={styles.metaFilters_clear}
          >
            {CLEAR_SINGLE_FILTER}
          </Button>
        );
      }
      return null;
    };

    const handleCollapseChange = (key: string | string[]) => {
      setExpandedKeys(typeof key === 'string' ? [key] : key);
    };

    const handleSwitchChange = (keyword: string) => {
      return (value: boolean) => {
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: value,
          }),
        );
      };
    };

    const handleNumericSlideChange = (keyword: string) => {
      return (value: [number, number]) => {
        const selectedMetaFilterCopy = {
          min: value[0],
          max: value[1],
        };
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: selectedMetaFilterCopy,
          }),
        );
      };
    };

    const handleDatePickerChange = (keyword: string) => {
      const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {
          const selectedMetaFilterCopy = {
            min: dateStrings[0],
            max: dateStrings[1],
          };
          dispatch(
            selectMetaDataFilterValues({
              ...selectedMetaFilterValues,
              [keyword]: selectedMetaFilterCopy,
            }),
          );
        } else {
          const initialMetaFilterValue = metadataValues[keyword] as any;
          const selectedMetaFilterCopy = {
            min: initialMetaFilterValue?.min,
            max: initialMetaFilterValue?.max,
          };
          dispatch(
            selectMetaDataFilterValues({
              ...selectedMetaFilterValues,
              [keyword]: selectedMetaFilterCopy,
            }),
          );
        }
      };
      return onChange;
    };

    // Message when there are no pipelines and no metadata filters
    const emptyPipelineAndMetadataMessage = interpolateString(EMPTY_PIPELINE_METADATA_MESSAGE, {
      uploadFile: (
        <Button
          key="uploadFile"
          type="link"
          onClick={() => workspaceNavigate('/files')}
          className={styles.emptyPipeline_uploadFile}
        >
          {UPLOAD_FILE_LINK}
        </Button>
      ),
    });

    const emptyPipelineMeta =
      (!isNil(requestWithoutErrors) && requestWithoutErrors) || !pipelineName ? (
        <div className={styles.emptyPipeline}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span>{pipelineName ? EMPTY_METADATA_MESSAGE : emptyPipelineAndMetadataMessage}</span>
            }
          />
        </div>
      ) : null;

    const getComponentByMetadataType = (keyword: string, type: string) => {
      if (type === 'keyword') {
        return (
          <Select
            mode="multiple"
            size={isSmallerScreen ? 'large' : 'middle'}
            showSearch
            allowClear
            suffixIcon={null}
            optionFilterProp="children"
            className={styles.metaFilters_select}
            value={selectedMetaFilterValues[keyword] as string[]}
            filterOption={false}
            placeholder={SELECT_KEYWORD_PLACEHOLDER}
            onSearch={handleMetaSelectSearch(keyword)}
            onChange={handleMetaSelectChange(keyword)}
            notFoundContent={
              isLoadingMetadataValues ? renderSelectInputSkeleton() : EMPTY_KEYWORD_SELECT_LABEL
            }
            data-testid="metadataFilters_keywordSelect"
          >
            {Array.isArray(metadataValues[keyword])
              ? (metadataValues[keyword] as string[])?.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))
              : null}
          </Select>
        );
      }
      if (type === 'boolean') {
        return (
          <Select
            size={isSmallerScreen ? 'large' : 'middle'}
            value={selectedMetaFilterValues[keyword] as boolean}
            onChange={(value) => handleSwitchChange(keyword)(value)}
            className={styles.metaFilters_select}
            data-testid="metadataFilters_booleanSelect"
            defaultActiveFirstOption={false}
            allowClear
          >
            <Option value>True</Option>
            <Option value={false}>False</Option>
          </Select>
        );
      }
      if (type === 'float' || type === 'long') {
        const formatedMinValue = formatDecimalPlaces((metadataValues[keyword] as any)?.min);
        const formatedMaxValue = formatDecimalPlaces((metadataValues[keyword] as any)?.max);

        const minMaxRange: SliderMarks = {
          [formatedMinValue]: {
            label: <span>{formatedMinValue}</span>,
          },
          [formatedMaxValue]: {
            label: <span>{formatedMaxValue}</span>,
          },
        };

        const selectedMetaFilterValue = selectedMetaFilterValues[
          keyword
        ] as INumericRangeMetadataFilterValue;

        return (
          <RangeSlider
            data-testid="metadataFilters_rangeSlider"
            min={formatedMinValue}
            max={formatedMaxValue}
            marks={minMaxRange}
            values={[
              selectedMetaFilterValue?.min ? +formatDecimalPlaces(selectedMetaFilterValue.min) : 0,
              selectedMetaFilterValue?.max ? +formatDecimalPlaces(selectedMetaFilterValue.max) : 0,
            ]}
            onChange={handleNumericSlideChange(keyword)}
          />
        );
      }
      if (type === 'date') {
        const dateValue = metadataValues[keyword] as any;
        const selectedMetaFilterValue = selectedMetaFilterValues[
          keyword
        ] as IDateMetadataFilterValue;

        return (
          <DateRangePicker
            data-testid="metadataFilters_datePicker"
            size={isSmallerScreen ? 'large' : 'middle'}
            value={[
              dayjs(selectedMetaFilterValue?.min || dateValue?.min),
              dayjs(selectedMetaFilterValue?.max || dateValue?.max),
            ]}
            disabledDate={(current) => {
              return current.isBefore(dateValue?.min) || current.isAfter(dateValue?.max);
            }}
            onChange={handleDatePickerChange(keyword)}
          />
        );
      }

      return null;
    };

    const metadataFiltersSelects = (
      <>
        <Collapse
          bordered={false}
          activeKey={expandedKeys}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <Button
                size="small"
                icon={<UpOutlined />}
                type="text"
                className={styles.metaFilters_collapseButton}
              />
            ) : (
              <Button
                size="small"
                icon={<DownOutlined />}
                type="text"
                className={styles.metaFilters_collapseButton}
              />
            )
          }
          onChange={handleCollapseChange}
          className={styles.metaFilters}
        >
          {Object.keys(metadataFields).map((keyword) => {
            return (
              <Panel
                header={transformToTitleCase(keyword)}
                key={keyword.toString()}
                extra={clearSingleMetaFilterButton(keyword)}
                className={styles.metaFilters_panel}
                data-testid="metadataFilters_item"
              >
                {getComponentByMetadataType(keyword, metadataFields[keyword].type)}
              </Panel>
            );
          })}
        </Collapse>
      </>
    );

    return (
      <>{Object.keys(metadataFields).length > 0 ? metadataFiltersSelects : emptyPipelineMeta}</>
    );
  };

  return MetadataFiltersComponent();
};

export default MetadataFilters;
