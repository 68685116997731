import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileTextFilled, RiseOutlined } from '@ant-design/icons';
import { Button, Collapse, Tabs, Tooltip } from 'antd';
import { getFileExtension } from '@utils/common';
import { cloneArrayBuffer } from '@utils/file';
import { parseFileContentToViewer } from '@utils/file';
import { formatOrdinals } from '@utils/math';
import { getFileMetaApi, getFilePreviewBlobByTypeApi } from '@api/data';
import { getFileBlobApi as getFileBlobExternalApi } from '@api/external/data';
import ArrowUpDownSVG from '@assets/arrow-up-down.svg?react';
import CollapseSVG from '@assets/icons/collapse.svg?react';
import ExpandSVG from '@assets/icons/expand.svg?react';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  FILE_DRAWER_COLLAPSE_TABS,
  REFERENCE_LABEL,
  VIEW_METADATA_BUTTON_LABEL,
} from '@constants/common';
import { UploadFileType } from '@constants/constant';
import { SupportedViewerLanguage } from '@constants/enum/common';
import { EventControlComponent, EventControlElement, UserEventType } from '@constants/event';
import {
  COLLAPSE_ALL_BUTTON_LABEL,
  DOCUMENT_RANK_HEADER,
  EXPAND_ALL_BUTTON_LABEL,
  REFERENCE_HEADER,
  REFERENCES_HEADER,
  SCROLL_TO_REFERENCE_TITLE,
} from '@constants/search';
import { sharedPrototypeTokenDataSelector } from '@redux/selectors/sharedPrototypeSelectors';
import {
  FileDrawerCollapseTabOptions,
  IFilePreview,
  IHighlightData,
  IMappedReferencesMetaAnnotation,
  ISearchResultDocument,
  SearchResultMeta,
} from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import HighlightContent from '@components/highlightContent/HighlightContent';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import MetadataModal from '@components/metadataModal/MetadataModal';
import useReferences from '@components/search/hooks/useReferences';
import styles from './referencesList.module.scss';

const PDFHighlighter = React.lazy(() => import('@components/pdf/PDFHighligther'));
const CodeViewer = React.lazy(() => import('@components/codeViewer/CodeViewer'));

const { Panel } = Collapse;

interface IReferencesListProps {
  searchResultReferences: IMappedReferencesMetaAnnotation[];
  searchDocuments: ISearchResultDocument[];
  isExternal?: boolean;
}

const ReferencesList = ({
  searchResultReferences,
  searchDocuments,
  isExternal,
}: IReferencesListProps) => {
  const { trackUserEvent } = useUserEvent();
  const {
    activeReference,
    collapseActiveKeys,
    setCollapseActiveKeys,
    currentActiveCollapseTab,
    setCurrentActiveCollapseTab,
  } = useReferences();
  const sharedPrototypeTokenData = useSelector(sharedPrototypeTokenDataSelector);
  const [selectedFileID, setSelectedFileID] = useState('');
  const [loadingFileContent, setLoadingFileContent] = useState<boolean>(false);
  const [filePreviewById, setFilePreviewById] = useState<Record<string, IFilePreview>>({});
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [selectedMeta, setSelectedMeta] = useState<SearchResultMeta | null>(null);
  const [fileMetaById, setFileMetaById] = useState<Record<string, SearchResultMeta>>({});
  const highlightContentRefs = useRef<React.ElementRef<typeof HighlightContent>[]>([]);

  const getDocumentsFileNameByFileId = (fileId: string) => {
    return searchDocuments.find((doc) => doc.file.id === fileId)?.file?.name || '';
  };

  // TODO: Add a hook for files
  const getFileContentById = async (fileId: string) => {
    try {
      setLoadingFileContent(true);

      // TODO: Support getting meta for external files
      const fileMetaApiCall = getFileMetaApi;
      let workspace: string | undefined;

      if (isExternal) {
        const { workspaceName } = sharedPrototypeTokenData || {};
        workspace = workspaceName;
      }

      const fileName = getDocumentsFileNameByFileId(fileId);
      const fileType = getFileExtension(fileName) as UploadFileType;
      const { data } = isExternal
        ? await getFileBlobExternalApi(fileId, workspace)
        : await getFilePreviewBlobByTypeApi(fileId, fileType);
      const { data: metaData } = !isExternal
        ? await fileMetaApiCall(fileId, workspace)
        : { data: {} };
      const parsedFileContent = await parseFileContentToViewer(data);

      setFilePreviewById({ ...filePreviewById, [fileId]: parsedFileContent });
      setFileMetaById({ ...fileMetaById, [fileId]: { ...metaData, file_id: fileId } });
      setLoadingFileContent(false);
    } catch {
      setLoadingFileContent(false);
    }
  };

  useEffect(() => {
    if (activeReference?.referenceId) setCollapseActiveKeys([activeReference.referenceId]);
  }, [activeReference, activeReference?.referenceId]);

  useEffect(() => {
    if (
      currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE &&
      !filePreviewById[selectedFileID]
    )
      getFileContentById(selectedFileID);
  }, [currentActiveCollapseTab, selectedFileID, collapseActiveKeys.length]);

  // Methods
  const showScrollToReferenceButton = (fileName: string) => {
    return fileName?.includes(`.${UploadFileType.txt}`);
  };

  const onExpandAllRefs = () => {
    setCollapseActiveKeys(
      searchResultReferences?.map((ref, idx) => `${ref.result_id}_${idx}`) ?? [],
    );
  };

  const onCollapseAllRefs = () => {
    setCollapseActiveKeys([]);
  };

  const onCloseMetaModal = () => {
    setIsMetaModalVisible(false);
  };

  // Renders
  const renderMetadataModal = () => {
    return (
      <MetadataModal
        data={selectedMeta}
        nonEditableFields={['file_id']}
        open={isMetaModalVisible}
        onCancel={onCloseMetaModal}
        onOk={onCloseMetaModal}
      />
    );
  };

  const renderPanelHeader = (referenceNo: number, fileName: string) => (
    <div className={styles.panelHeader}>
      <div className={styles.panelHeader_title}>
        <FileTextFilled />
        {REFERENCE_HEADER} [{referenceNo}]
      </div>
      <span className={styles.panelHeader_fileName}>{fileName}</span>
    </div>
  );

  const renderSubHeader = (rank: number) => (
    <div className={styles.subHeader}>
      <RiseOutlined />
      <span>
        {DOCUMENT_RANK_HEADER} {formatOrdinals(rank)}
      </span>
    </div>
  );

  const renderTextContent = (
    content: string,
    highlightData: IHighlightData,
    referenceNumber: number,
  ) => {
    if (highlightData)
      return (
        <HighlightContent
          content={content}
          highlightData={[highlightData]}
          ref={(ref) => {
            if (ref && highlightContentRefs.current) {
              highlightContentRefs.current[referenceNumber - 1] = ref;
            }
          }}
        />
      );

    return (
      <div className={styles.content}>
        <pre>{content as string}</pre>
      </div>
    );
  };

  const renderPDFContent = useCallback(
    (reference: IMappedReferencesMetaAnnotation, content: ArrayBuffer) => {
      const { meta } = reference;

      const pageNumber = meta?.page_number || 1;

      // Clone array buffer to avoid detaching it when rendering the same file on a different collapse tab
      return (
        <React.Suspense fallback={<LoadingIndicator />}>
          <PDFHighlighter
            pdfData={cloneArrayBuffer(content)}
            search={{
              term: '',
              context: '',
              page: pageNumber,
            }}
          />
        </React.Suspense>
      );
    },
    [],
  );

  const renderMarkdownContent = (content: string) => {
    return <MarkdownViewer>{content}</MarkdownViewer>;
  };

  const renderCodeContent = (content: string, codeLanguage: SupportedViewerLanguage) => {
    return (
      <React.Suspense fallback={<LoadingIndicator />}>
        <CodeViewer code={content} codeLanguage={codeLanguage} />
      </React.Suspense>
    );
  };

  const renderFileDocumentReference = (
    reference: IMappedReferencesMetaAnnotation,
    referenceNumber: number,
  ) => {
    if (!selectedFileID) return null;
    if (loadingFileContent || !filePreviewById[reference.file_id])
      return (
        <div className={styles.file_content}>
          <LoadingIndicator />
        </div>
      );

    const { content, displayLanguage } = filePreviewById[reference.file_id];
    const highlightLabel = `${REFERENCE_LABEL} ${referenceNumber}`;
    const referenceText = reference.content.slice(reference.doc_start_idx, reference.doc_end_idx);

    const isArrayBufferType = content instanceof ArrayBuffer;
    if (isArrayBufferType)
      return <div className={styles.file_content}>{renderPDFContent(reference, content)}</div>;

    if (displayLanguage === SupportedViewerLanguage.PLAIN_TEXT)
      return (
        <div className={styles.file_content}>
          {renderTextContent(
            content,
            {
              context: reference.content,
              answer: referenceText,
              label: highlightLabel,
              contentId: reference.file_id,
            },
            referenceNumber,
          )}
        </div>
      );
    if (displayLanguage === SupportedViewerLanguage.MARKDOWN)
      return <div className={styles.file_content}>{renderMarkdownContent(content)}</div>;

    return (
      <div className={styles.file_content}>
        {renderCodeContent(content, displayLanguage || SupportedViewerLanguage.PLAIN_TEXT)}
      </div>
    );
  };

  const renderDocumentReference = (
    reference: IMappedReferencesMetaAnnotation,
    referenceNumber: number,
  ) => {
    const highlightLabel = `${REFERENCE_LABEL} ${referenceNumber}`;
    return (
      <>
        {renderSubHeader(reference.document_position)}
        {reference.content &&
          renderTextContent(
            reference.content,
            {
              offsetsInDocument: [
                {
                  start: reference.doc_start_idx,
                  end: reference.doc_end_idx,
                },
              ],
              label: highlightLabel,
              contentId: reference.document_id,
            },
            referenceNumber,
          )}
        <Button
          size="small"
          className={styles.viewMetadata_button}
          onClick={() => {
            setSelectedMeta(reference.meta);
            setIsMetaModalVisible(true);
          }}
        >
          {VIEW_METADATA_BUTTON_LABEL}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.container}>
      {renderMetadataModal()}
      <div className={styles.container_header}>
        <h5>{REFERENCES_HEADER}</h5>
        <div className={styles.buttonsWrapper}>
          <Button size="small" onClick={onExpandAllRefs} icon={<ExpandSVG />}>
            {EXPAND_ALL_BUTTON_LABEL}
          </Button>
          <Button size="small" onClick={onCollapseAllRefs} icon={<CollapseSVG />}>
            {COLLAPSE_ALL_BUTTON_LABEL}
          </Button>
        </div>
      </div>
      <Collapse
        expandIconPosition="end"
        activeKey={collapseActiveKeys}
        onChange={(key) => {
          setCollapseActiveKeys(key as string[]);
        }}
      >
        {searchResultReferences.map((reference, index) => (
          <Panel
            header={renderPanelHeader(index + 1, reference.file_name)}
            key={`${reference.result_id}_${index}`}
          >
            <div className={styles.panelTabs}>
              <Tabs
                activeKey={currentActiveCollapseTab}
                items={FILE_DRAWER_COLLAPSE_TABS}
                tabBarGutter={16}
                onChange={(activeKey) => {
                  setCurrentActiveCollapseTab(activeKey);
                  setSelectedFileID(reference.file_id);

                  trackUserEvent({
                    type: UserEventType.CLICK,
                    control: `${EventControlComponent.REFERENCE_DRAWER}/${EventControlElement.TAB}`,
                    properties: {
                      tab: activeKey,
                      result_id: reference.result_id,
                      file_id: reference.file_id,
                      document_id: reference.document_id,
                    },
                  });
                }}
              />
              {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && (
                <div className={styles.buttonWrapper}>
                  <Button
                    size="small"
                    className={styles.viewMetadata_button}
                    onClick={() => {
                      setSelectedMeta(fileMetaById[reference.file_id]);
                      setIsMetaModalVisible(true);
                    }}
                  >
                    {VIEW_METADATA_BUTTON_LABEL}
                  </Button>
                  {showScrollToReferenceButton(reference.file_name) && (
                    <Tooltip title={SCROLL_TO_REFERENCE_TITLE}>
                      <Button
                        size="small"
                        icon={<ArrowUpDownSVG />}
                        onClick={() => highlightContentRefs?.current?.[index].scrollToHighlight()}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE
              ? renderFileDocumentReference(reference, index + 1)
              : renderDocumentReference(reference, index + 1)}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ReferencesList;
