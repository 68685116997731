import { NavigateFunction, NavigateOptions } from 'react-router-dom';

export class Navigator {
  static navigate: NavigateFunction;

  static workspaceNavigate: (to: string | number, options?: NavigateOptions) => void;

  static setNavigate(func: NavigateFunction) {
    Navigator.navigate = func;
  }

  static setWorkspaceNavigate(func: (to: string | number, options?: NavigateOptions) => void) {
    Navigator.workspaceNavigate = func;
  }
}
