import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import { IBreadcrumb } from '@constants/routes';
import { layoutRouteNameSelector } from '@redux/selectors/layoutSelectors';
import WorkspaceLink from '@components/common/WorkspaceLink/WorkspaceLink';
import styles from './breadcrumbs.module.scss';

const Breadcrumbs = () => {
  const {
    header: { crumbs },
    routeParams,
    resetRouteName,
    location,
  } = useCurrentRoute();

  // Moving this to useCurrentRoute causes rerender of layout
  const routeName = useSelector(layoutRouteNameSelector);

  const { workspace: workspaceName } = routeParams;
  const isWorkspaceBasedRoute = !!workspaceName;

  useEffect(() => {
    resetRouteName();
  }, [location]);

  const replaceRouteParams = (inputString: string): string => {
    const parameterRegex = /:(\w+)/g;
    const replacedString = inputString.replace(parameterRegex, (match, paramName) => {
      return routeName || routeParams[paramName] || match;
    });

    return replacedString;
  };

  const itemRender = (route: any) => {
    if (isWorkspaceBasedRoute) return <WorkspaceLink to={route.href}>{route.title}</WorkspaceLink>;
    return <Link to={route.href}>{route.title}</Link>;
  };

  const addWorkspaceToBreadcrumb = () => {
    if (!isWorkspaceBasedRoute) return null;

    return [
      {
        href: `/${workspaceName}`,
        title: <div className={styles.item}>{replaceRouteParams(workspaceName)}</div>,
      },
    ];
  };

  const mapBreadcrumbs = () => {
    return crumbs?.map((crumb: IBreadcrumb, index: number) => ({
      href: replaceRouteParams(crumb.link),
      title: (
        <div className={`${styles.item} ${index === crumbs.length - 1 ? styles.current : ''}`}>
          {replaceRouteParams(crumb.label)}
        </div>
      ),
    }));
  };

  return crumbs ? (
    <div className={styles.pageTitle_crumbs}>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            href: '/',
            title: (
              <div className={styles.item}>
                <HomeOutlined />
              </div>
            ),
            className: styles.item,
          },
          ...(addWorkspaceToBreadcrumb() || []),
          ...(mapBreadcrumbs() || []),
        ]}
      />
    </div>
  ) : null;
};

export default Breadcrumbs;
